function IPFS({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1024.00 1024.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 511.06 0.00
      L 505.73 2.59"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 513.59 0.00
      L 519.22 2.57"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 514.79 1021.69
      L 510.03 1021.48"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 349.34 383.01
      L 262.14 383.08
      Q 261.56 383.08 261.55 383.66
      L 261.49 399.49"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 261.49 399.49
      Q 190.57 358.55 119.72 317.80
      Q 117.64 316.60 116.38 317.61
      Q 109.23 323.30 100.79 326.82
      C 97.99 327.99 98.45 330.55 98.45 333.50
      Q 98.33 513.13 98.28 692.75
      Q 98.28 695.43 98.84 697.44
      A 3.00 2.98 5.8 0 0 100.38 699.32
      C 108.59 703.43 116.47 708.69 122.80 715.19
      C 132.40 725.06 139.24 737.48 142.17 751.05
      C 143.78 758.51 143.39 765.56 143.47 772.93
      C 143.52 777.21 147.70 778.81 150.09 780.16
      C 192.18 803.93 233.19 828.45 275.75 852.61
      Q 294.48 863.24 313.11 874.02
      Q 315.92 875.65 318.72 877.31
      Q 338.09 888.80 357.61 900.01
      Q 410.97 930.67 464.27 961.44
      C 467.39 963.24 469.02 964.45 472.03 962.58
      Q 475.01 960.74 481.09 957.61
      Q 483.53 956.35 483.53 953.50
      Q 483.56 826.11 483.56 698.72
      C 483.56 660.49 483.91 622.30 483.55 584.04
      A 3.50 3.46 -77.4 0 0 481.61 580.94
      C 468.64 574.56 457.66 565.15 449.67 553.57
      Q 445.18 547.06 441.38 536.80"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 441.38 536.80
      Q 458.18 520.59 463.51 497.50
      Q 468.77 474.68 465.01 450.51"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 465.01 450.51
      C 467.04 450.00 468.19 452.19 470.53 450.51
      Q 478.42 444.87 486.84 442.11"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 486.84 442.11
      L 486.84 669.05
      A 0.95 0.95 0.0 0 0 487.79 670.00
      L 538.97 670.00
      Q 539.44 670.00 539.44 669.53
      L 539.43 549.24
      A 0.48 0.48 0.0 0 1 539.91 548.76
      L 578.05 548.75"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 578.05 548.75
      C 573.14 558.20 564.90 566.46 556.67 573.01
      C 552.95 575.97 548.48 578.28 544.16 580.58
      C 542.17 581.65 541.44 582.81 541.42 585.00
      Q 541.06 627.75 541.47 670.50
      C 541.51 674.41 541.32 678.72 541.33 681.28
      Q 541.41 813.02 541.39 944.75
      Q 541.39 949.36 541.51 954.14
      A 3.70 3.69 13.5 0 0 543.46 957.31
      Q 547.89 959.71 552.89 962.74
      Q 555.68 964.43 558.53 962.78
      Q 614.02 930.65 669.62 898.73
      C 708.30 876.52 746.64 853.70 785.46 831.74
      Q 797.15 825.13 808.76 818.35
      Q 842.93 798.40 877.25 778.70
      C 880.30 776.95 881.54 776.11 881.53 772.23
      Q 881.52 766.04 881.66 759.84
      C 881.84 751.89 884.51 744.62 887.16 737.26
      C 893.37 719.97 908.72 706.22 925.16 698.58
      Q 926.01 698.19 926.01 697.26
      L 926.01 325.78
      Q 926.01 325.28 925.56 325.08
      Q 917.72 321.47 911.23 315.83
      A 1.25 1.25 0.0 0 0 909.79 315.69
      Q 899.28 321.66 888.57 327.83
      C 886.35 329.10 883.99 329.97 882.29 330.95
      Q 850.52 349.21 818.82 367.57
      Q 802.17 377.21 797.57 380.17"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 797.57 380.17
      Q 785.85 379.00 773.00 378.80
      C 737.62 378.25 693.74 389.84 677.61 425.58
      Q 671.79 438.47 671.03 452.91"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 671.03 452.91
      Q 650.08 465.17 629.85 476.90
      Q 611.71 487.41 593.46 497.72
      C 589.80 499.80 585.95 502.15 587.73 507.13"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 587.73 507.13
      L 539.73 507.11
      A 0.29 0.29 0.0 0 1 539.44 506.82
      L 539.42 442.57"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 539.42 442.57
      C 546.22 445.05 549.85 447.58 554.91 450.75
      A 3.60 3.59 -44.1 0 0 558.63 450.80
      Q 579.22 438.73 599.65 427.15
      Q 600.36 426.75 600.04 426.02"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 600.04 426.02
      L 653.93 425.84
      A 0.36 0.36 0.0 0 0 654.29 425.50
      L 655.85 394.58"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 655.85 394.58
      Q 768.79 329.28 881.36 264.36
      Q 883.59 263.08 883.65 260.01
      Q 883.78 254.33 883.73 248.59
      Q 883.73 248.09 883.36 247.75
      Q 880.77 245.34 879.12 244.39
      C 849.39 227.39 819.78 210.21 790.14 193.07
      Q 691.99 136.32 593.76 79.71
      C 584.74 74.52 569.14 65.21 556.58 58.38
      Q 555.81 57.96 554.10 57.41
      Q 553.61 57.25 553.18 57.53
      Q 539.89 65.97 529.33 68.58
      C 520.86 70.67 514.85 70.60 505.08 70.44
      Q 497.24 70.30 491.54 68.96
      C 482.94 66.93 475.98 63.24 468.59 59.41
      A 1.40 1.34 51.2 0 0 467.66 59.29
      Q 466.12 59.65 464.73 60.41
      C 452.61 67.08 439.38 75.11 428.80 81.14
      C 378.94 109.55 329.48 138.68 279.74 167.30
      Q 219.07 202.21 158.48 237.26
      C 155.82 238.80 151.19 241.09 147.74 243.54
      A 3.12 3.11 72.4 0 0 146.43 246.08
      L 146.43 263.66
      A 3.86 3.85 -75.1 0 0 148.36 267.00
      L 349.34 383.01"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 655.85 394.58
      L 656.24 384.16
      A 0.30 0.29 -89.0 0 0 655.95 383.85
      L 487.27 383.84
      A 0.44 0.44 0.0 0 0 486.83 384.28
      L 486.84 442.11"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 465.01 450.51
      C 459.37 417.33 434.53 396.02 402.74 388.25
      C 385.23 383.97 368.45 383.25 349.34 383.01"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 600.04 426.02
      L 540.20 425.81
      A 0.76 0.76 0.0 0 0 539.43 426.57
      L 539.42 442.57"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 261.49 399.49
      L 261.49 669.72
      A 0.28 0.28 0.0 0 0 261.77 670.00
      L 313.21 670.01
      A 0.47 0.47 0.0 0 0 313.68 669.54
      L 313.66 560.11
      A 0.20 0.20 0.0 0 1 313.88 559.91
      Q 342.38 562.12 370.99 560.79
      C 395.90 559.64 422.23 553.94 441.38 536.80"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 578.05 548.75
      L 648.03 548.72
      Q 648.53 548.72 648.53 548.22
      L 648.55 507.74
      Q 648.55 507.14 647.96 507.14
      L 587.73 507.13"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 671.03 452.91
      C 668.25 490.63 686.58 519.04 721.20 532.82
      C 740.53 540.51 760.70 545.92 780.61 551.90
      C 789.76 554.65 799.39 559.00 806.00 565.75
      C 816.91 576.90 817.55 594.18 811.35 607.94
      C 804.91 622.21 789.73 628.71 775.27 630.10
      C 743.78 633.12 710.27 623.34 682.64 608.23
      A 0.35 0.35 0.0 0 0 682.14 608.43
      L 669.53 649.51
      Q 669.37 650.05 669.86 650.33
      C 705.34 670.60 746.67 677.32 787.00 672.25
      C 805.72 669.89 824.63 663.52 839.80 652.05
      C 865.83 632.39 872.09 602.23 867.49 571.02
      Q 864.17 548.51 849.59 533.28
      C 835.00 518.06 811.65 508.58 792.01 502.48
      Q 769.58 495.51 751.75 489.42
      Q 741.12 485.79 736.92 482.60
      Q 726.08 474.36 725.73 459.01
      C 725.15 433.89 741.46 423.87 764.01 422.03
      C 791.30 419.79 819.71 427.18 844.45 438.62
      A 0.49 0.48 -66.7 0 0 845.10 438.36
      L 860.79 400.49
      A 0.51 0.50 26.0 0 0 860.57 399.86
      Q 836.25 386.53 809.01 381.97
      Q 803.86 381.11 797.57 380.17"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 220.18 384.21
      A 0.36 0.36 0.0 0 0 219.82 383.85
      L 167.60 383.85
      A 0.36 0.36 0.0 0 0 167.24 384.21
      L 167.24 669.63
      A 0.36 0.36 0.0 0 0 167.60 669.99
      L 219.82 669.99
      A 0.36 0.36 0.0 0 0 220.18 669.63
      L 220.18 384.21"
        />
        <path
          stroke="#a2c9ca"
          vectorEffect="non-scaling-stroke"
          d="
      M 313.67 429.74
      L 313.67 522.26
      A 0.58 0.57 -89.5 0 0 314.23 522.84
      Q 336.71 523.42 359.41 522.69
      Q 372.23 522.29 384.51 518.47
      Q 407.71 511.28 413.68 487.48"
        />
        <path
          stroke="#99b6b7"
          vectorEffect="non-scaling-stroke"
          d="
      M 413.68 487.48
      C 418.28 466.05 415.04 443.27 394.58 431.19
      C 383.33 424.54 369.56 422.56 356.25 422.52
      Q 335.27 422.45 314.27 422.55
      Q 313.75 422.55 313.75 423.07
      L 313.67 429.74"
        />
        <path
          stroke="#3c8082"
          vectorEffect="non-scaling-stroke"
          d="
      M 413.68 487.48
      L 313.67 429.74"
        />
      </g>
      <path
        fill="#449294"
        d="
      M 508.07 0.00
      L 511.06 0.00
      L 505.73 2.59
      Q 505.80 1.17 508.07 0.00
      Z"
      />
      <path
        fill="#336d6f"
        d="
      M 511.06 0.00
      L 513.59 0.00
      L 519.22 2.57
      Q 521.51 5.91 524.26 7.51
      C 587.41 44.24 650.69 80.77 713.96 117.30
      C 781.64 156.36 849.32 195.43 917.12 234.29
      Q 932.43 243.07 947.61 252.09
      C 952.38 254.92 954.03 257.63 954.03 263.00
      Q 954.03 510.36 954.03 757.72
      C 954.03 763.76 952.91 766.92 947.84 769.84
      Q 776.11 868.86 604.49 968.04
      Q 561.99 992.60 519.56 1017.30
      Q 517.41 1018.55 514.79 1021.69
      L 510.03 1021.48
      Q 507.56 1018.58 505.42 1017.33
      C 482.15 1003.74 458.77 990.36 435.46 976.85
      C 321.69 910.86 207.87 844.94 93.74 779.58
      Q 85.49 774.85 77.37 769.91
      C 72.62 767.02 70.97 764.41 70.97 759.00
      Q 70.97 510.12 70.97 261.25
      Q 70.97 255.39 77.03 251.93
      Q 187.80 188.51 298.35 124.70
      Q 435.30 45.64 500.44 7.70
      Q 503.49 5.92 505.73 2.59
      L 511.06 0.00
      Z
      M 349.34 383.01
      L 262.14 383.08
      Q 261.56 383.08 261.55 383.66
      L 261.49 399.49
      Q 190.57 358.55 119.72 317.80
      Q 117.64 316.60 116.38 317.61
      Q 109.23 323.30 100.79 326.82
      C 97.99 327.99 98.45 330.55 98.45 333.50
      Q 98.33 513.13 98.28 692.75
      Q 98.28 695.43 98.84 697.44
      A 3.00 2.98 5.8 0 0 100.38 699.32
      C 108.59 703.43 116.47 708.69 122.80 715.19
      C 132.40 725.06 139.24 737.48 142.17 751.05
      C 143.78 758.51 143.39 765.56 143.47 772.93
      C 143.52 777.21 147.70 778.81 150.09 780.16
      C 192.18 803.93 233.19 828.45 275.75 852.61
      Q 294.48 863.24 313.11 874.02
      Q 315.92 875.65 318.72 877.31
      Q 338.09 888.80 357.61 900.01
      Q 410.97 930.67 464.27 961.44
      C 467.39 963.24 469.02 964.45 472.03 962.58
      Q 475.01 960.74 481.09 957.61
      Q 483.53 956.35 483.53 953.50
      Q 483.56 826.11 483.56 698.72
      C 483.56 660.49 483.91 622.30 483.55 584.04
      A 3.50 3.46 -77.4 0 0 481.61 580.94
      C 468.64 574.56 457.66 565.15 449.67 553.57
      Q 445.18 547.06 441.38 536.80
      Q 458.18 520.59 463.51 497.50
      Q 468.77 474.68 465.01 450.51
      C 467.04 450.00 468.19 452.19 470.53 450.51
      Q 478.42 444.87 486.84 442.11
      L 486.84 669.05
      A 0.95 0.95 0.0 0 0 487.79 670.00
      L 538.97 670.00
      Q 539.44 670.00 539.44 669.53
      L 539.43 549.24
      A 0.48 0.48 0.0 0 1 539.91 548.76
      L 578.05 548.75
      C 573.14 558.20 564.90 566.46 556.67 573.01
      C 552.95 575.97 548.48 578.28 544.16 580.58
      C 542.17 581.65 541.44 582.81 541.42 585.00
      Q 541.06 627.75 541.47 670.50
      C 541.51 674.41 541.32 678.72 541.33 681.28
      Q 541.41 813.02 541.39 944.75
      Q 541.39 949.36 541.51 954.14
      A 3.70 3.69 13.5 0 0 543.46 957.31
      Q 547.89 959.71 552.89 962.74
      Q 555.68 964.43 558.53 962.78
      Q 614.02 930.65 669.62 898.73
      C 708.30 876.52 746.64 853.70 785.46 831.74
      Q 797.15 825.13 808.76 818.35
      Q 842.93 798.40 877.25 778.70
      C 880.30 776.95 881.54 776.11 881.53 772.23
      Q 881.52 766.04 881.66 759.84
      C 881.84 751.89 884.51 744.62 887.16 737.26
      C 893.37 719.97 908.72 706.22 925.16 698.58
      Q 926.01 698.19 926.01 697.26
      L 926.01 325.78
      Q 926.01 325.28 925.56 325.08
      Q 917.72 321.47 911.23 315.83
      A 1.25 1.25 0.0 0 0 909.79 315.69
      Q 899.28 321.66 888.57 327.83
      C 886.35 329.10 883.99 329.97 882.29 330.95
      Q 850.52 349.21 818.82 367.57
      Q 802.17 377.21 797.57 380.17
      Q 785.85 379.00 773.00 378.80
      C 737.62 378.25 693.74 389.84 677.61 425.58
      Q 671.79 438.47 671.03 452.91
      Q 650.08 465.17 629.85 476.90
      Q 611.71 487.41 593.46 497.72
      C 589.80 499.80 585.95 502.15 587.73 507.13
      L 539.73 507.11
      A 0.29 0.29 0.0 0 1 539.44 506.82
      L 539.42 442.57
      C 546.22 445.05 549.85 447.58 554.91 450.75
      A 3.60 3.59 -44.1 0 0 558.63 450.80
      Q 579.22 438.73 599.65 427.15
      Q 600.36 426.75 600.04 426.02
      L 653.93 425.84
      A 0.36 0.36 0.0 0 0 654.29 425.50
      L 655.85 394.58
      Q 768.79 329.28 881.36 264.36
      Q 883.59 263.08 883.65 260.01
      Q 883.78 254.33 883.73 248.59
      Q 883.73 248.09 883.36 247.75
      Q 880.77 245.34 879.12 244.39
      C 849.39 227.39 819.78 210.21 790.14 193.07
      Q 691.99 136.32 593.76 79.71
      C 584.74 74.52 569.14 65.21 556.58 58.38
      Q 555.81 57.96 554.10 57.41
      Q 553.61 57.25 553.18 57.53
      Q 539.89 65.97 529.33 68.58
      C 520.86 70.67 514.85 70.60 505.08 70.44
      Q 497.24 70.30 491.54 68.96
      C 482.94 66.93 475.98 63.24 468.59 59.41
      A 1.40 1.34 51.2 0 0 467.66 59.29
      Q 466.12 59.65 464.73 60.41
      C 452.61 67.08 439.38 75.11 428.80 81.14
      C 378.94 109.55 329.48 138.68 279.74 167.30
      Q 219.07 202.21 158.48 237.26
      C 155.82 238.80 151.19 241.09 147.74 243.54
      A 3.12 3.11 72.4 0 0 146.43 246.08
      L 146.43 263.66
      A 3.86 3.85 -75.1 0 0 148.36 267.00
      L 349.34 383.01
      Z"
      />
      <path
        fill="#449294"
        d="
      M 513.59 0.00
      L 517.05 0.00
      Q 519.27 1.33 519.22 2.57
      L 513.59 0.00
      Z"
      />
      <path
        fill="#449294"
        d="
      M 655.85 394.58
      L 656.24 384.16
      A 0.30 0.29 -89.0 0 0 655.95 383.85
      L 487.27 383.84
      A 0.44 0.44 0.0 0 0 486.83 384.28
      L 486.84 442.11
      Q 478.42 444.87 470.53 450.51
      C 468.19 452.19 467.04 450.00 465.01 450.51
      C 459.37 417.33 434.53 396.02 402.74 388.25
      C 385.23 383.97 368.45 383.25 349.34 383.01
      L 148.36 267.00
      A 3.86 3.85 -75.1 0 1 146.43 263.66
      L 146.43 246.08
      A 3.12 3.11 72.4 0 1 147.74 243.54
      C 151.19 241.09 155.82 238.80 158.48 237.26
      Q 219.07 202.21 279.74 167.30
      C 329.48 138.68 378.94 109.55 428.80 81.14
      C 439.38 75.11 452.61 67.08 464.73 60.41
      Q 466.12 59.65 467.66 59.29
      A 1.40 1.34 51.2 0 1 468.59 59.41
      C 475.98 63.24 482.94 66.93 491.54 68.96
      Q 497.24 70.30 505.08 70.44
      C 514.85 70.60 520.86 70.67 529.33 68.58
      Q 539.89 65.97 553.18 57.53
      Q 553.61 57.25 554.10 57.41
      Q 555.81 57.96 556.58 58.38
      C 569.14 65.21 584.74 74.52 593.76 79.71
      Q 691.99 136.32 790.14 193.07
      C 819.78 210.21 849.39 227.39 879.12 244.39
      Q 880.77 245.34 883.36 247.75
      Q 883.73 248.09 883.73 248.59
      Q 883.78 254.33 883.65 260.01
      Q 883.59 263.08 881.36 264.36
      Q 768.79 329.28 655.85 394.58
      Z"
      />
      <path
        fill="#449294"
        d="
      M 578.05 548.75
      L 648.03 548.72
      Q 648.53 548.72 648.53 548.22
      L 648.55 507.74
      Q 648.55 507.14 647.96 507.14
      L 587.73 507.13
      C 585.95 502.15 589.80 499.80 593.46 497.72
      Q 611.71 487.41 629.85 476.90
      Q 650.08 465.17 671.03 452.91
      C 668.25 490.63 686.58 519.04 721.20 532.82
      C 740.53 540.51 760.70 545.92 780.61 551.90
      C 789.76 554.65 799.39 559.00 806.00 565.75
      C 816.91 576.90 817.55 594.18 811.35 607.94
      C 804.91 622.21 789.73 628.71 775.27 630.10
      C 743.78 633.12 710.27 623.34 682.64 608.23
      A 0.35 0.35 0.0 0 0 682.14 608.43
      L 669.53 649.51
      Q 669.37 650.05 669.86 650.33
      C 705.34 670.60 746.67 677.32 787.00 672.25
      C 805.72 669.89 824.63 663.52 839.80 652.05
      C 865.83 632.39 872.09 602.23 867.49 571.02
      Q 864.17 548.51 849.59 533.28
      C 835.00 518.06 811.65 508.58 792.01 502.48
      Q 769.58 495.51 751.75 489.42
      Q 741.12 485.79 736.92 482.60
      Q 726.08 474.36 725.73 459.01
      C 725.15 433.89 741.46 423.87 764.01 422.03
      C 791.30 419.79 819.71 427.18 844.45 438.62
      A 0.49 0.48 -66.7 0 0 845.10 438.36
      L 860.79 400.49
      A 0.51 0.50 26.0 0 0 860.57 399.86
      Q 836.25 386.53 809.01 381.97
      Q 803.86 381.11 797.57 380.17
      Q 802.17 377.21 818.82 367.57
      Q 850.52 349.21 882.29 330.95
      C 883.99 329.97 886.35 329.10 888.57 327.83
      Q 899.28 321.66 909.79 315.69
      A 1.25 1.25 0.0 0 1 911.23 315.83
      Q 917.72 321.47 925.56 325.08
      Q 926.01 325.28 926.01 325.78
      L 926.01 697.26
      Q 926.01 698.19 925.16 698.58
      C 908.72 706.22 893.37 719.97 887.16 737.26
      C 884.51 744.62 881.84 751.89 881.66 759.84
      Q 881.52 766.04 881.53 772.23
      C 881.54 776.11 880.30 776.95 877.25 778.70
      Q 842.93 798.40 808.76 818.35
      Q 797.15 825.13 785.46 831.74
      C 746.64 853.70 708.30 876.52 669.62 898.73
      Q 614.02 930.65 558.53 962.78
      Q 555.68 964.43 552.89 962.74
      Q 547.89 959.71 543.46 957.31
      A 3.70 3.69 13.5 0 1 541.51 954.14
      Q 541.39 949.36 541.39 944.75
      Q 541.41 813.02 541.33 681.28
      C 541.32 678.72 541.51 674.41 541.47 670.50
      Q 541.06 627.75 541.42 585.00
      C 541.44 582.81 542.17 581.65 544.16 580.58
      C 548.48 578.28 552.95 575.97 556.67 573.01
      C 564.90 566.46 573.14 558.20 578.05 548.75
      Z"
      />
      <path
        fill="#449294"
        d="
      M 261.49 399.49
      L 261.49 669.72
      A 0.28 0.28 0.0 0 0 261.77 670.00
      L 313.21 670.01
      A 0.47 0.47 0.0 0 0 313.68 669.54
      L 313.66 560.11
      A 0.20 0.20 0.0 0 1 313.88 559.91
      Q 342.38 562.12 370.99 560.79
      C 395.90 559.64 422.23 553.94 441.38 536.80
      Q 445.18 547.06 449.67 553.57
      C 457.66 565.15 468.64 574.56 481.61 580.94
      A 3.50 3.46 -77.4 0 1 483.55 584.04
      C 483.91 622.30 483.56 660.49 483.56 698.72
      Q 483.56 826.11 483.53 953.50
      Q 483.53 956.35 481.09 957.61
      Q 475.01 960.74 472.03 962.58
      C 469.02 964.45 467.39 963.24 464.27 961.44
      Q 410.97 930.67 357.61 900.01
      Q 338.09 888.80 318.72 877.31
      Q 315.92 875.65 313.11 874.02
      Q 294.48 863.24 275.75 852.61
      C 233.19 828.45 192.18 803.93 150.09 780.16
      C 147.70 778.81 143.52 777.21 143.47 772.93
      C 143.39 765.56 143.78 758.51 142.17 751.05
      C 139.24 737.48 132.40 725.06 122.80 715.19
      C 116.47 708.69 108.59 703.43 100.38 699.32
      A 3.00 2.98 5.8 0 1 98.84 697.44
      Q 98.28 695.43 98.28 692.75
      Q 98.33 513.13 98.45 333.50
      C 98.45 330.55 97.99 327.99 100.79 326.82
      Q 109.23 323.30 116.38 317.61
      Q 117.64 316.60 119.72 317.80
      Q 190.57 358.55 261.49 399.49
      Z
      M 220.18 384.21
      A 0.36 0.36 0.0 0 0 219.82 383.85
      L 167.60 383.85
      A 0.36 0.36 0.0 0 0 167.24 384.21
      L 167.24 669.63
      A 0.36 0.36 0.0 0 0 167.60 669.99
      L 219.82 669.99
      A 0.36 0.36 0.0 0 0 220.18 669.63
      L 220.18 384.21
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 797.57 380.17
      Q 803.86 381.11 809.01 381.97
      Q 836.25 386.53 860.57 399.86
      A 0.51 0.50 26.0 0 1 860.79 400.49
      L 845.10 438.36
      A 0.49 0.48 -66.7 0 1 844.45 438.62
      C 819.71 427.18 791.30 419.79 764.01 422.03
      C 741.46 423.87 725.15 433.89 725.73 459.01
      Q 726.08 474.36 736.92 482.60
      Q 741.12 485.79 751.75 489.42
      Q 769.58 495.51 792.01 502.48
      C 811.65 508.58 835.00 518.06 849.59 533.28
      Q 864.17 548.51 867.49 571.02
      C 872.09 602.23 865.83 632.39 839.80 652.05
      C 824.63 663.52 805.72 669.89 787.00 672.25
      C 746.67 677.32 705.34 670.60 669.86 650.33
      Q 669.37 650.05 669.53 649.51
      L 682.14 608.43
      A 0.35 0.35 0.0 0 1 682.64 608.23
      C 710.27 623.34 743.78 633.12 775.27 630.10
      C 789.73 628.71 804.91 622.21 811.35 607.94
      C 817.55 594.18 816.91 576.90 806.00 565.75
      C 799.39 559.00 789.76 554.65 780.61 551.90
      C 760.70 545.92 740.53 540.51 721.20 532.82
      C 686.58 519.04 668.25 490.63 671.03 452.91
      Q 671.79 438.47 677.61 425.58
      C 693.74 389.84 737.62 378.25 773.00 378.80
      Q 785.85 379.00 797.57 380.17
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 349.34 383.01
      C 368.45 383.25 385.23 383.97 402.74 388.25
      C 434.53 396.02 459.37 417.33 465.01 450.51
      Q 468.77 474.68 463.51 497.50
      Q 458.18 520.59 441.38 536.80
      C 422.23 553.94 395.90 559.64 370.99 560.79
      Q 342.38 562.12 313.88 559.91
      A 0.20 0.20 0.0 0 0 313.66 560.11
      L 313.68 669.54
      A 0.47 0.47 0.0 0 1 313.21 670.01
      L 261.77 670.00
      A 0.28 0.28 0.0 0 1 261.49 669.72
      L 261.49 399.49
      L 261.55 383.66
      Q 261.56 383.08 262.14 383.08
      L 349.34 383.01
      Z
      M 313.67 429.74
      L 313.67 522.26
      A 0.58 0.57 -89.5 0 0 314.23 522.84
      Q 336.71 523.42 359.41 522.69
      Q 372.23 522.29 384.51 518.47
      Q 407.71 511.28 413.68 487.48
      C 418.28 466.05 415.04 443.27 394.58 431.19
      C 383.33 424.54 369.56 422.56 356.25 422.52
      Q 335.27 422.45 314.27 422.55
      Q 313.75 422.55 313.75 423.07
      L 313.67 429.74
      Z"
      />
      <rect
        fill="#ffffff"
        x="167.24"
        y="383.85"
        width="52.94"
        height="286.14"
        rx="0.36"
      />
      <path
        fill="#ffffff"
        d="
      M 655.85 394.58
      L 654.29 425.50
      A 0.36 0.36 0.0 0 1 653.93 425.84
      L 600.04 426.02
      L 540.20 425.81
      A 0.76 0.76 0.0 0 0 539.43 426.57
      L 539.42 442.57
      L 539.44 506.82
      A 0.29 0.29 0.0 0 0 539.73 507.11
      L 587.73 507.13
      L 647.96 507.14
      Q 648.55 507.14 648.55 507.74
      L 648.53 548.22
      Q 648.53 548.72 648.03 548.72
      L 578.05 548.75
      L 539.91 548.76
      A 0.48 0.48 0.0 0 0 539.43 549.24
      L 539.44 669.53
      Q 539.44 670.00 538.97 670.00
      L 487.79 670.00
      A 0.95 0.95 0.0 0 1 486.84 669.05
      L 486.84 442.11
      L 486.83 384.28
      A 0.44 0.44 0.0 0 1 487.27 383.84
      L 655.95 383.85
      A 0.30 0.29 -89.0 0 1 656.24 384.16
      L 655.85 394.58
      Z"
      />
      <path
        fill="#336d6f"
        d="
      M 413.68 487.48
      L 313.67 429.74
      L 313.75 423.07
      Q 313.75 422.55 314.27 422.55
      Q 335.27 422.45 356.25 422.52
      C 369.56 422.56 383.33 424.54 394.58 431.19
      C 415.04 443.27 418.28 466.05 413.68 487.48
      Z"
      />
      <path
        fill="#449294"
        d="
      M 600.04 426.02
      Q 600.36 426.75 599.65 427.15
      Q 579.22 438.73 558.63 450.80
      A 3.60 3.59 -44.1 0 1 554.91 450.75
      C 549.85 447.58 546.22 445.05 539.42 442.57
      L 539.43 426.57
      A 0.76 0.76 0.0 0 1 540.20 425.81
      L 600.04 426.02
      Z"
      />
      <path
        fill="#449294"
        d="
      M 313.67 429.74
      L 413.68 487.48
      Q 407.71 511.28 384.51 518.47
      Q 372.23 522.29 359.41 522.69
      Q 336.71 523.42 314.23 522.84
      A 0.58 0.57 -89.5 0 1 313.67 522.26
      L 313.67 429.74
      Z"
      />
      <path
        fill="#449294"
        d="
      M 510.03 1021.48
      L 514.79 1021.69
      C 514.24 1023.80 510.66 1023.45 510.03 1021.48
      Z"
      />
    </svg>
  );
}

export default IPFS;
