function Retreeb() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#ffffff"
        d="
      M 113.88 223.81
      L 113.88 305.34
      A 0.34 0.33 -0.0 0 1 113.54 305.67
      L 5.35 305.68
      A 0.35 0.35 0.0 0 1 5.00 305.33
      L 5.00 34.77
      A 0.76 0.76 0.0 0 1 5.76 34.01
      Q 88.48 33.99 170.75 34.01
      C 213.04 34.02 257.44 56.03 267.02 100.23
      Q 273.15 128.48 260.12 155.77
      Q 260.05 155.91 259.91 155.85
      L 259.90 155.85
      Q 259.75 155.80 259.80 155.65
      C 271.11 115.57 245.67 73.85 204.90 65.89
      Q 198.41 64.62 184.99 64.63
      Q 152.99 64.63 121.00 64.63
      C 96.39 64.62 72.90 79.72 60.74 100.85
      C 44.53 129.02 49.14 164.43 72.45 187.30
      Q 93.37 207.83 123.00 208.02
      Q 153.29 208.21 183.59 208.23
      Q 197.13 208.23 203.35 207.13
      Q 213.19 205.38 222.31 201.04
      A 0.39 0.38 64.1 0 1 222.82 201.22
      L 274.28 305.23
      A 0.30 0.30 0.0 0 1 274.01 305.67
      L 155.04 305.68
      Q 154.76 305.68 154.65 305.41
      L 121.29 223.77
      Q 121.07 223.25 120.51 223.25
      L 114.44 223.25
      Q 113.88 223.25 113.88 223.81
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1164.53 112.37
      Q 1176.84 95.28 1198.02 92.43
      C 1221.77 89.24 1243.94 99.76 1255.41 120.85
      C 1264.91 138.31 1266.42 160.26 1261.90 179.48
      C 1256.37 202.99 1239.74 220.81 1215.50 225.14
      C 1196.02 228.63 1175.88 222.49 1164.51 205.65
      Q 1164.11 205.07 1164.11 205.77
      L 1164.12 222.25
      A 0.37 0.37 0.0 0 1 1163.75 222.62
      L 1124.56 222.63
      A 0.57 0.57 0.0 0 1 1123.99 222.06
      L 1123.99 38.25
      Q 1123.99 37.74 1124.50 37.74
      L 1163.74 37.74
      A 0.38 0.38 0.0 0 1 1164.12 38.12
      L 1164.13 112.24
      Q 1164.13 112.92 1164.53 112.37
      Z
      M 1196.17 192.37
      C 1229.96 189.39 1232.43 135.25 1201.76 126.90
      C 1183.54 121.93 1166.75 132.59 1163.25 151.00
      C 1159.15 172.63 1171.96 194.49 1196.17 192.37
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 641.74 94.76
      L 641.74 58.01
      Q 641.74 57.50 642.24 57.50
      L 681.56 57.50
      A 0.56 0.55 -90.0 0 1 682.11 58.06
      L 682.13 94.81
      Q 682.13 95.25 682.57 95.25
      L 710.56 95.25
      A 0.31 0.31 0.0 0 1 710.87 95.56
      L 710.87 126.69
      Q 710.87 127.26 710.30 127.26
      L 682.48 127.26
      A 0.38 0.38 0.0 0 0 682.10 127.64
      Q 682.15 147.66 682.13 167.50
      C 682.12 177.93 683.17 190.16 696.24 191.04
      Q 702.88 191.49 713.18 190.46
      Q 713.76 190.40 713.76 190.99
      L 713.77 222.19
      Q 713.77 222.60 713.36 222.66
      Q 695.98 225.46 680.01 224.53
      C 654.77 223.06 642.20 206.80 641.96 181.99
      Q 641.70 154.79 641.72 127.71
      A 0.45 0.45 0.0 0 0 641.27 127.26
      L 622.25 127.26
      Q 621.74 127.26 621.74 126.75
      L 621.74 95.76
      Q 621.74 95.25 622.24 95.25
      L 641.24 95.25
      Q 641.74 95.25 641.74 94.76
      Z"
      />
      <circle fill="#ffffff" cx="191.03" cy="136.10" r="66.34" />
      <circle fill="#a9e2ba" cx="1348.76" cy="107.34" r="15.76" />
      <path
        fill="#ffffff"
        d="
      M 519.96 170.59
      Q 521.22 190.50 540.79 194.64
      C 551.96 197.00 567.58 196.21 573.14 184.77
      A 0.92 0.92 0.0 0 1 573.97 184.25
      L 612.49 184.25
      A 0.54 0.54 0.0 0 1 613.02 184.91
      C 605.46 220.43 565.24 229.40 534.75 224.95
      C 493.17 218.88 474.76 185.08 480.72 145.24
      C 484.77 118.14 503.10 99.00 529.89 93.68
      C 565.38 86.63 607.38 99.48 613.88 140.38
      Q 616.04 153.94 613.70 169.60
      Q 613.63 170.01 613.22 170.01
      L 520.50 170.01
      Q 519.93 170.01 519.96 170.59
      Z
      M 520.42 145.13
      L 574.98 144.85
      A 0.23 0.23 0.0 0 0 575.21 144.62
      L 575.20 143.38
      A 24.87 24.35 -0.3 0 0 550.20 119.16
      L 544.92 119.18
      A 24.87 24.35 -0.3 0 0 520.18 143.66
      L 520.19 144.90
      A 0.23 0.23 0.0 0 0 520.42 145.13
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 864.76 170.56
      Q 866.36 191.75 887.53 195.10
      C 898.32 196.81 912.66 195.69 918.04 184.69
      A 0.80 0.79 -76.7 0 1 918.75 184.25
      L 957.25 184.25
      Q 957.79 184.25 957.70 184.78
      C 955.53 198.52 946.33 209.69 934.38 216.37
      C 916.93 226.11 895.93 227.95 876.50 224.47
      C 852.27 220.13 833.61 204.96 827.34 180.73
      C 821.73 159.03 824.04 132.56 838.65 114.66
      C 854.59 95.13 880.35 89.68 904.75 92.56
      C 931.74 95.74 953.91 112.08 958.63 139.88
      Q 960.96 153.56 958.51 169.56
      Q 958.44 170.01 957.99 170.01
      L 865.26 170.01
      Q 864.72 170.01 864.76 170.56
      Z
      M 865.40 145.13
      L 919.60 144.85
      A 0.41 0.41 0.0 0 0 920.01 144.44
      L 920.00 143.34
      A 24.85 24.31 -0.3 0 0 895.02 119.16
      L 889.70 119.18
      A 24.85 24.31 -0.3 0 0 864.98 143.62
      L 864.99 144.72
      A 0.41 0.41 0.0 0 0 865.40 145.13
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1010.45 170.60
      C 1011.28 186.03 1021.30 194.01 1035.93 195.49
      C 1046.01 196.50 1058.59 194.96 1063.70 184.73
      Q 1063.95 184.25 1064.49 184.25
      L 1103.23 184.25
      A 0.26 0.26 0.0 0 1 1103.49 184.56
      C 1099.63 205.71 1082.47 218.25 1062.70 223.27
      C 1033.70 230.63 996.26 224.56 979.72 196.96
      C 967.45 176.48 967.00 145.08 978.07 124.05
      C 988.93 103.41 1009.57 93.47 1032.50 92.07
      C 1052.78 90.83 1074.13 95.17 1088.94 109.92
      C 1104.92 125.84 1107.30 147.84 1104.23 169.58
      Q 1104.17 170.01 1103.73 170.01
      L 1011.01 170.01
      Q 1010.42 170.01 1010.45 170.60
      Z
      M 1010.90 145.09
      L 1065.49 144.89
      A 0.27 0.27 0.0 0 0 1065.76 144.62
      L 1065.76 143.82
      A 24.76 24.75 -0.2 0 0 1040.91 119.16
      L 1035.29 119.18
      A 24.76 24.75 -0.2 0 0 1010.62 144.02
      L 1010.62 144.82
      A 0.27 0.27 0.0 0 0 1010.90 145.09
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 427.85 120.10
      C 428.38 118.92 428.67 117.29 429.09 116.30
      Q 439.30 92.28 466.25 93.19
      Q 469.00 93.28 472.53 94.14
      Q 473.01 94.26 473.01 94.75
      L 473.01 131.26
      A 0.51 0.50 -85.6 0 1 472.43 131.76
      C 453.20 128.84 435.52 133.06 429.94 153.94
      Q 427.75 162.16 427.67 169.51
      Q 427.39 195.84 427.40 222.18
      A 0.44 0.43 -90.0 0 1 426.97 222.62
      L 387.57 222.63
      Q 387.00 222.63 387.00 222.07
      L 387.00 95.75
      Q 387.00 95.25 387.50 95.25
      L 426.96 95.25
      A 0.47 0.46 0.0 0 1 427.43 95.71
      L 427.41 120.01
      Q 427.41 121.09 427.85 120.10
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 772.25 119.27
      Q 772.25 122.21 773.04 119.38
      C 778.65 99.27 797.52 90.11 817.34 94.08
      Q 817.88 94.19 817.88 94.74
      L 817.88 131.26
      Q 817.87 131.87 817.27 131.78
      C 796.93 128.76 779.18 133.65 774.18 156.01
      Q 772.56 163.26 772.49 169.75
      Q 772.21 196.00 772.23 222.27
      A 0.36 0.35 0.0 0 1 771.87 222.62
      L 732.23 222.63
      A 0.40 0.40 0.0 0 1 731.83 222.23
      L 731.83 95.71
      Q 731.83 95.25 732.29 95.25
      L 771.75 95.25
      Q 772.25 95.25 772.25 95.75
      L 772.25 119.27
      Z"
      />
      <path
        fill="#a9e2ba"
        d="
      M 1467.98 179.74
      A 40.32 40.32 0.0 0 1 1427.66 220.06
      L 1419.78 220.06
      A 40.32 40.32 0.0 0 1 1379.46 179.74
      L 1379.46 170.38
      A 40.32 40.32 0.0 0 1 1419.78 130.06
      L 1427.66 130.06
      A 40.32 40.32 0.0 0 1 1467.98 170.38
      L 1467.98 179.74
      Z
      M 1408.38 157.13
      C 1401.63 166.25 1401.87 184.85 1408.95 193.77
      C 1415.94 202.59 1431.49 203.17 1438.57 193.77
      C 1445.04 185.17 1445.45 169.57 1440.89 160.13
      C 1434.53 146.98 1416.65 145.97 1408.38 157.13
      Z"
      />
      <rect
        fill="#a9e2ba"
        x="1336.83"
        y="131.76"
        width="24.44"
        height="86.42"
        rx="0.30"
      />
      <circle fill="#a9e2ba" cx="1295.89" cy="203.32" r="20.10" />
    </svg>
  );
}

export default Retreeb;
