function Fantom() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#1869ee"
        d="
      M 110.43 326.58
      Q 71.79 306.37 33.19 286.06
      C 27.55 283.09 25.99 279.06 25.99 272.50
      Q 25.99 168.86 25.98 65.22
      C 25.98 59.37 27.28 55.01 32.36 52.34
      Q 73.91 30.52 115.50 8.75
      C 124.14 4.22 135.44 5.30 144.47 10.02
      Q 184.90 31.17 225.28 52.40
      C 230.28 55.02 231.74 59.75 231.74 65.54
      Q 231.73 168.67 231.74 271.78
      C 231.75 278.01 230.58 282.82 225.15 285.67
      Q 183.72 307.45 142.23 329.15
      C 136.96 331.91 127.65 333.04 121.91 331.41
      Q 116.97 330.00 110.43 326.58
      Z
      M 118.09 104.23
      C 124.06 107.36 130.77 108.79 136.85 105.61
      Q 177.28 84.41 217.61 63.28
      A 0.34 0.34 0.0 0 0 217.61 62.67
      Q 177.24 41.53 136.76 20.29
      Q 131.97 17.77 126.25 18.68
      Q 122.86 19.22 116.71 22.44
      Q 78.32 42.55 39.93 62.68
      A 0.33 0.33 0.0 0 0 39.93 63.27
      Q 78.95 83.73 118.09 104.23
      Z
      M 39.15 77.20
      A 0.30 0.30 0.0 0 0 38.71 77.46
      L 38.71 152.50
      A 0.30 0.30 0.0 0 0 39.15 152.76
      L 110.72 115.25
      A 0.30 0.30 0.0 0 0 110.72 114.71
      L 39.15 77.20
      Z
      M 218.17 152.63
      A 0.55 0.55 0.0 0 0 218.98 152.15
      L 218.98 77.79
      A 0.55 0.55 0.0 0 0 218.17 77.31
      L 147.25 114.48
      A 0.55 0.55 0.0 0 0 147.25 115.46
      L 218.17 152.63
      Z
      M 121.96 210.26
      A 0.35 0.35 0.0 0 0 122.47 209.95
      L 122.47 123.99
      A 0.35 0.35 0.0 0 0 121.96 123.68
      L 39.96 166.66
      A 0.35 0.35 0.0 0 0 39.96 167.28
      L 121.96 210.26
      Z
      M 135.70 123.74
      A 0.32 0.32 0.0 0 0 135.23 124.02
      L 135.23 209.94
      A 0.32 0.32 0.0 0 0 135.70 210.22
      L 217.65 167.26
      A 0.32 0.32 0.0 0 0 217.65 166.70
      L 135.70 123.74
      Z
      M 145.39 219.45
      C 137.32 223.68 129.27 225.26 120.31 222.88
      Q 116.99 222.00 110.70 218.70
      Q 74.81 199.87 38.97 181.10
      Q 38.71 180.97 38.71 181.26
      L 38.71 274.45
      Q 38.71 274.88 39.09 275.08
      Q 78.50 295.57 117.91 316.07
      C 127.69 321.16 133.34 319.52 142.66 314.63
      Q 180.69 294.67 218.51 274.78
      A 0.89 0.89 0.0 0 0 218.99 273.99
      L 218.98 181.74
      A 0.52 0.52 0.0 0 0 218.22 181.28
      Q 181.80 200.37 145.39 219.45
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 224.56 35.54
      L 230.63 25.57
      Q 230.91 25.10 231.40 25.36
      C 250.21 35.24 258.10 45.05 257.90 67.10
      Q 257.89 67.54 257.45 67.54
      L 245.74 67.55
      Q 245.13 67.55 245.12 66.94
      C 245.05 58.06 244.40 49.57 236.69 43.79
      Q 230.88 39.44 224.70 36.05
      A 0.37 0.37 0.0 0 1 224.56 35.54
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 389.17 124.83
      Q 389.24 114.79 389.31 104.75
      C 389.56 66.03 421.23 52.85 454.67 62.13
      A 0.67 0.67 0.0 0 1 455.15 62.77
      L 455.14 84.73
      Q 455.14 85.54 454.37 85.31
      C 440.50 81.16 423.02 81.89 418.74 99.01
      C 416.69 107.20 417.47 116.57 417.48 124.95
      Q 417.48 125.49 418.01 125.49
      L 450.02 125.49
      A 0.53 0.53 0.0 0 1 450.55 126.02
      L 450.54 146.73
      Q 450.54 147.24 450.02 147.24
      L 418.01 147.21
      A 0.54 0.53 0.0 0 0 417.47 147.74
      L 417.48 265.72
      A 0.70 0.69 -0.4 0 1 416.78 266.42
      L 389.78 266.37
      Q 389.20 266.37 389.20 265.78
      L 389.20 147.73
      A 0.48 0.47 90.0 0 0 388.73 147.25
      L 362.48 147.25
      Q 361.97 147.25 361.97 146.73
      L 361.95 126.02
      Q 361.95 125.49 362.48 125.49
      L 388.51 125.49
      Q 389.17 125.49 389.17 124.83
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 852.27 147.75
      Q 852.15 183.42 852.28 219.09
      C 852.29 224.06 852.84 228.27 854.49 232.50
      C 860.01 246.73 878.72 245.74 890.38 242.50
      Q 890.99 242.34 890.99 242.97
      L 890.99 264.75
      Q 890.99 265.22 890.54 265.35
      Q 873.07 270.55 856.99 267.11
      C 834.12 262.21 824.13 244.42 824.01 222.25
      Q 823.80 185.03 823.80 147.82
      Q 823.80 147.25 823.23 147.25
      L 796.78 147.25
      Q 796.30 147.25 796.30 146.77
      L 796.29 125.73
      A 0.24 0.23 90.0 0 1 796.52 125.49
      L 823.42 125.49
      Q 823.83 125.49 823.83 125.08
      L 823.81 90.74
      A 0.69 0.68 80.5 0 1 824.27 90.09
      L 851.73 80.61
      Q 852.24 80.43 852.24 80.97
      L 852.23 124.99
      Q 852.23 125.49 852.74 125.49
      L 890.75 125.49
      Q 891.25 125.49 891.25 126.00
      L 891.25 146.74
      Q 891.25 147.23 890.76 147.24
      L 852.77 147.25
      Q 852.28 147.25 852.27 147.75
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 579.37 244.46
      A 0.44 0.44 0.0 0 0 578.55 244.23
      Q 568.36 261.54 548.86 266.30
      C 524.65 272.22 498.02 265.09 483.61 244.38
      C 470.55 225.60 467.53 201.97 470.87 179.75
      C 476.44 142.70 504.92 116.93 543.50 122.96
      C 558.36 125.28 570.25 133.11 578.04 145.89
      Q 579.39 148.11 579.38 145.52
      L 579.36 125.94
      Q 579.36 125.49 579.81 125.49
      L 606.98 125.49
      Q 607.47 125.49 607.47 125.98
      L 607.46 265.48
      A 0.93 0.93 0.0 0 1 606.52 266.41
      L 579.75 266.37
      A 0.38 0.37 -0.0 0 1 579.37 266.00
      L 579.37 244.46
      Z
      M 578.62 207.62
      C 580.70 191.32 579.39 170.36 568.69 157.57
      C 554.02 140.01 523.00 140.29 509.07 158.33
      Q 501.87 167.64 499.67 180.00
      C 494.40 209.61 502.79 247.56 541.25 245.92
      C 563.99 244.95 575.93 228.83 578.62 207.62
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 676.18 148.89
      C 676.91 147.74 677.55 146.24 678.17 145.28
      Q 687.75 130.53 703.24 125.53
      C 720.90 119.83 745.60 120.40 759.67 133.51
      C 771.50 144.52 774.91 161.30 774.94 176.75
      Q 775.04 221.47 775.06 266.07
      Q 775.06 266.39 774.74 266.39
      L 747.27 266.38
      Q 746.74 266.38 746.74 265.85
      Q 746.72 222.39 746.65 179.01
      C 746.62 163.03 742.10 148.40 724.25 145.58
      C 697.26 141.31 677.15 158.26 677.10 185.75
      Q 677.03 225.96 677.05 265.83
      Q 677.05 266.39 676.49 266.39
      L 649.53 266.38
      Q 649.00 266.38 649.00 265.85
      Q 649.00 213.02 648.99 160.26
      Q 648.99 144.36 646.84 125.97
      Q 646.79 125.49 647.27 125.49
      L 672.95 125.49
      A 0.23 0.23 0.0 0 1 673.18 125.70
      L 675.56 148.74
      Q 675.66 149.70 676.18 148.89
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 1037.54 235.84
      C 1023.24 261.50 994.60 271.80 965.85 267.46
      C 920.13 260.56 902.59 218.26 910.70 176.38
      C 916.29 147.51 936.65 126.87 966.27 122.92
      C 1000.73 118.33 1032.53 133.28 1043.33 167.98
      C 1049.97 189.32 1048.66 215.90 1037.54 235.84
      Z
      M 1017.87 204.37
      C 1019.32 187.68 1017.62 168.53 1006.11 155.90
      C 993.76 142.35 969.51 141.11 954.83 151.65
      Q 944.36 159.17 940.64 172.15
      C 935.26 190.89 935.14 222.47 951.60 236.90
      C 960.65 244.82 972.70 247.01 984.13 245.64
      C 1006.93 242.91 1016.07 225.22 1017.87 204.37
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 1194.98 148.35
      C 1202.07 135.46 1213.66 127.09 1228.00 123.69
      C 1240.90 120.64 1256.74 121.73 1267.91 128.42
      C 1283.79 137.93 1288.94 158.53 1288.94 175.88
      Q 1288.95 220.87 1288.94 265.87
      Q 1288.94 266.38 1288.44 266.38
      L 1261.06 266.43
      Q 1260.50 266.43 1260.50 265.87
      Q 1260.49 222.14 1260.48 178.51
      C 1260.47 156.47 1251.71 141.76 1227.39 145.58
      C 1206.92 148.79 1198.63 167.40 1198.68 186.75
      Q 1198.77 226.30 1198.76 265.85
      Q 1198.76 266.38 1198.23 266.38
      L 1170.89 266.40
      A 0.43 0.42 0.0 0 1 1170.46 265.98
      Q 1170.52 222.64 1170.51 179.25
      C 1170.50 157.98 1162.84 142.16 1138.36 145.28
      C 1118.33 147.82 1108.57 165.85 1108.52 184.50
      Q 1108.41 225.18 1108.38 265.82
      A 0.62 0.62 0.0 0 1 1107.76 266.44
      L 1080.96 266.38
      Q 1080.32 266.37 1080.32 265.73
      Q 1080.38 210.59 1080.30 155.50
      Q 1080.28 144.61 1078.15 126.14
      Q 1078.08 125.49 1078.73 125.49
      L 1103.98 125.49
      Q 1104.47 125.49 1104.52 125.98
      L 1106.72 146.52
      Q 1106.99 149.07 1108.18 146.80
      C 1118.02 127.87 1138.72 119.71 1159.48 122.48
      C 1176.06 124.69 1188.06 132.51 1194.18 148.29
      A 0.44 0.44 0.0 0 0 1194.98 148.35
      Z"
      />
      <path
        fill="#1869ee"
        d="
      M 0.00 279.59
      L 0.00 270.68
      L 12.21 270.71
      Q 12.73 270.71 12.74 271.23
      C 12.76 281.39 13.96 289.95 23.28 295.98
      Q 29.93 300.28 33.09 302.10
      Q 33.53 302.35 33.27 302.78
      L 27.19 312.69
      Q 26.99 313.02 26.64 312.84
      C 14.79 306.72 5.10 299.92 1.99 287.20
      Q 1.05 283.39 0.00 279.59
      Z"
      />
    </svg>
  );
}

export default Fantom;
