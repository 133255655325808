function Securrency() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#a6da20"
        d="
      M 117.38 12.89
      C 135.67 2.53 158.86 1.24 178.57 8.70
      Q 184.14 10.81 196.32 17.86
      Q 247.25 47.35 298.17 76.85
      Q 300.43 78.15 299.42 80.10
      A 1.53 1.52 -1.6 0 1 298.85 80.70
      L 247.13 110.61
      Q 245.66 111.46 244.19 110.61
      Q 200.23 85.22 156.77 60.02
      Q 152.31 57.44 146.91 60.60
      Q 105.60 84.75 64.13 108.63
      Q 59.40 111.35 59.37 117.75
      Q 59.20 154.51 59.30 191.02
      Q 59.30 191.59 58.75 191.46
      C 36.00 186.26 17.45 169.32 8.67 148.36
      C -4.14 117.76 6.36 83.74 31.73 63.38
      Q 35.93 60.01 45.19 54.63
      Q 99.88 22.80 117.38 12.89
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 490.78 113.26
      L 481.73 135.54
      A 0.73 0.73 0.0 0 1 480.68 135.89
      C 466.29 127.42 445.57 122.26 429.25 128.14
      C 419.47 131.66 415.42 144.41 426.03 150.16
      Q 432.16 153.48 438.99 155.08
      C 457.95 159.54 487.36 163.08 494.88 184.97
      Q 498.03 194.14 495.85 204.68
      C 491.67 224.88 472.46 234.73 453.33 237.06
      C 431.64 239.71 405.38 236.09 387.06 223.11
      A 0.91 0.91 0.0 0 1 386.75 221.99
      L 396.75 199.98
      Q 397.08 199.25 397.75 199.70
      Q 421.45 215.63 450.24 212.48
      Q 454.05 212.07 458.65 209.88
      C 465.03 206.84 468.56 199.39 464.03 193.29
      C 460.46 188.47 452.81 186.19 447.22 184.87
      Q 432.11 181.30 422.51 178.69
      C 403.59 173.53 388.88 163.01 389.14 141.50
      C 389.43 118.10 409.27 104.65 431.01 101.87
      C 451.38 99.28 472.50 102.02 490.48 112.34
      Q 491.02 112.66 490.78 113.26
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 538.01 193.28
      A 0.77 0.77 0.0 0 0 537.27 194.28
      C 544.57 217.16 575.52 218.39 590.82 204.06
      A 0.90 0.89 -43.0 0 1 592.09 204.10
      L 607.14 220.38
      A 0.59 0.58 -44.6 0 1 607.13 221.18
      C 593.70 235.15 576.35 238.30 557.63 236.77
      C 523.87 234.01 500.85 205.84 508.78 172.01
      C 515.11 144.96 540.12 130.20 567.05 132.41
      C 598.89 135.01 618.01 161.06 614.14 192.10
      A 1.35 1.35 0.0 0 1 612.80 193.28
      L 538.01 193.28
      Z
      M 567.15 155.23
      C 552.80 152.51 539.59 160.74 537.00 175.30
      Q 536.95 175.62 537.27 175.62
      L 585.99 175.62
      Q 586.73 175.62 586.56 174.89
      C 584.17 164.71 577.66 157.22 567.15 155.23
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 682.50 212.44
      Q 697.90 212.46 705.40 199.20
      Q 705.87 198.37 706.72 198.83
      L 728.75 210.82
      A 0.50 0.49 -62.1 0 1 728.95 211.48
      Q 719.68 229.50 699.96 234.67
      C 665.21 243.78 626.89 224.91 626.51 185.50
      C 626.13 146.26 662.70 125.77 698.38 133.92
      Q 719.32 138.70 728.84 157.52
      A 0.75 0.75 0.0 0 1 728.52 158.52
      L 706.49 170.34
      Q 705.87 170.67 705.52 170.05
      C 698.13 156.91 682.91 153.05 670.01 159.68
      C 655.28 167.26 652.90 189.38 661.92 202.27
      Q 669.03 212.43 682.50 212.44
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 889.41 144.54
      A 0.84 0.84 0.0 0 0 890.85 145.12
      C 899.78 135.80 911.47 131.88 924.03 132.27
      Q 924.52 132.28 924.52 132.77
      L 924.54 158.98
      Q 924.54 159.77 923.76 159.66
      C 903.54 156.84 891.02 167.25 890.89 187.75
      Q 890.74 211.51 890.75 235.10
      A 0.52 0.51 0.0 0 1 890.23 235.61
      L 861.84 235.59
      A 0.76 0.75 0.0 0 1 861.08 234.84
      L 861.09 134.47
      Q 861.09 133.77 861.78 133.78
      L 888.76 133.82
      A 0.69 0.68 -90.0 0 1 889.44 134.51
      L 889.41 144.54
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 968.89 145.53
      Q 968.87 146.83 969.73 145.85
      C 978.33 136.02 990.60 131.98 1003.48 132.18
      A 0.56 0.56 0.0 0 1 1004.03 132.74
      L 1004.06 159.02
      A 0.62 0.61 -86.0 0 1 1003.36 159.63
      C 990.13 157.84 975.99 161.98 971.88 176.13
      C 970.66 180.32 970.28 184.81 970.28 189.68
      Q 970.28 212.37 970.27 235.06
      A 0.55 0.54 -0.0 0 1 969.72 235.60
      L 941.23 235.63
      A 0.65 0.65 0.0 0 1 940.58 234.98
      L 940.56 134.47
      A 0.72 0.72 0.0 0 1 941.28 133.75
      L 968.39 133.83
      A 0.68 0.67 0.8 0 1 969.06 134.51
      L 968.89 145.53
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1040.84 193.84
      C 1047.01 216.97 1079.25 218.62 1094.39 203.95
      A 0.78 0.78 0.0 0 1 1095.50 203.98
      L 1110.61 220.37
      A 0.51 0.51 0.0 0 1 1110.61 221.07
      Q 1099.13 233.36 1082.26 236.16
      C 1053.49 240.95 1021.90 230.27 1012.79 199.92
      Q 1008.99 187.29 1011.71 174.07
      C 1015.82 154.05 1030.24 139.07 1050.26 133.96
      C 1070.45 128.81 1093.46 134.22 1106.87 150.79
      C 1116.38 162.54 1119.64 178.03 1117.30 192.99
      A 0.33 0.32 -85.9 0 1 1116.98 193.27
      L 1041.26 193.28
      Q 1040.69 193.28 1040.84 193.84
      Z
      M 1067.24 154.92
      C 1053.76 153.46 1042.92 162.33 1040.36 175.13
      A 0.41 0.41 0.0 0 0 1040.76 175.62
      L 1089.48 175.62
      A 0.40 0.40 0.0 0 0 1089.87 175.14
      Q 1085.95 156.94 1067.24 154.92
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1160.03 144.48
      A 0.46 0.46 0.0 0 0 1160.82 144.82
      C 1171.58 134.26 1186.96 130.68 1201.52 132.83
      C 1223.82 136.12 1235.51 152.88 1235.62 175.00
      Q 1235.77 205.07 1235.77 235.04
      A 0.57 0.57 0.0 0 1 1235.20 235.61
      L 1206.56 235.60
      Q 1206.05 235.60 1206.05 235.10
      Q 1206.02 208.88 1206.04 182.76
      C 1206.05 165.17 1197.07 154.63 1178.53 159.09
      C 1166.81 161.91 1161.47 172.40 1161.43 183.75
      Q 1161.35 209.61 1161.38 235.37
      A 0.29 0.28 -90.0 0 1 1161.10 235.66
      L 1132.04 235.66
      A 0.62 0.62 0.0 0 1 1131.42 235.04
      L 1131.47 134.48
      A 0.56 0.55 -90.0 0 1 1132.02 133.92
      L 1159.72 133.99
      Q 1160.20 134.00 1160.20 134.48
      L 1160.03 144.48
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1328.23 170.38
      Q 1319.39 155.45 1302.28 156.96
      Q 1290.24 158.03 1284.27 166.73
      C 1278.82 174.66 1277.49 186.03 1280.68 195.25
      C 1284.40 206.04 1293.20 211.92 1304.50 212.40
      Q 1319.39 213.04 1327.86 199.12
      Q 1328.29 198.40 1329.03 198.80
      L 1350.80 210.66
      Q 1351.43 211.00 1351.12 211.64
      C 1344.09 226.23 1329.88 234.48 1314.13 236.41
      C 1280.99 240.47 1249.34 221.56 1248.90 185.50
      C 1248.43 147.48 1282.74 127.08 1317.76 133.20
      C 1332.21 135.73 1344.98 144.28 1351.27 157.83
      A 0.44 0.43 63.5 0 1 1351.09 158.39
      L 1328.53 170.46
      A 0.23 0.22 -29.6 0 1 1328.23 170.38
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 817.10 224.99
      Q 817.12 223.65 816.19 224.62
      C 806.26 234.95 793.97 238.21 779.74 236.92
      C 754.58 234.63 742.17 216.89 742.19 192.37
      Q 742.22 163.47 742.16 134.39
      A 0.59 0.58 90.0 0 1 742.74 133.80
      L 771.26 133.79
      A 0.55 0.54 -90.0 0 1 771.80 134.34
      Q 771.76 160.97 771.79 187.49
      C 771.81 200.10 776.90 211.10 791.25 211.35
      C 807.83 211.64 815.15 200.38 815.27 185.00
      Q 815.48 159.61 815.50 134.34
      A 0.54 0.54 0.0 0 1 816.04 133.80
      L 844.52 133.78
      Q 845.25 133.78 845.25 134.52
      L 845.25 235.04
      Q 845.25 235.59 844.71 235.59
      L 817.53 235.62
      Q 816.94 235.62 816.95 235.03
      L 817.10 224.99
      Z"
      />
      <path
        fill="#ffffff"
        d="
      M 1416.00 201.85
      Q 1416.21 201.82 1416.37 201.44
      Q 1430.01 168.85 1444.35 134.66
      Q 1444.64 133.98 1445.37 133.97
      L 1472.27 133.73
      A 0.67 0.67 0.0 0 1 1472.90 134.66
      Q 1448.91 190.89 1425.19 246.75
      C 1418.40 262.76 1405.27 273.89 1387.35 273.74
      Q 1384.67 273.72 1382.01 273.63
      Q 1368.56 273.18 1357.41 265.36
      A 1.22 1.22 0.0 0 1 1357.02 263.79
      L 1367.41 243.72
      Q 1367.66 243.22 1368.09 243.58
      C 1375.84 250.12 1389.91 253.49 1396.99 243.73
      C 1398.73 241.33 1401.59 237.44 1400.40 234.68
      Q 1378.85 184.82 1357.25 134.67
      A 0.51 0.50 -11.7 0 1 1357.72 133.97
      L 1387.07 133.95
      A 0.98 0.97 20.5 0 1 1387.97 134.55
      L 1415.65 201.65
      Q 1415.75 201.88 1416.00 201.85
      Z"
      />
      <path
        fill="#08615b"
        d="
      M 197.08 247.23
      Q 218.69 234.75 240.31 222.29
      C 244.84 219.69 245.80 216.36 245.80 211.00
      Q 245.81 175.26 245.81 139.68
      A 0.56 0.56 0.0 0 1 246.53 139.14
      C 249.83 140.11 253.38 140.96 256.42 142.25
      C 307.47 163.92 317.77 230.28 275.08 265.88
      Q 270.05 270.08 258.39 276.84
      Q 227.56 294.70 196.72 312.56
      Q 184.32 319.74 179.03 321.82
      C 162.54 328.30 143.76 328.42 127.12 322.16
      Q 121.45 320.03 109.35 313.04
      Q 58.76 283.83 8.20 254.56
      C 6.90 253.81 5.49 253.24 5.53 251.57
      A 2.26 2.24 75.8 0 1 6.65 249.67
      L 58.08 219.86
      Q 59.38 219.11 60.69 219.86
      Q 103.25 244.46 146.19 269.31
      Q 153.01 273.25 159.08 269.61
      Q 177.99 258.26 197.08 247.23
      Z"
      />
    </svg>
  );
}
export default Securrency;
