function Biconomy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#ff4e17"
        d="
  M 206.05 134.62
  C 208.71 136.34 211.62 138.11 213.94 139.89
  C 251.30 168.62 261.67 219.69 236.14 259.89
  Q 214.00 294.75 173.70 300.54
  Q 166.93 301.51 153.74 301.51
  Q 82.11 301.49 10.35 301.50
  A 0.35 0.34 0.0 0 1 10.00 301.16
  Q 10.00 175.02 10.00 48.79
  Q 10.00 39.68 11.43 35.38
  C 14.58 25.93 22.48 18.56 32.17 15.99
  Q 35.86 15.01 44.41 15.03
  Q 107.83 15.22 129.74 15.43
  C 168.86 15.80 205.63 39.26 213.07 79.47
  Q 218.20 107.15 205.83 133.84
  Q 205.60 134.33 206.05 134.62
  Z
  M 69.40 100.87
  C 71.58 127.63 88.32 151.78 115.50 157.16
  C 126.30 159.29 139.90 158.05 150.44 154.45
  Q 188.27 141.53 192.15 100.75
  C 195.37 66.78 172.39 43.47 140.30 38.40
  Q 134.40 37.47 122.14 37.46
  Q 84.72 37.43 47.31 37.42
  Q 46.86 37.42 46.73 37.84
  Q 46.68 38.02 46.79 38.21
  Q 46.92 38.43 47.36 38.59
  C 60.69 43.38 68.78 55.71 68.86 69.75
  C 68.92 80.06 68.57 90.60 69.40 100.87
  Z"
      />
      <path
        fill="#ff4e17"
        d="
  M 544.51 146.51
  C 562.47 156.65 574.79 172.26 574.51 193.74
  C 574.15 221.54 558.37 244.83 529.99 250.01
  Q 521.35 251.58 513.25 251.55
  Q 473.12 251.39 432.69 251.34
  Q 432.25 251.34 432.25 250.91
  L 432.25 78.77
  A 0.48 0.48 0.0 0 1 432.72 78.29
  Q 466.72 78.27 500.75 78.30
  Q 515.46 78.31 520.66 79.56
  Q 535.63 83.16 544.74 96.98
  C 554.29 111.46 555.16 131.55 544.36 145.79
  Q 544.02 146.24 544.51 146.51
  Z
  M 464.79 105.13
  L 464.73 137.15
  A 0.43 0.43 0.0 0 0 465.16 137.58
  L 503.76 137.65
  A 16.67 16.42 0.1 0 0 520.46 121.26
  L 520.46 121.22
  A 16.67 16.42 0.1 0 0 503.82 104.77
  L 465.22 104.70
  A 0.43 0.43 0.0 0 0 464.79 105.13
  Z
  M 541.62 199.62
  C 543.65 184.88 536.25 171.63 522.50 166.06
  Q 518.01 164.24 514.50 164.22
  Q 489.93 164.07 465.29 164.21
  Q 464.76 164.21 464.76 164.75
  L 464.76 224.24
  Q 464.76 224.75 465.27 224.75
  Q 486.62 224.84 507.88 224.76
  Q 517.60 224.72 522.43 223.27
  Q 539.06 218.26 541.62 199.62
  Z"
      />
      <circle fill="#ff4e17" cx="602.98" cy="98.89" r="19.99" />
      <path
        fill="#ff4e17"
        d="
  M 718.07 169.91
  C 707.58 153.25 684.08 153.42 672.16 167.27
  C 663.16 177.75 661.97 194.46 666.98 206.98
  C 676.09 229.72 708.80 233.01 719.63 209.75
  Q 719.88 209.21 720.47 209.21
  L 753.25 209.22
  A 0.36 0.36 0.0 0 1 753.60 209.68
  C 747.19 233.43 729.71 250.53 705.00 254.35
  C 672.83 259.32 642.24 241.30 633.92 209.26
  C 624.60 173.35 643.79 136.43 681.43 128.88
  C 708.89 123.38 735.85 134.90 748.83 160.02
  Q 748.98 160.32 748.66 160.42
  L 718.93 170.21
  Q 718.38 170.39 718.07 169.91
  Z"
      />
      <path
        fill="#ff4e17"
        d="
  M 876.99 159.12
  C 894.68 191.90 882.53 236.11 847.06 250.32
  C 816.69 262.49 780.04 251.26 765.64 221.07
  C 757.46 203.92 756.91 182.79 764.04 165.24
  C 783.13 118.19 852.77 114.28 876.99 159.12
  Z
  M 792.13 198.87
  C 797.92 234.68 846.63 234.57 852.00 198.50
  C 854.36 182.66 849.17 165.04 832.69 159.04
  C 819.86 154.37 805.31 158.49 797.56 169.89
  C 791.86 178.29 790.50 188.82 792.13 198.87
  Z"
      />
      <path
        fill="#ff4e17"
        d="
  M 1029.71 154.92
  C 1045.22 130.94 1076.54 122.03 1103.19 130.99
  C 1156.62 148.95 1159.89 228.32 1108.02 250.04
  C 1087.28 258.73 1062.09 256.40 1043.85 242.89
  C 1016.87 222.90 1011.75 182.68 1029.71 154.92
  Z
  M 1103.95 166.29
  C 1092.69 154.43 1071.77 153.89 1060.52 166.27
  C 1046.22 181.99 1048.75 212.78 1069.01 222.72
  Q 1075.50 225.90 1082.76 225.65
  C 1098.50 225.11 1108.92 214.86 1111.89 200.00
  C 1114.15 188.74 1111.94 174.70 1103.95 166.29
  Z"
      />
      <path
        fill="#ff4e17"
        d="
  M 1257.55 147.37
  Q 1259.50 145.23 1261.28 142.99
  Q 1270.64 131.27 1285.49 128.68
  C 1314.83 123.56 1338.73 141.12 1338.75 171.75
  Q 1338.77 211.49 1338.81 251.16
  A 0.33 0.33 0.0 0 1 1338.48 251.49
  L 1307.98 251.50
  A 0.62 0.62 0.0 0 1 1307.36 250.88
  Q 1307.36 215.02 1307.44 179.00
  Q 1307.45 175.51 1306.14 171.05
  C 1301.49 155.12 1280.20 153.29 1269.83 164.33
  Q 1264.19 170.34 1264.13 180.25
  Q 1263.89 215.73 1263.90 251.09
  A 0.40 0.40 0.0 0 1 1263.50 251.49
  L 1232.01 251.50
  A 0.27 0.26 90.0 0 1 1231.75 251.23
  Q 1231.75 215.28 1231.73 179.25
  C 1231.71 148.70 1187.92 150.75 1188.00 181.00
  Q 1188.08 215.97 1188.07 250.93
  Q 1188.07 251.50 1187.50 251.50
  L 1155.97 251.50
  A 0.35 0.35 0.0 0 1 1155.62 251.15
  L 1155.62 131.72
  A 0.41 0.40 -0.0 0 1 1156.03 131.32
  L 1185.97 131.32
  Q 1186.61 131.32 1186.60 131.96
  L 1186.48 144.52
  Q 1186.46 146.26 1187.41 144.79
  C 1202.25 121.70 1244.17 121.85 1256.96 147.29
  Q 1257.19 147.76 1257.55 147.37
  Z"
      />
      <path
        fill="#ff4e17"
        d="
  M 929.41 145.26
  C 938.36 131.46 955.27 126.38 971.13 128.62
  C 990.80 131.40 1002.72 145.31 1005.94 164.26
  Q 1006.74 168.94 1006.75 178.28
  Q 1006.79 214.79 1006.78 251.20
  A 0.30 0.29 -90.0 0 1 1006.49 251.50
  L 974.78 251.49
  A 0.35 0.35 0.0 0 1 974.43 251.14
  Q 974.43 216.23 974.42 181.25
  C 974.42 170.67 970.15 161.15 959.13 158.26
  C 946.04 154.83 933.61 161.42 930.48 174.85
  Q 929.73 178.09 929.73 185.22
  Q 929.72 218.07 929.75 250.75
  A 0.74 0.74 0.0 0 1 929.01 251.50
  L 897.52 251.49
  A 0.23 0.22 -0.0 0 1 897.29 251.27
  L 897.30 132.01
  A 0.69 0.69 0.0 0 1 897.99 131.32
  L 928.30 131.32
  A 0.47 0.47 0.0 0 1 928.77 131.80
  L 928.66 145.04
  Q 928.64 146.44 929.41 145.26
  Z"
      />
      <rect
        fill="#ff4e17"
        x="586.94"
        y="131.32"
        width="32.44"
        height="120.18"
        rx="0.29"
      />
      <path
        fill="#ff4e17"
        d="
  M 1407.49 201.04
  L 1436.66 131.52
  A 0.32 0.32 0.0 0 1 1436.95 131.32
  L 1470.87 131.32
  A 0.32 0.32 0.0 0 1 1471.16 131.77
  L 1396.09 298.61
  A 0.32 0.32 0.0 0 1 1395.80 298.80
  L 1362.13 298.81
  A 0.32 0.32 0.0 0 1 1361.83 298.36
  L 1389.86 236.47
  A 0.32 0.32 0.0 0 0 1389.85 236.19
  L 1339.15 131.78
  A 0.32 0.32 0.0 0 1 1339.44 131.32
  L 1375.04 131.32
  A 0.32 0.32 0.0 0 1 1375.34 131.51
  L 1406.91 201.05
  A 0.32 0.32 0.0 0 0 1407.49 201.04
  Z"
      />
    </svg>
  );
}

export default Biconomy;
