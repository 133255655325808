function Polytrade() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#768cfa"
        d="
  M 221.81 146.57
  A 0.70 0.70 0.0 0 0 222.64 145.88
  Q 222.45 132.43 222.53 118.87
  C 222.55 114.69 220.70 111.87 217.31 109.92
  Q 198.53 99.10 134.36 62.61
  C 129.23 59.69 124.09 62.34 118.28 65.70
  Q 62.33 98.07 6.19 130.27
  A 0.63 0.63 0.0 0 1 5.26 129.86
  Q 5.23 129.73 5.12 129.45
  Q 5.03 129.24 5.03 129.04
  Q 5.00 113.77 5.03 98.49
  C 5.04 93.39 11.18 92.19 14.65 90.17
  Q 68.19 59.01 121.73 27.86
  Q 121.82 27.81 124.20 26.20
  Q 128.19 23.47 132.34 26.18
  Q 134.12 27.35 134.20 27.40
  Q 190.07 60.00 246.01 92.51
  C 250.12 94.90 251.54 96.76 251.54 101.82
  Q 251.52 144.05 251.54 186.25
  Q 251.54 188.38 250.02 190.48
  Q 245.04 197.37 237.53 193.00
  Q 186.64 163.40 135.78 133.74
  Q 133.28 132.28 130.46 131.57
  C 127.42 130.81 124.17 134.77 121.63 136.21
  Q 81.78 158.96 41.95 181.75
  C 38.73 183.60 36.02 184.70 35.99 188.50
  Q 35.74 220.12 35.68 251.75
  Q 35.67 252.54 35.49 252.78
  Q 35.02 253.40 34.44 253.08
  Q 21.45 245.77 9.07 238.45
  C 6.61 237.00 5.02 234.08 5.02 230.96
  Q 5.02 199.85 5.02 169.14
  A 2.81 2.78 -24.2 0 1 5.74 167.27
  Q 8.34 164.36 11.81 162.31
  Q 66.35 130.18 120.97 98.18
  C 126.94 94.68 129.36 93.17 136.28 97.20
  Q 178.49 121.76 220.79 146.17
  Q 221.35 146.49 221.81 146.57
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 417.84 155.27
  C 423.47 128.82 447.06 110.25 473.88 110.01
  C 506.70 109.72 537.44 135.99 534.26 170.49
  Q 532.09 193.97 516.89 208.83
  C 491.35 233.80 450.20 229.19 427.72 202.53
  C 417.06 189.88 414.49 171.00 417.84 155.27
  Z
  M 511.69 170.88
  C 514.36 145.01 495.00 128.16 469.85 131.50
  C 453.08 133.73 442.04 145.67 440.49 162.74
  C 439.22 176.61 442.97 190.31 454.63 198.19
  C 468.38 207.48 490.16 206.25 501.43 193.44
  C 506.52 187.65 510.85 179.09 511.69 170.88
  Z"
      />
      <path
        fill="#d0d0d1"
        d="
  M 1173.38 195.31
  L 1114.78 195.30
  Q 1114.27 195.30 1114.08 195.76
  L 1103.09 221.99
  Q 1102.99 222.24 1102.72 222.23
  L 1095.65 222.13
  Q 1095.47 222.13 1095.54 221.96
  L 1142.52 111.28
  A 2.39 2.39 0.0 0 1 1146.92 111.28
  L 1193.90 221.99
  Q 1193.97 222.14 1193.80 222.14
  L 1185.27 222.16
  Q 1185.11 222.16 1185.05 222.01
  L 1174.00 195.72
  Q 1173.83 195.31 1173.38 195.31
  Z
  M 1119.82 188.48
  A 0.54 0.54 0.0 0 0 1120.32 189.22
  L 1172.03 189.22
  A 0.54 0.54 0.0 0 0 1172.52 188.47
  L 1145.96 125.54
  A 0.54 0.54 0.0 0 0 1144.96 125.55
  L 1119.82 188.48
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 734.70 154.28
  A 0.60 0.60 0.0 0 0 735.74 154.27
  L 758.91 111.93
  Q 759.17 111.45 759.72 111.45
  L 782.51 111.46
  A 0.22 0.22 0.0 0 1 782.70 111.79
  Q 765.77 141.84 748.79 171.99
  C 747.06 175.07 745.03 177.96 745.00 181.65
  Q 744.86 201.84 745.00 221.95
  Q 745.00 222.48 744.47 222.48
  L 723.15 222.42
  A 0.57 0.56 -90.0 0 1 722.59 221.85
  Q 722.80 204.65 722.59 187.25
  C 722.54 183.47 722.78 179.82 721.11 176.33
  Q 719.97 173.92 718.66 171.61
  Q 701.79 141.68 684.99 111.88
  A 0.28 0.28 0.0 0 1 685.23 111.46
  L 708.99 111.45
  A 1.51 1.50 74.7 0 1 710.31 112.21
  L 734.70 154.28
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 311.71 183.02
  A 0.71 0.71 0.0 0 0 311.00 183.73
  L 311.00 221.74
  A 0.74 0.74 0.0 0 1 310.26 222.48
  L 290.55 222.45
  A 0.65 0.65 0.0 0 1 289.90 221.80
  L 289.88 112.50
  Q 289.88 111.62 290.77 111.61
  Q 311.46 111.37 332.75 111.50
  C 351.30 111.60 370.27 121.97 372.51 141.74
  C 373.68 152.14 370.81 163.85 362.63 170.94
  C 354.53 177.96 341.77 183.08 331.00 183.03
  Q 321.20 182.98 311.71 183.02
  Z
  M 310.97 131.75
  L 311.03 162.97
  A 0.66 0.66 0.0 0 0 311.69 163.63
  L 331.89 163.60
  A 15.68 15.68 0.0 0 0 347.54 147.89
  L 347.54 146.71
  A 15.68 15.68 0.0 0 0 331.83 131.06
  L 311.63 131.09
  A 0.66 0.66 0.0 0 0 310.97 131.75
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 610.54 201.62
  Q 610.70 202.93 612.01 202.93
  L 658.96 203.05
  A 0.43 0.42 -90.0 0 1 659.38 203.48
  L 659.41 223.02
  Q 659.41 223.64 658.78 223.64
  Q 627.22 223.83 595.76 223.73
  Q 591.96 223.72 588.97 223.37
  Q 587.73 223.23 587.73 221.98
  L 587.78 112.71
  Q 587.78 111.45 589.04 111.46
  L 609.72 111.54
  A 0.51 0.51 0.0 0 1 610.23 112.05
  Q 610.29 151.85 610.02 191.75
  Q 609.98 197.21 610.54 201.62
  Z"
      />
      <path
        fill="#d0d0d1"
        d="
  M 864.04 119.55
  L 825.04 119.44
  A 0.69 0.69 0.0 0 1 824.35 118.77
  L 824.22 112.52
  A 0.69 0.69 0.0 0 1 824.90 111.82
  L 912.76 111.71
  A 0.69 0.69 0.0 0 1 913.46 112.40
  L 913.50 118.81
  A 0.69 0.69 0.0 0 1 912.81 119.51
  L 873.40 119.50
  A 0.69 0.69 0.0 0 0 872.71 120.19
  L 872.74 222.93
  A 0.69 0.69 0.0 0 1 872.06 223.62
  L 865.43 223.69
  A 0.69 0.69 0.0 0 1 864.73 223.00
  L 864.73 120.24
  A 0.69 0.69 0.0 0 0 864.04 119.55
  Z"
      />
      <path
        fill="#d0d0d1"
        d="
  M 976.49 173.72
  L 976.53 223.45
  Q 976.53 223.68 976.30 223.68
  L 969.08 223.72
  Q 968.87 223.72 968.87 223.51
  L 968.86 112.70
  Q 968.86 111.77 969.79 111.76
  Q 988.85 111.69 1008.50 111.74
  Q 1012.24 111.75 1017.10 113.16
  C 1029.83 116.84 1038.10 126.81 1039.71 140.03
  Q 1039.91 141.71 1039.54 144.20
  C 1039.27 146.06 1039.29 148.04 1038.82 149.74
  C 1035.52 161.55 1027.54 168.81 1015.54 171.23
  A 1.06 1.06 0.0 0 0 1014.90 172.91
  L 1051.39 221.51
  Q 1051.95 222.25 1051.03 222.25
  L 1044.70 222.24
  A 2.27 2.27 0.0 0 1 1043.06 221.54
  Q 1041.05 219.44 1039.33 217.19
  Q 1022.57 195.30 1005.72 173.50
  A 0.94 0.93 -19.2 0 0 1004.99 173.15
  L 977.03 173.18
  Q 976.49 173.18 976.49 173.72
  Z
  M 976.51 119.89
  L 976.51 164.67
  A 0.38 0.38 0.0 0 0 976.89 165.05
  L 1008.75 165.05
  A 22.41 21.86 90.0 0 0 1030.61 142.64
  L 1030.61 141.92
  A 22.41 21.86 90.0 0 0 1008.75 119.51
  L 976.89 119.51
  A 0.38 0.38 0.0 0 0 976.51 119.89
  Z"
      />
      <path
        fill="#d0d0d1"
        d="
  M 1250.94 112.41
  A 0.56 0.56 0.0 0 1 1251.50 111.85
  L 1286.07 111.79
  A 56.97 53.65 -0.1 0 1 1343.14 165.34
  L 1343.14 168.42
  A 56.97 53.65 -0.1 0 1 1286.27 222.17
  L 1251.70 222.23
  A 0.56 0.56 0.0 0 1 1251.14 221.67
  L 1250.94 112.41
  Z
  M 1260.54 121.37
  L 1260.06 214.69
  A 0.62 0.62 0.0 0 0 1260.67 215.32
  L 1287.50 215.46
  A 47.71 45.28 0.3 0 0 1335.45 170.43
  L 1335.47 166.43
  A 47.71 45.28 0.3 0 0 1288.00 120.90
  L 1261.17 120.76
  A 0.62 0.62 0.0 0 0 1260.54 121.37
  Z"
      />
      <path
        fill="#d0d0d1"
        d="
  M 1411.92 119.87
  L 1411.90 159.14
  A 0.33 0.33 0.0 0 0 1412.23 159.47
  L 1459.32 159.73
  A 0.33 0.33 0.0 0 1 1459.65 160.06
  L 1459.69 166.96
  A 0.33 0.33 0.0 0 1 1459.36 167.29
  L 1412.24 167.31
  A 0.33 0.33 0.0 0 0 1411.91 167.64
  L 1411.91 215.36
  A 0.33 0.33 0.0 0 0 1412.24 215.69
  L 1475.01 215.72
  A 0.33 0.33 0.0 0 1 1475.33 216.05
  L 1475.22 223.34
  A 0.33 0.33 0.0 0 1 1474.90 223.66
  L 1403.04 223.68
  A 0.33 0.33 0.0 0 1 1402.71 223.35
  L 1402.71 112.06
  A 0.33 0.33 0.0 0 1 1403.04 111.73
  L 1472.21 111.75
  A 0.33 0.33 0.0 0 1 1472.54 112.08
  L 1472.65 119.19
  A 0.33 0.33 0.0 0 1 1472.32 119.52
  L 1412.25 119.54
  A 0.33 0.33 0.0 0 0 1411.92 119.87
  Z"
      />
      <path
        fill="#768cfa"
        d="
  M 132.51 167.50
  Q 133.29 167.73 133.98 168.16
  Q 157.78 182.90 182.15 197.86
  Q 185.02 199.62 186.18 202.14
  A 1.14 1.13 -44.7 0 1 186.17 203.12
  Q 185.07 205.43 182.90 206.67
  Q 158.54 220.62 134.27 234.73
  Q 131.98 236.06 129.25 235.93
  Q 127.28 235.84 124.60 234.26
  Q 100.71 220.13 76.53 206.51
  C 65.39 200.23 72.22 197.06 79.60 193.03
  Q 101.37 181.12 123.02 169.02
  C 126.43 167.11 128.99 166.43 132.51 167.50
  Z"
      />
      <path
        fill="#768cfa"
        d="
  M 125.83 269.16
  C 129.63 271.24 134.24 268.45 137.41 266.64
  Q 177.30 243.93 217.20 221.23
  C 220.67 219.26 224.63 220.00 228.25 222.07
  Q 240.25 228.94 252.43 235.95
  Q 253.22 236.41 252.63 237.11
  Q 252.46 237.32 251.87 237.66
  Q 193.20 271.05 134.61 304.59
  C 130.46 306.97 126.76 306.22 122.51 303.78
  Q 97.33 289.31 72.10 274.91
  C 68.22 272.70 67.56 267.97 67.54 263.32
  Q 67.51 250.16 67.53 237.06
  A 0.36 0.35 -77.0 0 1 68.04 236.74
  Q 70.62 238.01 72.97 239.36
  Q 109.33 260.12 125.83 269.16
  Z"
      />
    </svg>
  );
}

export default Polytrade;
