function Socket({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1200.00 1200.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#868686"
          vectorEffect="non-scaling-stroke"
          d="
  M 770.33 1071.13
  C 949.42 1005.55 1084.25 831.53 1099.44 641.29
  Q 1101.64 613.85 1100.69 591.25
  Q 1099.87 571.68 1098.49 559.25
  Q 1097.50 550.30 1094.18 526.23
  Q 1093.80 523.51 1093.44 520.76
  Q 1081.45 429.46 1034.57 350.41
  C 916.98 152.15 658.95 43.67 438.13 126.74
  Q 428.50 130.37 418.87 134.00
  C 228.50 205.76 94.96 401.42 100.89 605.23
  C 101.22 616.47 102.38 630.58 103.07 643.26
  C 114.22 847.58 269.32 1024.14 462.28 1081.16
  Q 529.77 1101.10 600.08 1099.79
  C 607.56 1099.65 616.18 1098.96 624.25 1098.57
  C 626.11 1098.48 628.45 1097.93 630.58 1097.86
  C 678.41 1096.40 725.41 1087.57 770.33 1071.13"
        />
        <path
          stroke="#868686"
          vectorEffect="non-scaling-stroke"
          d="
  M 655.73 537.68
  C 717.26 444.12 779.59 351.07 840.80 257.32
  Q 841.20 256.72 840.63 257.16
  C 710.93 358.33 585.79 465.24 458.82 569.78
  Q 458.33 570.18 458.97 570.19
  L 519.40 570.84
  Q 540.40 571.07 561.40 571.05
  L 633.22 571.00
  Q 633.73 571.00 634.00 570.58
  Q 644.91 554.12 655.73 537.68"
        />
        <path
          stroke="#868686"
          vectorEffect="non-scaling-stroke"
          d="
  M 566.22 629.05
  L 358.83 942.89
  Q 358.25 943.76 359.07 943.12
  C 489.13 841.81 613.88 734.06 741.86 630.16
  Q 742.29 629.82 741.74 629.82
  L 668.37 628.98
  A 6.93 1.00 0.6 0 0 667.39 628.97
  L 566.58 628.86
  A 0.44 0.44 0.0 0 0 566.22 629.05"
        />
      </g>
      <path
        fill="#0d0d0d"
        d="
  M 437.85 1161.74
  C 391.38 1148.82 345.90 1129.25 304.50 1104.51
  C 289.79 1095.72 274.98 1086.53 261.12 1076.71
  C 95.06 959.08 0.49 755.85 17.72 553.25
  Q 18.61 542.76 21.72 517.64
  C 47.88 306.67 202.50 121.27 401.56 49.85
  Q 410.84 46.52 420.52 43.52
  C 584.97 -7.40 763.70 13.02 910.35 104.64
  C 1033.39 181.50 1122.11 301.99 1162.48 440.75
  C 1189.08 532.22 1191.51 632.39 1171.22 724.76
  C 1138.50 873.70 1046.79 1007.18 918.94 1089.95
  Q 827.35 1149.24 723.23 1170.93
  Q 579.00 1200.97 437.85 1161.74
  Z
  M 770.33 1071.13
  C 949.42 1005.55 1084.25 831.53 1099.44 641.29
  Q 1101.64 613.85 1100.69 591.25
  Q 1099.87 571.68 1098.49 559.25
  Q 1097.50 550.30 1094.18 526.23
  Q 1093.80 523.51 1093.44 520.76
  Q 1081.45 429.46 1034.57 350.41
  C 916.98 152.15 658.95 43.67 438.13 126.74
  Q 428.50 130.37 418.87 134.00
  C 228.50 205.76 94.96 401.42 100.89 605.23
  C 101.22 616.47 102.38 630.58 103.07 643.26
  C 114.22 847.58 269.32 1024.14 462.28 1081.16
  Q 529.77 1101.10 600.08 1099.79
  C 607.56 1099.65 616.18 1098.96 624.25 1098.57
  C 626.11 1098.48 628.45 1097.93 630.58 1097.86
  C 678.41 1096.40 725.41 1087.57 770.33 1071.13
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 770.33 1071.13
  C 725.41 1087.57 678.41 1096.40 630.58 1097.86
  C 628.45 1097.93 626.11 1098.48 624.25 1098.57
  C 616.18 1098.96 607.56 1099.65 600.08 1099.79
  Q 529.77 1101.10 462.28 1081.16
  C 269.32 1024.14 114.22 847.58 103.07 643.26
  C 102.38 630.58 101.22 616.47 100.89 605.23
  C 94.96 401.42 228.50 205.76 418.87 134.00
  Q 428.50 130.37 438.13 126.74
  C 658.95 43.67 916.98 152.15 1034.57 350.41
  Q 1081.45 429.46 1093.44 520.76
  Q 1093.80 523.51 1094.18 526.23
  Q 1097.50 550.30 1098.49 559.25
  Q 1099.87 571.68 1100.69 591.25
  Q 1101.64 613.85 1099.44 641.29
  C 1084.25 831.53 949.42 1005.55 770.33 1071.13
  Z
  M 655.73 537.68
  C 717.26 444.12 779.59 351.07 840.80 257.32
  Q 841.20 256.72 840.63 257.16
  C 710.93 358.33 585.79 465.24 458.82 569.78
  Q 458.33 570.18 458.97 570.19
  L 519.40 570.84
  Q 540.40 571.07 561.40 571.05
  L 633.22 571.00
  Q 633.73 571.00 634.00 570.58
  Q 644.91 554.12 655.73 537.68
  Z
  M 566.22 629.05
  L 358.83 942.89
  Q 358.25 943.76 359.07 943.12
  C 489.13 841.81 613.88 734.06 741.86 630.16
  Q 742.29 629.82 741.74 629.82
  L 668.37 628.98
  A 6.93 1.00 0.6 0 0 667.39 628.97
  L 566.58 628.86
  A 0.44 0.44 0.0 0 0 566.22 629.05
  Z"
      />
      <path
        fill="#0d0d0d"
        d="
  M 655.73 537.68
  Q 644.91 554.12 634.00 570.58
  Q 633.73 571.00 633.22 571.00
  L 561.40 571.05
  Q 540.40 571.07 519.40 570.84
  L 458.97 570.19
  Q 458.33 570.18 458.82 569.78
  C 585.79 465.24 710.93 358.33 840.63 257.16
  Q 841.20 256.72 840.80 257.32
  C 779.59 351.07 717.26 444.12 655.73 537.68
  Z"
      />
      <path
        fill="#0d0d0d"
        d="
  M 358.83 942.89
  L 566.22 629.05
  A 0.44 0.44 0.0 0 1 566.58 628.86
  L 667.39 628.97
  A 6.93 1.00 0.6 0 1 668.37 628.98
  L 741.74 629.82
  Q 742.29 629.82 741.86 630.16
  C 613.88 734.06 489.13 841.81 359.07 943.12
  Q 358.25 943.76 358.83 942.89
  Z"
      />
    </svg>
  );
}

export default Socket;
