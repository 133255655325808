function Hardhat({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 256.00 177.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#ffed27"
          vectorEffect="non-scaling-stroke"
          d="
      M 219.75 52.18
      Q 219.90 58.24 221.65 62.75
      Q 222.24 64.27 222.49 65.39
      C 222.85 66.98 223.46 68.38 223.63 69.86
      C 224.42 76.57 226.07 82.26 226.56 85.88
      Q 229.14 104.91 229.33 111.25
      Q 229.73 124.23 229.57 136.78
      Q 229.56 137.56 230.32 137.71
      L 245.41 140.84"
        />
        <path
          stroke="#f8e32d"
          vectorEffect="non-scaling-stroke"
          d="
      M 255.44 161.95
      Q 250.16 158.15 242.24 156.46
      Q 224.75 152.71 212.01 151.37
      C 199.20 150.02 194.42 158.91 184.46 163.84
      Q 174.20 168.91 163.49 168.94
      Q 129.81 169.04 96.12 168.99
      C 87.02 168.97 78.85 167.67 70.75 163.46
      C 66.97 161.50 63.15 158.30 59.46 155.58
      Q 57.15 153.87 55.76 153.44
      Q 55.29 153.29 54.93 152.96
      Q 54.55 152.61 54.06 152.51
      C 51.13 151.91 48.85 151.02 46.00 151.26
      Q 29.56 152.66 11.50 156.98
      C 9.07 157.57 3.74 159.45 1.59 161.64
      Q 1.13 162.11 0.44 162.13"
        />
        <path
          stroke="#f8e32d"
          vectorEffect="non-scaling-stroke"
          d="
      M 10.82 140.79
      C 27.29 136.74 43.99 135.00 60.95 133.61
      A 0.70 0.69 -1.8 0 0 61.59 132.93
      Q 61.80 124.99 61.29 117.00
      Q 61.27 116.61 61.60 115.82
      Q 61.80 115.36 61.74 114.86
      Q 61.30 111.24 61.89 107.72
      Q 62.48 104.21 62.55 101.71
      C 62.62 98.72 63.39 95.93 63.41 93.01
      C 63.43 90.81 64.18 89.04 64.36 87.02
      Q 64.59 84.50 64.81 83.51
      Q 65.77 79.04 66.65 74.55
      Q 70.10 56.88 76.53 40.07
      Q 80.31 30.18 85.43 20.90
      Q 86.28 19.36 86.82 17.67
      Q 86.95 17.24 87.30 16.96
      Q 87.97 16.41 88.02 15.05"
        />
        <path
          stroke="#b7b05f"
          vectorEffect="non-scaling-stroke"
          d="
      M 128.45 99.23
      L 154.18 84.77
      Q 155.09 84.26 154.54 83.37
      Q 141.48 62.26 128.99 42.03
      Q 128.87 41.82 128.39 41.77"
        />
        <path
          stroke="#857d2c"
          vectorEffect="non-scaling-stroke"
          d="
      M 128.39 41.77
      Q 128.14 41.19 127.84 41.13
      Q 127.59 41.08 127.33 41.50
      Q 114.33 62.64 101.64 83.10
      Q 101.06 84.04 102.01 84.60
      Q 112.34 90.62 120.20 95.34
      Q 124.82 98.11 127.81 99.44
      Q 128.30 99.65 128.45 99.23"
        />
        <path
          stroke="#3c3d3d"
          vectorEffect="non-scaling-stroke"
          d="
      M 128.39 41.77
      Q 128.01 43.03 128.01 45.75
      Q 127.98 72.12 128.02 98.50
      Q 128.02 99.26 128.45 99.23"
        />
        <path
          stroke="#b7b05f"
          vectorEffect="non-scaling-stroke"
          d="
      M 128.44 130.97
      Q 128.60 130.91 128.71 130.89
      Q 128.91 130.85 129.03 130.69
      L 153.19 96.43
      Q 155.01 93.86 152.33 95.51
      L 127.97 110.55"
        />
        <path
          stroke="#857d2c"
          vectorEffect="non-scaling-stroke"
          d="
      M 127.97 110.55
      L 102.57 94.90
      Q 101.07 93.98 102.09 95.41
      L 127.60 131.53
      Q 127.92 131.98 128.25 131.54
      Q 128.40 131.33 128.44 130.97"
        />
        <path
          stroke="#3c3d3d"
          vectorEffect="non-scaling-stroke"
          d="
      M 127.97 110.55
      C 128.04 117.07 127.33 124.60 128.44 130.97"
        />
      </g>
      <path
        fill="#fff04d"
        d="
      M 125.10 0.00
      L 132.01 0.00
      Q 146.90 0.68 161.19 4.74
      Q 161.69 4.88 162.06 5.25
      Q 162.42 5.60 162.90 5.56
      Q 163.40 5.52 163.79 5.84
      Q 167.97 9.26 168.13 14.72
      Q 168.14 15.25 168.66 15.40
      C 170.64 15.94 172.10 17.10 174.11 17.52
      Q 174.78 17.67 175.14 17.85
      Q 180.47 20.53 185.83 23.19
      C 186.73 23.64 187.28 24.45 188.24 24.92
      Q 190.06 25.80 190.11 25.83
      Q 200.13 32.09 205.16 37.08
      C 207.98 39.87 211.09 42.40 213.63 45.28
      Q 216.73 48.80 219.75 52.18
      Q 219.90 58.24 221.65 62.75
      Q 222.24 64.27 222.49 65.39
      C 222.85 66.98 223.46 68.38 223.63 69.86
      C 224.42 76.57 226.07 82.26 226.56 85.88
      Q 229.14 104.91 229.33 111.25
      Q 229.73 124.23 229.57 136.78
      Q 229.56 137.56 230.32 137.71
      L 245.41 140.84
      Q 246.03 141.52 247.12 141.52
      A 1.62 1.58 61.6 0 1 248.02 141.80
      Q 249.03 142.50 250.25 142.53
      Q 250.76 142.55 250.96 143.03
      Q 251.21 143.66 251.89 143.55
      Q 252.38 143.48 252.75 143.81
      L 256.00 146.81
      L 256.00 161.24
      Q 255.74 161.39 255.44 161.95
      Q 250.16 158.15 242.24 156.46
      Q 224.75 152.71 212.01 151.37
      C 199.20 150.02 194.42 158.91 184.46 163.84
      Q 174.20 168.91 163.49 168.94
      Q 129.81 169.04 96.12 168.99
      C 87.02 168.97 78.85 167.67 70.75 163.46
      C 66.97 161.50 63.15 158.30 59.46 155.58
      Q 57.15 153.87 55.76 153.44
      Q 55.29 153.29 54.93 152.96
      Q 54.55 152.61 54.06 152.51
      C 51.13 151.91 48.85 151.02 46.00 151.26
      Q 29.56 152.66 11.50 156.98
      C 9.07 157.57 3.74 159.45 1.59 161.64
      Q 1.13 162.11 0.44 162.13
      Q 0.41 161.85 0.00 161.63
      L 0.00 147.02
      C 2.88 143.01 7.00 142.35 10.82 140.79
      C 27.29 136.74 43.99 135.00 60.95 133.61
      A 0.70 0.69 -1.8 0 0 61.59 132.93
      Q 61.80 124.99 61.29 117.00
      Q 61.27 116.61 61.60 115.82
      Q 61.80 115.36 61.74 114.86
      Q 61.30 111.24 61.89 107.72
      Q 62.48 104.21 62.55 101.71
      C 62.62 98.72 63.39 95.93 63.41 93.01
      C 63.43 90.81 64.18 89.04 64.36 87.02
      Q 64.59 84.50 64.81 83.51
      Q 65.77 79.04 66.65 74.55
      Q 70.10 56.88 76.53 40.07
      Q 80.31 30.18 85.43 20.90
      Q 86.28 19.36 86.82 17.67
      Q 86.95 17.24 87.30 16.96
      Q 87.97 16.41 88.02 15.05
      L 89.58 9.64
      A 1.28 1.26 80.1 0 1 90.03 8.99
      Q 90.77 8.43 91.03 7.56
      Q 91.17 7.07 91.59 6.80
      C 92.90 5.95 94.02 5.10 95.54 4.68
      Q 110.04 0.63 125.10 0.00
      Z
      M 128.45 99.23
      L 154.18 84.77
      Q 155.09 84.26 154.54 83.37
      Q 141.48 62.26 128.99 42.03
      Q 128.87 41.82 128.39 41.77
      Q 128.14 41.19 127.84 41.13
      Q 127.59 41.08 127.33 41.50
      Q 114.33 62.64 101.64 83.10
      Q 101.06 84.04 102.01 84.60
      Q 112.34 90.62 120.20 95.34
      Q 124.82 98.11 127.81 99.44
      Q 128.30 99.65 128.45 99.23
      Z
      M 128.44 130.97
      Q 128.60 130.91 128.71 130.89
      Q 128.91 130.85 129.03 130.69
      L 153.19 96.43
      Q 155.01 93.86 152.33 95.51
      L 127.97 110.55
      L 102.57 94.90
      Q 101.07 93.98 102.09 95.41
      L 127.60 131.53
      Q 127.92 131.98 128.25 131.54
      Q 128.40 131.33 128.44 130.97
      Z"
      />
      <path
        fill="#f0d50c"
        d="
      M 88.02 15.05
      Q 87.97 16.41 87.30 16.96
      Q 86.95 17.24 86.82 17.67
      Q 86.28 19.36 85.43 20.90
      Q 80.31 30.18 76.53 40.07
      Q 70.10 56.88 66.65 74.55
      Q 65.77 79.04 64.81 83.51
      Q 64.59 84.50 64.36 87.02
      C 64.18 89.04 63.43 90.81 63.41 93.01
      C 63.39 95.93 62.62 98.72 62.55 101.71
      Q 62.48 104.21 61.89 107.72
      Q 61.30 111.24 61.74 114.86
      Q 61.80 115.36 61.60 115.82
      Q 61.27 116.61 61.29 117.00
      Q 61.80 124.99 61.59 132.93
      A 0.70 0.69 -1.8 0 1 60.95 133.61
      C 43.99 135.00 27.29 136.74 10.82 140.79
      Q 10.47 124.98 10.98 117.73
      C 14.34 70.72 43.96 31.62 88.02 15.05
      Z"
      />
      <path
        fill="#0a0a0a"
        d="
      M 128.39 41.77
      Q 128.01 43.03 128.01 45.75
      Q 127.98 72.12 128.02 98.50
      Q 128.02 99.26 128.45 99.23
      Q 128.30 99.65 127.81 99.44
      Q 124.82 98.11 120.20 95.34
      Q 112.34 90.62 102.01 84.60
      Q 101.06 84.04 101.64 83.10
      Q 114.33 62.64 127.33 41.50
      Q 127.59 41.08 127.84 41.13
      Q 128.14 41.19 128.39 41.77
      Z"
      />
      <path
        fill="#6e6f70"
        d="
      M 128.39 41.77
      Q 128.87 41.82 128.99 42.03
      Q 141.48 62.26 154.54 83.37
      Q 155.09 84.26 154.18 84.77
      L 128.45 99.23
      Q 128.02 99.26 128.02 98.50
      Q 127.98 72.12 128.01 45.75
      Q 128.01 43.03 128.39 41.77
      Z"
      />
      <path
        fill="#ffea00"
        d="
      M 219.75 52.18
      Q 238.60 75.87 243.79 105.50
      Q 245.08 112.88 245.05 120.37
      Q 245.05 120.81 245.24 121.21
      Q 245.36 121.47 245.37 121.75
      Q 245.46 130.82 245.41 140.84
      L 230.32 137.71
      Q 229.56 137.56 229.57 136.78
      Q 229.73 124.23 229.33 111.25
      Q 229.14 104.91 226.56 85.88
      C 226.07 82.26 224.42 76.57 223.63 69.86
      C 223.46 68.38 222.85 66.98 222.49 65.39
      Q 222.24 64.27 221.65 62.75
      Q 219.90 58.24 219.75 52.18
      Z"
      />
      <path
        fill="#0a0a0a"
        d="
      M 127.97 110.55
      C 128.04 117.07 127.33 124.60 128.44 130.97
      Q 128.40 131.33 128.25 131.54
      Q 127.92 131.98 127.60 131.53
      L 102.09 95.41
      Q 101.07 93.98 102.57 94.90
      L 127.97 110.55
      Z"
      />
      <path
        fill="#6e6f70"
        d="
      M 128.44 130.97
      C 127.33 124.60 128.04 117.07 127.97 110.55
      L 152.33 95.51
      Q 155.01 93.86 153.19 96.43
      L 129.03 130.69
      Q 128.91 130.85 128.71 130.89
      Q 128.60 130.91 128.44 130.97
      Z"
      />
      <path
        fill="#f0d50c"
        d="
      M 255.44 161.95
      Q 255.29 162.67 254.60 163.10
      C 249.29 166.44 244.43 167.66 237.00 169.26
      Q 224.93 171.86 204.49 173.68
      Q 173.27 176.47 141.88 177.00
      L 114.00 177.00
      Q 81.40 176.47 49.01 173.44
      Q 27.92 171.47 19.20 169.30
      Q 15.49 168.38 11.76 167.51
      C 9.42 166.97 7.23 165.90 4.87 165.16
      Q 2.80 164.51 0.44 162.13
      Q 1.13 162.11 1.59 161.64
      C 3.74 159.45 9.07 157.57 11.50 156.98
      Q 29.56 152.66 46.00 151.26
      C 48.85 151.02 51.13 151.91 54.06 152.51
      Q 54.55 152.61 54.93 152.96
      Q 55.29 153.29 55.76 153.44
      Q 57.15 153.87 59.46 155.58
      C 63.15 158.30 66.97 161.50 70.75 163.46
      C 78.85 167.67 87.02 168.97 96.12 168.99
      Q 129.81 169.04 163.49 168.94
      Q 174.20 168.91 184.46 163.84
      C 194.42 158.91 199.20 150.02 212.01 151.37
      Q 224.75 152.71 242.24 156.46
      Q 250.16 158.15 255.44 161.95
      Z"
      />
    </svg>
  );
}

export default Hardhat;
