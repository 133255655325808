function Typescript({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      version="1.2"
      width="40"
      baseProfile="tiny"
      viewBox="0.00 0.00 1024.00 1024.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#98bce3"
          vectorEffect="non-scaling-stroke"
          d="
  M 841.81 677.67
  C 818.92 667.19 795.59 658.29 773.65 645.80
  C 761.36 638.81 747.14 628.78 741.34 615.31
  C 735.18 601.01 738.84 584.63 750.49 574.21
  C 764.91 561.32 786.13 556.54 805.01 555.82
  Q 857.77 553.79 904.49 577.22
  C 909.49 579.73 914.72 583.16 919.71 586.32
  Q 920.26 586.67 920.26 586.01
  L 920.26 493.74
  Q 920.26 493.18 919.74 492.98
  C 882.64 479.14 843.29 475.69 803.99 476.05
  C 765.18 476.40 724.54 483.62 690.69 503.69
  C 665.52 518.62 645.13 542.49 637.08 570.78
  Q 631.99 588.65 631.84 608.26
  Q 631.46 659.82 666.19 694.94
  C 692.92 721.98 727.78 735.56 761.96 750.13
  C 785.99 760.36 829.34 779.05 833.19 809.25
  C 836.30 833.56 819.26 848.62 798.01 855.11
  C 779.73 860.69 759.86 861.52 740.51 859.60
  Q 680.80 853.67 634.35 815.29
  A 0.29 0.29 0.0 0 0 633.87 815.51
  L 633.87 914.50
  A 0.78 0.77 -77.3 0 0 634.31 915.20
  C 669.70 932.62 709.50 938.28 748.48 939.78
  Q 790.62 941.40 831.97 932.65
  Q 853.23 928.15 873.48 918.18
  C 901.62 904.34 925.97 878.89 934.50 848.26
  Q 942.90 818.08 938.73 786.02
  C 931.45 730.00 889.26 699.39 841.81 677.67"
        />
        <path
          stroke="#98bce3"
          vectorEffect="non-scaling-stroke"
          d="
  M 465.05 934.00
  A 0.48 0.48 0.0 0 0 465.53 933.52
  L 465.53 568.62
  A 0.48 0.48 0.0 0 1 466.01 568.14
  L 593.52 568.14
  A 0.48 0.48 0.0 0 0 594.00 567.66
  L 594.00 486.48
  A 0.48 0.48 0.0 0 0 593.52 486.00
  L 236.47 486.00
  A 0.48 0.48 0.0 0 0 235.99 486.48
  L 236.00 567.66
  A 0.48 0.48 0.0 0 0 236.48 568.14
  L 363.31 568.14
  A 0.48 0.48 0.0 0 1 363.79 568.62
  L 363.79 933.52
  A 0.48 0.48 0.0 0 0 364.27 934.00
  L 465.05 934.00"
        />
      </g>
      <path
        fill="#3178c6"
        d="
  M 91.88 0.00
  L 932.25 0.00
  Q 976.48 4.93 1002.88 38.40
  C 1013.40 51.73 1019.88 66.81 1022.61 83.53
  Q 1023.32 87.82 1024.00 92.12
  L 1024.00 932.00
  Q 1023.51 934.91 1023.13 937.82
  C 1017.51 980.86 984.04 1014.68 941.97 1022.35
  Q 936.93 1023.26 931.88 1024.00
  L 92.00 1024.00
  Q 87.12 1023.25 82.27 1022.41
  C 41.22 1015.35 8.70 982.74 1.56 941.76
  Q 0.71 936.83 0.00 931.88
  L 0.00 91.75
  Q 0.77 86.89 1.66 82.03
  C 9.89 36.72 46.31 4.72 91.88 0.00
  Z
  M 841.81 677.67
  C 818.92 667.19 795.59 658.29 773.65 645.80
  C 761.36 638.81 747.14 628.78 741.34 615.31
  C 735.18 601.01 738.84 584.63 750.49 574.21
  C 764.91 561.32 786.13 556.54 805.01 555.82
  Q 857.77 553.79 904.49 577.22
  C 909.49 579.73 914.72 583.16 919.71 586.32
  Q 920.26 586.67 920.26 586.01
  L 920.26 493.74
  Q 920.26 493.18 919.74 492.98
  C 882.64 479.14 843.29 475.69 803.99 476.05
  C 765.18 476.40 724.54 483.62 690.69 503.69
  C 665.52 518.62 645.13 542.49 637.08 570.78
  Q 631.99 588.65 631.84 608.26
  Q 631.46 659.82 666.19 694.94
  C 692.92 721.98 727.78 735.56 761.96 750.13
  C 785.99 760.36 829.34 779.05 833.19 809.25
  C 836.30 833.56 819.26 848.62 798.01 855.11
  C 779.73 860.69 759.86 861.52 740.51 859.60
  Q 680.80 853.67 634.35 815.29
  A 0.29 0.29 0.0 0 0 633.87 815.51
  L 633.87 914.50
  A 0.78 0.77 -77.3 0 0 634.31 915.20
  C 669.70 932.62 709.50 938.28 748.48 939.78
  Q 790.62 941.40 831.97 932.65
  Q 853.23 928.15 873.48 918.18
  C 901.62 904.34 925.97 878.89 934.50 848.26
  Q 942.90 818.08 938.73 786.02
  C 931.45 730.00 889.26 699.39 841.81 677.67
  Z
  M 465.05 934.00
  A 0.48 0.48 0.0 0 0 465.53 933.52
  L 465.53 568.62
  A 0.48 0.48 0.0 0 1 466.01 568.14
  L 593.52 568.14
  A 0.48 0.48 0.0 0 0 594.00 567.66
  L 594.00 486.48
  A 0.48 0.48 0.0 0 0 593.52 486.00
  L 236.47 486.00
  A 0.48 0.48 0.0 0 0 235.99 486.48
  L 236.00 567.66
  A 0.48 0.48 0.0 0 0 236.48 568.14
  L 363.31 568.14
  A 0.48 0.48 0.0 0 1 363.79 568.62
  L 363.79 933.52
  A 0.48 0.48 0.0 0 0 364.27 934.00
  L 465.05 934.00
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 841.81 677.67
  C 889.26 699.39 931.45 730.00 938.73 786.02
  Q 942.90 818.08 934.50 848.26
  C 925.97 878.89 901.62 904.34 873.48 918.18
  Q 853.23 928.15 831.97 932.65
  Q 790.62 941.40 748.48 939.78
  C 709.50 938.28 669.70 932.62 634.31 915.20
  A 0.78 0.77 -77.3 0 1 633.87 914.50
  L 633.87 815.51
  A 0.29 0.29 0.0 0 1 634.35 815.29
  Q 680.80 853.67 740.51 859.60
  C 759.86 861.52 779.73 860.69 798.01 855.11
  C 819.26 848.62 836.30 833.56 833.19 809.25
  C 829.34 779.05 785.99 760.36 761.96 750.13
  C 727.78 735.56 692.92 721.98 666.19 694.94
  Q 631.46 659.82 631.84 608.26
  Q 631.99 588.65 637.08 570.78
  C 645.13 542.49 665.52 518.62 690.69 503.69
  C 724.54 483.62 765.18 476.40 803.99 476.05
  C 843.29 475.69 882.64 479.14 919.74 492.98
  Q 920.26 493.18 920.26 493.74
  L 920.26 586.01
  Q 920.26 586.67 919.71 586.32
  C 914.72 583.16 909.49 579.73 904.49 577.22
  Q 857.77 553.79 805.01 555.82
  C 786.13 556.54 764.91 561.32 750.49 574.21
  C 738.84 584.63 735.18 601.01 741.34 615.31
  C 747.14 628.78 761.36 638.81 773.65 645.80
  C 795.59 658.29 818.92 667.19 841.81 677.67
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 465.05 934.00
  L 364.27 934.00
  A 0.48 0.48 0.0 0 1 363.79 933.52
  L 363.79 568.62
  A 0.48 0.48 0.0 0 0 363.31 568.14
  L 236.48 568.14
  A 0.48 0.48 0.0 0 1 236.00 567.66
  L 235.99 486.48
  A 0.48 0.48 0.0 0 1 236.47 486.00
  L 593.52 486.00
  A 0.48 0.48 0.0 0 1 594.00 486.48
  L 594.00 567.66
  A 0.48 0.48 0.0 0 1 593.52 568.14
  L 466.01 568.14
  A 0.48 0.48 0.0 0 0 465.53 568.62
  L 465.53 933.52
  A 0.48 0.48 0.0 0 1 465.05 934.00
  Z"
      />
    </svg>
  );
}

export default Typescript;
