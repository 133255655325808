function Nextjs({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1448.00 1448.00"
    >
      <path
        fill="#000000"
        d="
  M 473.70 1060.68
  Q 494.16 1060.94 514.60 1060.49
  Q 528.51 1060.17 536.11 1048.82
  C 539.43 1043.85 539.73 1039.03 540.03 1033.50
  Q 540.82 1018.45 540.96 969.00
  C 541.28 854.58 541.24 740.17 542.49 625.75
  Q 542.54 621.50 542.52 617.56
  A 0.42 0.42 0.0 0 1 543.29 617.32
  C 639.29 756.92 733.73 897.67 828.50 1038.12
  Q 834.10 1046.41 840.04 1054.43
  C 915.61 1156.48 990.75 1258.92 1067.23 1360.30
  A 1.21 1.19 -36.3 0 1 1066.96 1362.00
  Q 1065.14 1363.31 1063.32 1364.28
  C 980.60 1408.58 891.16 1434.97 796.94 1445.21
  Q 787.37 1446.25 777.00 1446.60
  Q 724.00 1448.36 670.99 1446.61
  Q 660.66 1446.27 651.05 1445.22
  Q 596.10 1439.19 546.18 1426.66
  C 375.16 1383.73 224.75 1277.36 125.33 1131.93
  C 56.82 1031.73 15.30 916.63 2.84 795.50
  Q 1.58 783.28 1.19 766.25
  Q 0.73 746.55 0.87 699.25
  C 1.01 653.17 7.36 609.37 17.16 565.28
  C 83.28 267.68 337.13 37.76 640.99 4.39
  C 653.60 3.01 673.86 0.81 690.14 0.75
  Q 737.10 0.56 784.01 2.32
  Q 794.33 2.71 803.93 3.88
  Q 845.36 8.92 880.77 16.75
  C 1073.71 59.41 1242.45 181.27 1344.35 350.18
  Q 1421.19 477.56 1441.96 627.00
  Q 1446.97 663.01 1447.22 693.80
  Q 1447.55 733.87 1446.99 763.75
  C 1446.76 775.90 1445.92 789.33 1444.47 802.04
  Q 1419.98 1016.11 1284.81 1182.48
  Q 1278.47 1190.28 1271.93 1197.79
  C 1226.88 1249.51 1174.42 1296.06 1116.65 1333.13
  A 0.72 0.71 -33.2 0 1 1115.66 1332.93
  Q 900.06 1006.52 684.63 679.89
  Q 615.54 575.14 545.85 470.78
  C 537.75 458.65 531.33 456.18 516.50 455.79
  Q 505.32 455.49 486.00 455.75
  C 479.90 455.83 474.23 455.80 468.95 458.39
  C 462.49 461.55 457.70 466.75 453.58 472.55
  A 1.58 1.57 -27.5 0 0 453.29 473.46
  Q 452.79 754.89 452.58 1036.32
  Q 452.58 1039.77 453.67 1042.48
  C 456.99 1050.69 461.91 1055.05 469.72 1059.60
  Q 471.54 1060.65 473.70 1060.68
  Z
  M 987.02 967.77
  Q 987.66 968.74 987.66 967.58
  C 987.89 806.37 988.87 644.84 987.81 483.50
  Q 987.73 471.40 987.27 459.25
  C 986.91 449.96 980.02 442.17 972.01 438.34
  C 967.10 436.00 961.87 436.02 956.25 435.93
  Q 937.75 435.62 924.61 436.10
  C 913.87 436.50 904.91 443.29 900.75 452.99
  C 898.54 458.15 898.84 465.26 898.76 470.75
  Q 898.28 502.75 898.17 546.89
  Q 897.83 688.81 897.96 831.01
  A 1.41 1.40 -61.8 0 0 898.19 831.78
  L 987.02 967.77
  Z"
      />
    </svg>
  );
}

export default Nextjs;
