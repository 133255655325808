function Reactjs({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1024.00 890.00"
    >
      <path
        fill="#61dafb"
        d="
  M 295.92 0.00
  L 330.02 0.00
  C 370.30 6.54 407.83 25.98 441.25 49.97
  Q 478.84 76.96 511.63 109.30
  Q 511.96 109.63 512.29 109.30
  Q 539.33 82.87 569.34 59.87
  C 606.01 31.77 647.97 7.17 694.12 0.00
  L 728.21 0.00
  C 781.72 8.82 808.75 52.79 816.94 102.51
  C 825.39 153.80 818.62 208.22 807.04 258.48
  Q 804.95 267.55 802.83 276.78
  A 0.54 0.53 14.6 0 0 803.21 277.42
  C 853.42 291.57 904.20 311.18 947.12 341.38
  C 989.51 371.22 1025.34 418.35 1007.92 473.40
  C 993.04 520.40 946.25 553.19 904.39 574.12
  C 871.99 590.32 838.04 602.65 803.22 612.56
  Q 802.70 612.70 802.82 613.23
  Q 805.05 622.74 807.24 632.26
  C 817.42 676.65 823.28 723.20 819.39 768.74
  C 814.58 825.00 789.43 879.95 727.87 890.00
  L 694.00 890.00
  Q 667.66 885.81 643.26 874.93
  C 594.07 852.98 550.80 818.11 512.41 780.81
  A 0.59 0.59 0.0 0 0 511.58 780.81
  Q 484.32 807.42 454.47 830.23
  C 417.77 858.27 376.17 882.73 329.88 890.00
  L 295.95 890.00
  C 201.05 873.57 197.43 760.30 206.93 687.51
  Q 211.84 649.95 221.25 613.25
  Q 221.40 612.65 220.80 612.48
  Q 184.44 602.38 149.40 587.82
  C 119.33 575.32 90.53 559.88 65.01 539.53
  C 27.22 509.40 0.17 464.97 16.45 415.24
  C 34.74 359.42 99.28 322.78 149.91 301.89
  Q 184.71 287.53 220.85 277.49
  A 0.59 0.58 75.1 0 0 221.26 276.77
  C 211.82 240.00 205.28 201.96 203.53 164.01
  C 200.50 98.28 216.74 12.78 295.92 0.00
  Z
  M 261.80 72.82
  C 248.04 98.52 245.62 133.24 247.05 162.50
  C 248.78 197.63 254.77 232.19 263.40 266.24
  Q 263.49 266.59 263.85 266.51
  C 307.71 256.75 351.79 250.34 396.52 245.86
  Q 397.04 245.81 397.34 245.38
  Q 436.07 190.39 481.50 140.81
  A 0.18 0.18 0.0 0 0 481.50 140.57
  Q 455.77 115.28 427.09 93.40
  C 389.05 64.38 297.23 6.61 261.80 72.82
  Z
  M 775.44 184.13
  C 778.35 156.12 778.29 126.67 771.95 99.12
  Q 768.07 82.25 759.37 68.15
  C 725.79 13.79 646.74 58.07 610.87 83.39
  Q 574.38 109.15 542.56 140.53
  Q 542.39 140.70 542.55 140.87
  Q 587.73 190.15 626.31 244.92
  Q 626.94 245.82 628.00 245.91
  Q 694.65 251.97 759.95 266.42
  Q 760.43 266.53 760.55 266.05
  Q 771.13 225.62 775.44 184.13
  Z
  M 568.87 242.02
  Q 569.38 242.04 569.71 241.95
  Q 570.18 241.82 569.89 241.43
  Q 542.76 205.49 512.36 172.28
  Q 512.00 171.89 511.64 172.28
  Q 481.28 205.48 454.15 241.37
  Q 453.73 241.92 454.42 241.89
  Q 511.56 239.41 568.87 242.02
  Z
  M 602.30 287.41
  C 542.40 282.62 481.51 282.98 421.68 287.43
  A 1.50 1.49 -74.7 0 0 420.55 288.08
  Q 369.67 362.98 330.21 444.49
  A 1.16 1.16 0.0 0 0 330.21 445.49
  Q 369.66 527.01 420.56 601.91
  A 1.54 1.53 -14.8 0 0 421.74 602.59
  Q 511.98 609.21 602.23 602.62
  A 1.57 1.56 -74.7 0 0 603.42 601.94
  Q 654.43 526.89 693.92 445.19
  A 0.49 0.49 0.0 0 0 693.92 444.79
  Q 654.43 363.11 603.44 288.07
  A 1.50 1.49 74.9 0 0 602.30 287.41
  Z
  M 661.88 293.33
  Q 660.47 293.15 659.14 293.05
  A 0.18 0.18 0.0 0 0 658.98 293.33
  Q 690.07 342.02 716.73 393.28
  Q 717.06 393.91 717.34 393.25
  Q 734.88 351.86 748.43 309.01
  Q 748.61 308.45 748.04 308.33
  Q 705.25 298.83 661.88 293.33
  Z
  M 364.51 293.12
  Q 319.85 298.65 275.92 308.35
  Q 275.38 308.47 275.55 309.00
  Q 289.11 351.87 306.66 393.27
  Q 306.93 393.91 307.25 393.29
  Q 333.84 342.19 364.83 293.63
  Q 365.21 293.04 364.51 293.12
  Z
  M 74.31 401.30
  C 17.29 464.82 100.94 517.97 149.07 540.15
  Q 189.79 558.91 233.03 570.78
  A 0.12 0.12 0.0 0 0 233.18 570.69
  Q 253.41 506.66 281.64 445.72
  A 1.76 1.74 45.4 0 0 281.64 444.25
  Q 253.40 383.32 233.18 319.27
  A 0.17 0.16 -14.5 0 0 232.98 319.16
  C 198.03 328.93 163.67 341.60 131.45 358.69
  C 110.38 369.87 90.13 383.68 74.31 401.30
  Z
  M 967.00 458.77
  C 981.01 408.25 913.24 367.51 876.69 350.57
  Q 835.25 331.35 791.02 319.20
  A 0.20 0.19 15.1 0 0 790.78 319.33
  Q 770.54 383.47 742.24 444.48
  Q 742.00 444.99 742.24 445.50
  Q 770.51 506.45 790.74 570.53
  A 0.38 0.37 -16.9 0 0 791.20 570.77
  C 823.69 561.72 855.22 550.11 885.33 535.07
  C 915.43 520.03 957.45 493.20 967.00 458.77
  Z
  M 275.83 581.61
  Q 319.91 591.37 364.73 596.88
  A 0.23 0.23 0.0 0 0 364.95 596.53
  Q 333.78 547.73 307.08 496.36
  A 0.15 0.15 0.0 0 0 306.81 496.37
  Q 289.16 537.98 275.51 581.07
  A 0.43 0.42 14.9 0 0 275.83 581.61
  Z
  M 659.45 596.88
  C 689.27 593.23 718.95 588.16 748.26 581.61
  A 0.34 0.33 76.3 0 0 748.51 581.19
  Q 734.86 538.04 717.18 496.37
  A 0.15 0.15 0.0 0 0 716.91 496.36
  Q 690.26 547.66 659.14 596.39
  Q 658.77 596.96 659.45 596.88
  Z
  M 247.29 721.50
  C 244.67 762.09 248.25 839.36 302.55 847.02
  C 334.36 851.50 372.07 833.12 397.96 816.67
  C 428.19 797.45 455.83 774.56 481.40 749.52
  Q 481.64 749.28 481.41 749.03
  C 451.01 715.94 423.42 681.01 397.20 644.53
  Q 396.88 644.07 396.32 644.02
  Q 329.39 637.99 263.80 623.48
  Q 263.49 623.41 263.41 623.71
  C 255.27 655.76 249.43 688.48 247.29 721.50
  Z
  M 760.16 623.52
  C 716.44 633.23 672.23 639.85 627.69 644.11
  A 1.64 1.62 14.7 0 0 626.51 644.79
  Q 587.89 699.62 542.59 749.07
  A 0.33 0.33 0.0 0 0 542.60 749.53
  C 566.75 773.19 592.89 794.97 621.19 813.55
  C 657.56 837.42 730.32 873.39 760.66 819.67
  C 773.69 796.60 777.18 768.83 777.07 742.75
  C 776.91 702.38 770.64 662.76 760.54 623.75
  A 0.32 0.32 0.0 0 0 760.16 623.52
  Z
  M 454.66 648.06
  Q 454.38 648.00 454.12 648.13
  A 0.22 0.22 0.0 0 0 454.04 648.46
  Q 481.14 684.34 511.49 717.52
  A 0.70 0.70 0.0 0 0 512.52 717.52
  Q 542.86 684.35 569.94 648.48
  A 0.25 0.25 0.0 0 0 569.73 648.08
  Q 512.38 650.57 455.10 648.14
  Q 455.02 648.13 454.66 648.06
  Z"
      />
      <circle fill="#61dafb" cx="512.00" cy="444.99" r="89.15" />
    </svg>
  );
}

export default Reactjs;
