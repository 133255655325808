function Chainlink({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1448.00 1448.00"
    >
      <path
        fill="#2a5ada"
        d="
  M 96.67 1086.47
  Q 96.02 1086.10 96.02 1085.35
  L 96.02 362.59
  Q 96.02 362.22 96.34 362.04
  L 722.94 0.29
  A 1.53 1.53 0.0 0 1 724.48 0.29
  L 1350.95 361.96
  Q 1351.29 362.16 1351.29 362.56
  L 1351.29 1085.50
  Q 1351.29 1086.01 1350.85 1086.27
  C 1303.86 1113.30 1256.72 1140.07 1209.93 1167.37
  Q 1037.70 1267.85 865.47 1368.31
  C 819.71 1395.01 773.64 1421.16 727.80 1447.54
  Q 727.16 1447.91 726.51 1447.54
  L 96.67 1086.47
  Z
  M 723.41 1142.31
  A 0.60 0.60 0.0 0 0 724.01 1142.31
  L 1085.42 933.41
  A 0.60 0.60 0.0 0 0 1085.72 932.89
  L 1085.72 515.11
  A 0.60 0.60 0.0 0 0 1085.42 514.59
  L 724.01 305.69
  A 0.60 0.60 0.0 0 0 723.41 305.69
  L 362.01 514.59
  A 0.60 0.60 0.0 0 0 361.71 515.11
  L 361.71 932.89
  A 0.60 0.60 0.0 0 0 362.01 933.41
  L 723.41 1142.31
  Z"
      />
    </svg>
  );
}

export default Chainlink;
