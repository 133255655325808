function Tokeny() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 709.00 162.00"
    >
      <path
        fill="#6d23fa"
        d="
  M 31.69 2.63
  L 60.78 62.30
  A 1.96 1.96 0.0 0 1 60.78 64.02
  L 31.67 123.78
  A 1.96 1.96 0.0 0 1 28.13 123.74
  L 0.65 63.89
  A 1.96 1.96 0.0 0 1 0.65 62.25
  L 28.15 2.67
  A 1.96 1.96 0.0 0 1 31.69 2.63
  Z"
      />
      <path
        fill="#6d23fa"
        d="
  M 93.61 64.55
  L 64.89 123.45
  A 2.14 1.94 82.8 0 1 64.54 123.95
  C 63.22 125.34 61.79 125.01 61.05 123.41
  Q 55.21 110.85 49.59 98.50
  A 1.65 1.63 -44.6 0 1 49.61 97.10
  L 65.19 64.53
  A 3.93 3.90 -45.5 0 0 65.17 61.13
  L 49.67 29.49
  A 2.05 2.04 44.2 0 1 49.64 27.72
  L 61.26 2.46
  A 1.88 1.87 44.4 0 1 64.65 2.43
  L 93.61 61.73
  A 3.21 3.21 0.0 0 1 93.61 64.55
  Z"
      />
      <path
        fill="#6d23fa"
        d="
  M 81.70 99.98
  L 98.97 63.59
  A 2.00 2.00 0.0 0 0 98.96 61.86
  L 81.46 25.95
  A 2.00 2.00 0.0 0 1 81.45 24.20
  L 91.91 2.66
  A 2.00 2.00 0.0 0 1 95.50 2.64
  L 125.04 62.26
  A 2.00 2.00 0.0 0 1 125.05 64.02
  L 95.93 123.66
  A 2.00 2.00 0.0 0 1 92.34 123.66
  L 81.71 101.71
  A 2.00 2.00 0.0 0 1 81.70 99.98
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 348.41 120.98
  L 317.85 121.07
  A 0.31 0.31 0.0 0 1 317.54 120.76
  L 317.53 1.95
  A 0.31 0.31 0.0 0 1 317.84 1.64
  L 348.44 1.61
  A 0.31 0.31 0.0 0 1 348.75 1.92
  L 348.76 70.79
  A 0.31 0.31 0.0 0 0 349.31 70.98
  L 379.39 33.62
  A 0.31 0.31 0.0 0 1 379.63 33.50
  L 415.91 33.51
  A 0.31 0.31 0.0 0 1 416.14 34.02
  L 382.57 74.08
  A 0.31 0.31 0.0 0 0 382.57 74.48
  L 421.35 120.54
  A 0.31 0.31 0.0 0 1 421.11 121.05
  L 382.43 120.83
  A 0.31 0.31 0.0 0 1 382.19 120.72
  L 349.26 80.17
  A 0.31 0.31 0.0 0 0 348.71 80.36
  L 348.72 120.67
  A 0.31 0.31 0.0 0 1 348.41 120.98
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 177.00 55.41
  Q 176.82 56.84 176.82 58.50
  Q 176.88 71.25 176.90 84.00
  C 176.91 90.16 177.80 96.07 185.01 97.29
  C 192.93 98.63 201.02 95.71 207.93 91.95
  A 0.66 0.65 -14.4 0 1 208.90 92.52
  L 208.87 115.01
  A 0.95 0.95 0.0 0 1 208.30 115.88
  C 185.29 125.81 146.67 128.17 146.19 91.72
  Q 145.95 73.56 146.08 55.55
  A 0.80 0.80 0.0 0 0 145.28 54.75
  L 132.98 54.74
  Q 132.36 54.74 132.26 54.14
  C 131.83 51.53 131.82 49.60 133.83 47.56
  Q 153.40 27.63 173.01 7.77
  Q 174.45 6.31 176.63 7.44
  Q 176.99 7.62 176.99 8.03
  L 176.96 32.97
  A 0.51 0.50 0.0 0 0 177.47 33.47
  L 205.84 33.48
  A 0.61 0.61 0.0 0 1 206.45 34.09
  L 206.50 54.06
  A 0.70 0.69 -90.0 0 1 205.81 54.76
  L 177.74 54.76
  A 0.75 0.75 0.0 0 0 177.00 55.41
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 443.79 84.50
  A 0.39 0.39 0.0 0 0 443.41 85.00
  C 445.78 93.41 452.48 99.21 460.60 101.48
  C 473.16 105.01 490.44 99.49 501.92 94.18
  Q 502.77 93.79 502.77 94.73
  L 502.75 111.99
  A 1.57 1.57 0.0 0 1 501.87 113.40
  C 486.75 120.59 471.96 122.97 455.25 121.99
  C 435.33 120.82 418.07 109.38 413.14 89.37
  C 407.82 67.75 415.57 45.50 437.09 36.52
  C 453.60 29.62 476.04 29.39 490.42 41.76
  C 503.16 52.72 505.30 67.40 504.97 83.58
  Q 504.95 84.50 504.03 84.50
  L 443.79 84.50
  Z
  M 443.02 69.16
  L 475.80 69.10
  A 0.30 0.30 0.0 0 0 476.10 68.80
  L 476.09 67.40
  A 16.70 15.66 89.9 0 0 460.40 50.73
  L 458.34 50.73
  A 16.70 15.66 89.9 0 0 442.71 67.46
  L 442.72 68.86
  A 0.30 0.30 0.0 0 0 443.02 69.16
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 543.41 44.78
  Q 544.12 44.18 545.25 43.02
  C 554.88 33.15 568.15 30.13 581.49 32.39
  C 600.48 35.60 604.92 53.51 604.91 70.00
  Q 604.89 95.10 604.87 119.86
  Q 604.87 120.52 604.21 120.52
  L 574.31 120.36
  Q 573.90 120.36 573.90 119.94
  Q 573.87 95.58 573.74 71.00
  Q 573.72 65.93 571.78 61.25
  C 568.36 52.97 555.94 54.40 550.73 59.20
  Q 545.72 63.82 542.81 68.52
  C 542.01 69.79 542.19 71.41 542.20 72.95
  Q 542.25 96.48 542.23 120.02
  Q 542.23 120.51 541.73 120.51
  L 512.21 120.51
  Q 511.65 120.51 511.65 119.96
  L 511.65 33.77
  A 0.33 0.32 0.0 0 1 511.98 33.45
  L 541.79 33.48
  Q 542.36 33.48 542.36 34.04
  L 542.35 44.30
  Q 542.35 45.68 543.41 44.78
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 309.85 77.00
  C 310.06 97.87 299.15 114.64 278.60 120.27
  C 245.28 129.39 207.49 114.95 209.60 74.50
  C 210.94 48.86 229.83 33.94 254.25 32.61
  C 283.89 31.00 309.51 43.75 309.85 77.00
  Z
  M 244.05 65.79
  C 240.05 77.68 241.60 99.45 259.01 99.78
  C 276.95 100.13 280.07 80.11 276.06 67.24
  C 272.73 56.53 261.88 52.58 252.19 56.72
  Q 246.25 59.25 244.05 65.79
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 658.45 76.02
  L 678.18 33.50
  A 0.42 0.42 0.0 0 1 678.56 33.26
  L 707.62 33.25
  A 0.42 0.42 0.0 0 1 708.00 33.85
  L 648.85 160.39
  A 0.42 0.42 0.0 0 1 648.47 160.63
  L 618.35 160.63
  A 0.42 0.42 0.0 0 1 617.97 160.03
  L 641.63 109.46
  A 0.42 0.42 0.0 0 0 641.63 109.10
  L 607.35 33.96
  A 0.42 0.42 0.0 0 1 607.73 33.37
  L 639.92 33.35
  A 0.42 0.42 0.0 0 1 640.31 33.61
  L 657.69 76.00
  A 0.42 0.42 0.0 0 0 658.45 76.02
  Z"
      />
    </svg>
  );
}

export default Tokeny;
