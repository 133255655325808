function Nodejs({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 360.00 360.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 215.23 142.50
  L 182.54 161.36
  A 1.62 1.61 45.6 0 1 180.89 161.34
  L 172.91 156.46
  A 0.92 0.91 -41.5 0 0 172.04 156.41
  L 166.93 158.77
  A 0.64 0.64 0.0 0 0 166.88 159.91
  Q 172.27 162.98 177.71 166.33
  Q 181.54 168.69 185.00 166.70
  Q 200.59 157.72 216.18 148.75
  C 220.44 146.30 221.65 145.12 221.68 140.25
  Q 221.78 124.15 221.66 108.06
  C 221.63 103.23 221.98 99.79 218.18 97.60
  Q 202.36 88.49 186.55 79.38
  C 183.63 77.69 181.08 76.99 178.22 78.66
  Q 162.34 87.93 146.38 97.07
  C 143.16 98.91 141.90 100.62 141.89 104.25
  Q 141.88 122.94 142.02 141.63
  Q 142.05 145.71 145.71 147.81
  Q 152.98 151.97 155.01 153.00
  C 162.39 156.76 171.34 154.19 171.33 144.64
  Q 171.33 125.76 171.42 106.99
  Q 171.43 105.80 170.23 105.81
  L 166.48 105.84
  Q 165.29 105.85 165.29 107.04
  Q 165.27 125.31 165.26 143.54
  C 165.26 154.36 151.64 144.53 148.42 142.46
  A 1.42 1.41 16.5 0 1 147.77 141.27
  L 147.79 104.22
  A 2.18 2.18 0.0 0 1 148.88 102.33
  L 181.19 83.73
  A 1.02 1.01 -44.5 0 1 182.20 83.73
  L 214.81 102.43
  A 1.81 1.81 0.0 0 1 215.72 104.00
  L 215.72 141.66
  A 1.00 0.99 75.5 0 1 215.23 142.50"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 234.75 80.22
  A 0.15 0.15 0.0 0 1 234.85 80.08
  L 237.28 79.22
  A 0.15 0.15 0.0 0 0 237.37 79.05
  L 237.32 78.80
  A 0.15 0.15 0.0 0 0 237.18 78.68
  L 230.97 78.54
  A 0.15 0.15 0.0 0 0 230.81 78.69
  L 230.79 79.26
  A 0.15 0.15 0.0 0 0 230.90 79.41
  L 233.36 79.98
  A 0.15 0.15 0.0 0 1 233.48 80.13
  L 233.53 86.79
  A 0.15 0.15 0.0 0 0 233.67 86.94
  L 234.53 86.98
  A 0.15 0.15 0.0 0 0 234.69 86.83
  L 234.75 80.22"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 246.14 86.31
  L 246.01 79.11
  Q 246.00 78.61 245.50 78.60
  Q 244.87 78.58 244.59 79.31
  Q 244.15 80.45 244.11 82.12
  Q 244.09 82.59 244.36 82.98
  Q 245.33 84.44 245.23 86.25
  Q 245.21 86.60 245.57 86.57
  L 245.94 86.53
  Q 246.15 86.51 246.14 86.31"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 241.05 84.08
  Q 241.38 84.53 241.39 85.09
  Q 241.40 85.83 241.79 86.40
  A 0.84 0.84 0.0 0 0 243.31 86.02
  L 243.61 83.51
  Q 243.65 83.19 243.49 83.48
  L 243.36 83.73
  Q 243.25 83.94 243.01 83.97
  C 240.60 84.29 240.61 81.08 240.31 79.53
  A 0.94 0.94 0.0 0 0 239.16 78.80
  L 238.70 78.91
  Q 237.97 79.10 238.33 79.76
  C 239.25 81.44 238.08 84.38 238.31 86.42
  Q 238.35 86.70 238.63 86.68
  L 239.09 86.65
  A 0.39 0.39 0.0 0 0 239.45 86.27
  L 239.57 82.82
  A 0.27 0.26 -62.2 0 1 240.05 82.67
  L 241.05 84.08"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 182.25 115.93
  C 180.58 109.24 198.91 108.11 200.83 116.07
  A 1.76 1.76 0.0 0 0 202.54 117.41
  L 205.98 117.39
  A 1.16 1.16 0.0 0 0 207.13 116.12
  Q 206.34 108.22 199.02 105.95
  C 191.50 103.62 175.30 104.01 175.65 116.03
  C 175.92 125.45 188.85 125.53 195.51 126.21
  C 198.40 126.50 204.32 128.57 201.21 132.82
  C 198.57 136.44 190.03 135.72 186.39 135.17
  C 182.21 134.54 181.63 131.83 180.30 128.76
  A 0.88 0.87 78.1 0 0 179.50 128.23
  L 175.92 128.22
  A 1.62 1.62 0.0 0 0 174.32 130.14
  Q 175.71 137.47 181.78 139.80
  C 188.24 142.29 200.58 142.26 205.68 137.09
  C 208.98 133.73 210.04 125.80 204.90 122.84
  C 199.50 119.73 191.53 119.57 185.36 118.45
  Q 182.76 117.98 182.25 115.93"
        />
        <path
          stroke="#6b873e"
          vectorEffect="non-scaling-stroke"
          d="
  M 220.62 213.58
  C 218.71 212.78 214.28 209.22 212.29 210.33
  Q 201.48 216.36 190.58 222.73
  A 2.66 2.66 0.0 0 0 189.27 225.02
  L 189.25 249.98
  A 2.76 2.74 -75.2 0 0 190.63 252.37
  L 212.05 264.68
  A 2.94 2.93 44.7 0 0 214.98 264.67
  L 235.97 252.60
  Q 237.73 251.59 237.73 249.56
  L 237.73 187.99
  A 2.69 2.69 0.0 0 0 236.35 185.64
  L 223.55 178.50
  A 1.38 1.38 0.0 0 0 221.50 179.71
  L 221.51 213.00
  A 0.64 0.63 11.7 0 1 220.62 213.58"
        />
        <path
          stroke="#6b873e"
          vectorEffect="non-scaling-stroke"
          d="
  M 84.87 228.09
  L 91.14 231.78
  Q 92.10 232.34 92.11 233.46
  L 92.19 249.34
  A 2.78 2.76 -75.4 0 0 93.58 251.73
  Q 99.79 255.29 106.10 258.90
  Q 107.60 259.76 108.33 258.12
  A 1.50 1.48 -32.7 0 0 108.47 257.49
  L 108.50 224.48
  A 2.72 2.72 0.0 0 0 107.13 222.10
  L 85.53 209.68
  A 2.81 2.80 44.8 0 0 82.74 209.68
  L 61.49 221.91
  A 2.98 2.96 -15.1 0 0 60.00 224.48
  L 59.98 257.92
  A 1.33 1.32 -14.8 0 0 61.96 259.07
  L 74.87 251.68
  A 2.73 2.71 -15.1 0 0 76.25 249.30
  Q 76.21 241.78 76.28 234.22
  C 76.30 231.20 81.79 228.97 83.75 228.04
  A 1.21 1.20 47.2 0 1 84.87 228.09"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 150.20 209.87
  A 2.57 2.57 0.0 0 0 147.62 209.87
  L 125.99 222.36
  A 2.57 2.57 0.0 0 0 124.71 224.58
  L 124.69 249.53
  A 2.57 2.57 0.0 0 0 125.98 251.76
  L 147.61 264.24
  A 2.57 2.57 0.0 0 0 150.17 264.24
  L 171.72 251.82
  A 2.57 2.57 0.0 0 0 173.01 249.59
  L 173.00 224.56
  A 2.57 2.57 0.0 0 0 171.72 222.34
  L 150.20 209.87"
        />
        <path
          stroke="#6b873e"
          vectorEffect="non-scaling-stroke"
          d="
  M 285.70 233.16
  L 285.75 239.34
  A 1.43 1.43 0.0 0 0 287.91 240.56
  L 300.64 233.08
  A 2.79 2.77 -15.4 0 0 302.01 230.69
  L 302.02 224.58
  A 2.41 2.39 -75.1 0 0 300.83 222.50
  L 279.00 209.85
  A 2.28 2.28 0.0 0 0 276.72 209.85
  L 255.03 222.37
  A 2.63 2.62 -15.1 0 0 253.72 224.64
  L 253.72 249.51
  Q 253.73 251.10 255.10 251.90
  L 276.51 264.24
  A 2.57 2.56 45.1 0 0 279.05 264.25
  Q 285.40 260.64 292.05 256.75
  Q 292.98 256.21 292.71 255.30
  A 1.15 1.14 6.7 0 0 292.17 254.62
  L 270.48 242.29
  A 1.49 1.48 15.2 0 1 269.74 241.01
  L 269.72 233.13
  A 0.95 0.94 75.3 0 1 270.19 232.31
  L 277.14 228.24
  A 1.23 1.21 -44.6 0 1 278.37 228.24
  L 285.14 232.20
  Q 285.69 232.52 285.70 233.16"
        />
        <path
          stroke="#c8e29f"
          vectorEffect="non-scaling-stroke"
          d="
  M 273.25 239.13
  A 1.08 1.08 0.0 0 0 273.79 240.07
  L 277.39 242.14
  A 1.08 1.08 0.0 0 0 278.47 242.13
  L 281.96 240.09
  A 1.08 1.08 0.0 0 0 282.49 239.17
  L 282.53 234.97
  A 1.08 1.08 0.0 0 0 282.00 234.03
  L 278.49 231.95
  A 1.08 1.08 0.0 0 0 277.39 231.95
  L 273.79 234.06
  A 1.08 1.08 0.0 0 0 273.26 234.99
  L 273.25 239.13"
        />
        <path
          stroke="#6b873e"
          vectorEffect="non-scaling-stroke"
          d="
  M 205.50 241.17
  A 1.71 1.71 0.0 0 0 206.35 242.64
  L 212.59 246.28
  A 1.71 1.71 0.0 0 0 214.31 246.28
  L 220.64 242.61
  A 1.71 1.71 0.0 0 0 221.49 241.13
  L 221.49 233.89
  A 1.71 1.71 0.0 0 0 220.63 232.41
  L 214.24 228.73
  A 1.71 1.71 0.0 0 0 212.51 228.74
  L 206.30 232.43
  A 1.71 1.71 0.0 0 0 205.46 233.91
  L 205.50 241.17"
        />
      </g>
      <path
        fill="#90c53f"
        d="
  M 181.06 4.41
  L 337.04 91.25
  A 0.60 0.60 0.0 0 1 337.35 91.77
  L 337.57 265.83
  A 0.60 0.60 0.0 0 1 337.26 266.35
  L 181.52 353.60
  A 0.60 0.60 0.0 0 1 180.94 353.60
  L 24.95 266.75
  A 0.60 0.60 0.0 0 1 24.64 266.23
  L 24.43 92.17
  A 0.60 0.60 0.0 0 1 24.74 91.65
  L 180.48 4.41
  A 0.60 0.60 0.0 0 1 181.06 4.41
  Z
  M 215.23 142.50
  L 182.54 161.36
  A 1.62 1.61 45.6 0 1 180.89 161.34
  L 172.91 156.46
  A 0.92 0.91 -41.5 0 0 172.04 156.41
  L 166.93 158.77
  A 0.64 0.64 0.0 0 0 166.88 159.91
  Q 172.27 162.98 177.71 166.33
  Q 181.54 168.69 185.00 166.70
  Q 200.59 157.72 216.18 148.75
  C 220.44 146.30 221.65 145.12 221.68 140.25
  Q 221.78 124.15 221.66 108.06
  C 221.63 103.23 221.98 99.79 218.18 97.60
  Q 202.36 88.49 186.55 79.38
  C 183.63 77.69 181.08 76.99 178.22 78.66
  Q 162.34 87.93 146.38 97.07
  C 143.16 98.91 141.90 100.62 141.89 104.25
  Q 141.88 122.94 142.02 141.63
  Q 142.05 145.71 145.71 147.81
  Q 152.98 151.97 155.01 153.00
  C 162.39 156.76 171.34 154.19 171.33 144.64
  Q 171.33 125.76 171.42 106.99
  Q 171.43 105.80 170.23 105.81
  L 166.48 105.84
  Q 165.29 105.85 165.29 107.04
  Q 165.27 125.31 165.26 143.54
  C 165.26 154.36 151.64 144.53 148.42 142.46
  A 1.42 1.41 16.5 0 1 147.77 141.27
  L 147.79 104.22
  A 2.18 2.18 0.0 0 1 148.88 102.33
  L 181.19 83.73
  A 1.02 1.01 -44.5 0 1 182.20 83.73
  L 214.81 102.43
  A 1.81 1.81 0.0 0 1 215.72 104.00
  L 215.72 141.66
  A 1.00 0.99 75.5 0 1 215.23 142.50
  Z
  M 234.75 80.22
  A 0.15 0.15 0.0 0 1 234.85 80.08
  L 237.28 79.22
  A 0.15 0.15 0.0 0 0 237.37 79.05
  L 237.32 78.80
  A 0.15 0.15 0.0 0 0 237.18 78.68
  L 230.97 78.54
  A 0.15 0.15 0.0 0 0 230.81 78.69
  L 230.79 79.26
  A 0.15 0.15 0.0 0 0 230.90 79.41
  L 233.36 79.98
  A 0.15 0.15 0.0 0 1 233.48 80.13
  L 233.53 86.79
  A 0.15 0.15 0.0 0 0 233.67 86.94
  L 234.53 86.98
  A 0.15 0.15 0.0 0 0 234.69 86.83
  L 234.75 80.22
  Z
  M 246.14 86.31
  L 246.01 79.11
  Q 246.00 78.61 245.50 78.60
  Q 244.87 78.58 244.59 79.31
  Q 244.15 80.45 244.11 82.12
  Q 244.09 82.59 244.36 82.98
  Q 245.33 84.44 245.23 86.25
  Q 245.21 86.60 245.57 86.57
  L 245.94 86.53
  Q 246.15 86.51 246.14 86.31
  Z
  M 241.05 84.08
  Q 241.38 84.53 241.39 85.09
  Q 241.40 85.83 241.79 86.40
  A 0.84 0.84 0.0 0 0 243.31 86.02
  L 243.61 83.51
  Q 243.65 83.19 243.49 83.48
  L 243.36 83.73
  Q 243.25 83.94 243.01 83.97
  C 240.60 84.29 240.61 81.08 240.31 79.53
  A 0.94 0.94 0.0 0 0 239.16 78.80
  L 238.70 78.91
  Q 237.97 79.10 238.33 79.76
  C 239.25 81.44 238.08 84.38 238.31 86.42
  Q 238.35 86.70 238.63 86.68
  L 239.09 86.65
  A 0.39 0.39 0.0 0 0 239.45 86.27
  L 239.57 82.82
  A 0.27 0.26 -62.2 0 1 240.05 82.67
  L 241.05 84.08
  Z
  M 182.25 115.93
  C 180.58 109.24 198.91 108.11 200.83 116.07
  A 1.76 1.76 0.0 0 0 202.54 117.41
  L 205.98 117.39
  A 1.16 1.16 0.0 0 0 207.13 116.12
  Q 206.34 108.22 199.02 105.95
  C 191.50 103.62 175.30 104.01 175.65 116.03
  C 175.92 125.45 188.85 125.53 195.51 126.21
  C 198.40 126.50 204.32 128.57 201.21 132.82
  C 198.57 136.44 190.03 135.72 186.39 135.17
  C 182.21 134.54 181.63 131.83 180.30 128.76
  A 0.88 0.87 78.1 0 0 179.50 128.23
  L 175.92 128.22
  A 1.62 1.62 0.0 0 0 174.32 130.14
  Q 175.71 137.47 181.78 139.80
  C 188.24 142.29 200.58 142.26 205.68 137.09
  C 208.98 133.73 210.04 125.80 204.90 122.84
  C 199.50 119.73 191.53 119.57 185.36 118.45
  Q 182.76 117.98 182.25 115.93
  Z
  M 220.62 213.58
  C 218.71 212.78 214.28 209.22 212.29 210.33
  Q 201.48 216.36 190.58 222.73
  A 2.66 2.66 0.0 0 0 189.27 225.02
  L 189.25 249.98
  A 2.76 2.74 -75.2 0 0 190.63 252.37
  L 212.05 264.68
  A 2.94 2.93 44.7 0 0 214.98 264.67
  L 235.97 252.60
  Q 237.73 251.59 237.73 249.56
  L 237.73 187.99
  A 2.69 2.69 0.0 0 0 236.35 185.64
  L 223.55 178.50
  A 1.38 1.38 0.0 0 0 221.50 179.71
  L 221.51 213.00
  A 0.64 0.63 11.7 0 1 220.62 213.58
  Z
  M 84.87 228.09
  L 91.14 231.78
  Q 92.10 232.34 92.11 233.46
  L 92.19 249.34
  A 2.78 2.76 -75.4 0 0 93.58 251.73
  Q 99.79 255.29 106.10 258.90
  Q 107.60 259.76 108.33 258.12
  A 1.50 1.48 -32.7 0 0 108.47 257.49
  L 108.50 224.48
  A 2.72 2.72 0.0 0 0 107.13 222.10
  L 85.53 209.68
  A 2.81 2.80 44.8 0 0 82.74 209.68
  L 61.49 221.91
  A 2.98 2.96 -15.1 0 0 60.00 224.48
  L 59.98 257.92
  A 1.33 1.32 -14.8 0 0 61.96 259.07
  L 74.87 251.68
  A 2.73 2.71 -15.1 0 0 76.25 249.30
  Q 76.21 241.78 76.28 234.22
  C 76.30 231.20 81.79 228.97 83.75 228.04
  A 1.21 1.20 47.2 0 1 84.87 228.09
  Z
  M 150.20 209.87
  A 2.57 2.57 0.0 0 0 147.62 209.87
  L 125.99 222.36
  A 2.57 2.57 0.0 0 0 124.71 224.58
  L 124.69 249.53
  A 2.57 2.57 0.0 0 0 125.98 251.76
  L 147.61 264.24
  A 2.57 2.57 0.0 0 0 150.17 264.24
  L 171.72 251.82
  A 2.57 2.57 0.0 0 0 173.01 249.59
  L 173.00 224.56
  A 2.57 2.57 0.0 0 0 171.72 222.34
  L 150.20 209.87
  Z
  M 285.70 233.16
  L 285.75 239.34
  A 1.43 1.43 0.0 0 0 287.91 240.56
  L 300.64 233.08
  A 2.79 2.77 -15.4 0 0 302.01 230.69
  L 302.02 224.58
  A 2.41 2.39 -75.1 0 0 300.83 222.50
  L 279.00 209.85
  A 2.28 2.28 0.0 0 0 276.72 209.85
  L 255.03 222.37
  A 2.63 2.62 -15.1 0 0 253.72 224.64
  L 253.72 249.51
  Q 253.73 251.10 255.10 251.90
  L 276.51 264.24
  A 2.57 2.56 45.1 0 0 279.05 264.25
  Q 285.40 260.64 292.05 256.75
  Q 292.98 256.21 292.71 255.30
  A 1.15 1.14 6.7 0 0 292.17 254.62
  L 270.48 242.29
  A 1.49 1.48 15.2 0 1 269.74 241.01
  L 269.72 233.13
  A 0.95 0.94 75.3 0 1 270.19 232.31
  L 277.14 228.24
  A 1.23 1.21 -44.6 0 1 278.37 228.24
  L 285.14 232.20
  Q 285.69 232.52 285.70 233.16
  Z
  M 273.25 239.13
  A 1.08 1.08 0.0 0 0 273.79 240.07
  L 277.39 242.14
  A 1.08 1.08 0.0 0 0 278.47 242.13
  L 281.96 240.09
  A 1.08 1.08 0.0 0 0 282.49 239.17
  L 282.53 234.97
  A 1.08 1.08 0.0 0 0 282.00 234.03
  L 278.49 231.95
  A 1.08 1.08 0.0 0 0 277.39 231.95
  L 273.79 234.06
  A 1.08 1.08 0.0 0 0 273.26 234.99
  L 273.25 239.13
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 215.23 142.50
  A 1.00 0.99 75.5 0 0 215.72 141.66
  L 215.72 104.00
  A 1.81 1.81 0.0 0 0 214.81 102.43
  L 182.20 83.73
  A 1.02 1.01 -44.5 0 0 181.19 83.73
  L 148.88 102.33
  A 2.18 2.18 0.0 0 0 147.79 104.22
  L 147.77 141.27
  A 1.42 1.41 16.5 0 0 148.42 142.46
  C 151.64 144.53 165.26 154.36 165.26 143.54
  Q 165.27 125.31 165.29 107.04
  Q 165.29 105.85 166.48 105.84
  L 170.23 105.81
  Q 171.43 105.80 171.42 106.99
  Q 171.33 125.76 171.33 144.64
  C 171.34 154.19 162.39 156.76 155.01 153.00
  Q 152.98 151.97 145.71 147.81
  Q 142.05 145.71 142.02 141.63
  Q 141.88 122.94 141.89 104.25
  C 141.90 100.62 143.16 98.91 146.38 97.07
  Q 162.34 87.93 178.22 78.66
  C 181.08 76.99 183.63 77.69 186.55 79.38
  Q 202.36 88.49 218.18 97.60
  C 221.98 99.79 221.63 103.23 221.66 108.06
  Q 221.78 124.15 221.68 140.25
  C 221.65 145.12 220.44 146.30 216.18 148.75
  Q 200.59 157.72 185.00 166.70
  Q 181.54 168.69 177.71 166.33
  Q 172.27 162.98 166.88 159.91
  A 0.64 0.64 0.0 0 1 166.93 158.77
  L 172.04 156.41
  A 0.92 0.91 -41.5 0 1 172.91 156.46
  L 180.89 161.34
  A 1.62 1.61 45.6 0 0 182.54 161.36
  L 215.23 142.50
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 234.75 80.22
  L 234.69 86.83
  A 0.15 0.15 0.0 0 1 234.53 86.98
  L 233.67 86.94
  A 0.15 0.15 0.0 0 1 233.53 86.79
  L 233.48 80.13
  A 0.15 0.15 0.0 0 0 233.36 79.98
  L 230.90 79.41
  A 0.15 0.15 0.0 0 1 230.79 79.26
  L 230.81 78.69
  A 0.15 0.15 0.0 0 1 230.97 78.54
  L 237.18 78.68
  A 0.15 0.15 0.0 0 1 237.32 78.80
  L 237.37 79.05
  A 0.15 0.15 0.0 0 1 237.28 79.22
  L 234.85 80.08
  A 0.15 0.15 0.0 0 0 234.75 80.22
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 246.01 79.11
  L 246.14 86.31
  Q 246.15 86.51 245.94 86.53
  L 245.57 86.57
  Q 245.21 86.60 245.23 86.25
  Q 245.33 84.44 244.36 82.98
  Q 244.09 82.59 244.11 82.12
  Q 244.15 80.45 244.59 79.31
  Q 244.87 78.58 245.50 78.60
  Q 246.00 78.61 246.01 79.11
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 239.57 82.82
  L 239.45 86.27
  A 0.39 0.39 0.0 0 1 239.09 86.65
  L 238.63 86.68
  Q 238.35 86.70 238.31 86.42
  C 238.08 84.38 239.25 81.44 238.33 79.76
  Q 237.97 79.10 238.70 78.91
  L 239.16 78.80
  A 0.94 0.94 0.0 0 1 240.31 79.53
  C 240.61 81.08 240.60 84.29 243.01 83.97
  Q 243.25 83.94 243.36 83.73
  L 243.49 83.48
  Q 243.65 83.19 243.61 83.51
  L 243.31 86.02
  A 0.84 0.84 0.0 0 1 241.79 86.40
  Q 241.40 85.83 241.39 85.09
  Q 241.38 84.53 241.05 84.08
  L 240.05 82.67
  A 0.27 0.26 -62.2 0 0 239.57 82.82
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 182.25 115.93
  Q 182.76 117.98 185.36 118.45
  C 191.53 119.57 199.50 119.73 204.90 122.84
  C 210.04 125.80 208.98 133.73 205.68 137.09
  C 200.58 142.26 188.24 142.29 181.78 139.80
  Q 175.71 137.47 174.32 130.14
  A 1.62 1.62 0.0 0 1 175.92 128.22
  L 179.50 128.23
  A 0.88 0.87 78.1 0 1 180.30 128.76
  C 181.63 131.83 182.21 134.54 186.39 135.17
  C 190.03 135.72 198.57 136.44 201.21 132.82
  C 204.32 128.57 198.40 126.50 195.51 126.21
  C 188.85 125.53 175.92 125.45 175.65 116.03
  C 175.30 104.01 191.50 103.62 199.02 105.95
  Q 206.34 108.22 207.13 116.12
  A 1.16 1.16 0.0 0 1 205.98 117.39
  L 202.54 117.41
  A 1.76 1.76 0.0 0 1 200.83 116.07
  C 198.91 108.11 180.58 109.24 182.25 115.93
  Z"
      />
      <path
        fill="#46483d"
        d="
  M 220.62 213.58
  A 0.64 0.63 11.7 0 0 221.51 213.00
  L 221.50 179.71
  A 1.38 1.38 0.0 0 1 223.55 178.50
  L 236.35 185.64
  A 2.69 2.69 0.0 0 1 237.73 187.99
  L 237.73 249.56
  Q 237.73 251.59 235.97 252.60
  L 214.98 264.67
  A 2.94 2.93 44.7 0 1 212.05 264.68
  L 190.63 252.37
  A 2.76 2.74 -75.2 0 1 189.25 249.98
  L 189.27 225.02
  A 2.66 2.66 0.0 0 1 190.58 222.73
  Q 201.48 216.36 212.29 210.33
  C 214.28 209.22 218.71 212.78 220.62 213.58
  Z
  M 205.50 241.17
  A 1.71 1.71 0.0 0 0 206.35 242.64
  L 212.59 246.28
  A 1.71 1.71 0.0 0 0 214.31 246.28
  L 220.64 242.61
  A 1.71 1.71 0.0 0 0 221.49 241.13
  L 221.49 233.89
  A 1.71 1.71 0.0 0 0 220.63 232.41
  L 214.24 228.73
  A 1.71 1.71 0.0 0 0 212.51 228.74
  L 206.30 232.43
  A 1.71 1.71 0.0 0 0 205.46 233.91
  L 205.50 241.17
  Z"
      />
      <path
        fill="#46483d"
        d="
  M 83.75 228.04
  C 81.79 228.97 76.30 231.20 76.28 234.22
  Q 76.21 241.78 76.25 249.30
  A 2.73 2.71 -15.1 0 1 74.87 251.68
  L 61.96 259.07
  A 1.33 1.32 -14.8 0 1 59.98 257.92
  L 60.00 224.48
  A 2.98 2.96 -15.1 0 1 61.49 221.91
  L 82.74 209.68
  A 2.81 2.80 44.8 0 1 85.53 209.68
  L 107.13 222.10
  A 2.72 2.72 0.0 0 1 108.50 224.48
  L 108.47 257.49
  A 1.50 1.48 -32.7 0 1 108.33 258.12
  Q 107.60 259.76 106.10 258.90
  Q 99.79 255.29 93.58 251.73
  A 2.78 2.76 -75.4 0 1 92.19 249.34
  L 92.11 233.46
  Q 92.10 232.34 91.14 231.78
  L 84.87 228.09
  A 1.21 1.20 47.2 0 0 83.75 228.04
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 150.20 209.87
  L 171.72 222.34
  A 2.57 2.57 0.0 0 1 173.00 224.56
  L 173.01 249.59
  A 2.57 2.57 0.0 0 1 171.72 251.82
  L 150.17 264.24
  A 2.57 2.57 0.0 0 1 147.61 264.24
  L 125.98 251.76
  A 2.57 2.57 0.0 0 1 124.69 249.53
  L 124.71 224.58
  A 2.57 2.57 0.0 0 1 125.99 222.36
  L 147.62 209.87
  A 2.57 2.57 0.0 0 1 150.20 209.87
  Z"
      />
      <path
        fill="#46483d"
        d="
  M 285.14 232.20
  L 278.37 228.24
  A 1.23 1.21 -44.6 0 0 277.14 228.24
  L 270.19 232.31
  A 0.95 0.94 75.3 0 0 269.72 233.13
  L 269.74 241.01
  A 1.49 1.48 15.2 0 0 270.48 242.29
  L 292.17 254.62
  A 1.15 1.14 6.7 0 1 292.71 255.30
  Q 292.98 256.21 292.05 256.75
  Q 285.40 260.64 279.05 264.25
  A 2.57 2.56 45.1 0 1 276.51 264.24
  L 255.10 251.90
  Q 253.73 251.10 253.72 249.51
  L 253.72 224.64
  A 2.63 2.62 -15.1 0 1 255.03 222.37
  L 276.72 209.85
  A 2.28 2.28 0.0 0 1 279.00 209.85
  L 300.83 222.50
  A 2.41 2.39 -75.1 0 1 302.02 224.58
  L 302.01 230.69
  A 2.79 2.77 -15.4 0 1 300.64 233.08
  L 287.91 240.56
  A 1.43 1.43 0.0 0 1 285.75 239.34
  L 285.70 233.16
  Q 285.69 232.52 285.14 232.20
  Z"
      />
      <path
        fill="#90c53f"
        d="
  M 205.50 241.17
  L 205.46 233.91
  A 1.71 1.71 0.0 0 1 206.30 232.43
  L 212.51 228.74
  A 1.71 1.71 0.0 0 1 214.24 228.73
  L 220.63 232.41
  A 1.71 1.71 0.0 0 1 221.49 233.89
  L 221.49 241.13
  A 1.71 1.71 0.0 0 1 220.64 242.61
  L 214.31 246.28
  A 1.71 1.71 0.0 0 1 212.59 246.28
  L 206.35 242.64
  A 1.71 1.71 0.0 0 1 205.50 241.17
  Z"
      />
      <path
        fill="#ffffff"
        d="
  M 273.25 239.13
  L 273.26 234.99
  A 1.08 1.08 0.0 0 1 273.79 234.06
  L 277.39 231.95
  A 1.08 1.08 0.0 0 1 278.49 231.95
  L 282.00 234.03
  A 1.08 1.08 0.0 0 1 282.53 234.97
  L 282.49 239.17
  A 1.08 1.08 0.0 0 1 281.96 240.09
  L 278.47 242.13
  A 1.08 1.08 0.0 0 1 277.39 242.14
  L 273.79 240.07
  A 1.08 1.08 0.0 0 1 273.25 239.13
  Z"
      />
    </svg>
  );
}

export default Nodejs;
