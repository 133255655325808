function Solidity({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1300.00 1300.00"
    >
      <path
        fill="#000000"
        fillOpacity="0.890"
        d="
  M 514.97 253.28
  Q 514.83 254.06 514.10 254.66
  A 0.81 0.81 0.0 0 0 513.85 255.53
  Q 514.31 256.94 515.39 258.62
  Q 518.71 263.78 522.17 270.30
  C 525.53 276.65 529.33 282.83 532.64 289.24
  C 533.79 291.46 535.47 293.60 536.70 295.90
  Q 539.37 300.91 542.06 305.93
  Q 542.42 306.60 544.38 309.74
  C 545.29 311.19 546.08 313.97 547.31 315.02
  Q 547.68 315.33 547.86 315.78
  C 548.51 317.49 549.69 319.04 550.39 320.56
  C 551.70 323.36 553.53 325.98 554.86 328.57
  Q 556.34 331.44 558.06 334.17
  C 559.13 335.88 559.90 337.89 561.03 339.70
  Q 563.07 342.97 563.46 343.70
  Q 566.47 349.33 569.58 354.90
  Q 570.25 356.09 571.01 357.19
  C 572.98 360.05 574.24 363.47 576.25 366.42
  C 577.00 367.54 577.39 368.90 578.15 370.11
  Q 581.18 374.92 583.93 379.90
  Q 590.24 391.31 596.48 402.76
  Q 597.16 404.01 597.99 405.15
  C 599.42 407.10 600.05 409.37 601.36 411.38
  Q 602.98 413.88 603.57 415.05
  C 606.73 421.24 610.63 427.61 613.69 433.22
  C 616.65 438.64 619.84 443.99 622.75 449.37
  Q 626.38 456.10 630.25 462.70
  Q 631.55 464.93 632.65 467.26
  Q 632.82 467.62 633.06 467.84
  Q 633.29 468.05 633.44 468.32
  L 642.67 484.93
  A 0.45 0.44 -14.6 0 1 642.28 485.59
  L 383.81 485.59
  Q 383.29 485.59 383.18 486.10
  Q 382.99 486.97 382.72 487.26
  Q 381.72 484.34 384.55 480.49
  C 386.33 478.09 387.18 476.18 388.36 473.73
  Q 388.58 473.26 389.04 473.01
  Q 389.63 472.69 389.52 472.01
  Q 389.43 471.49 389.82 471.14
  C 391.45 469.68 392.16 466.82 393.38 464.95
  C 395.28 462.05 396.45 459.27 398.25 456.52
  A 1.09 1.03 62.9 0 0 398.42 455.94
  Q 398.43 455.38 398.86 455.07
  Q 399.19 454.83 399.39 454.46
  L 406.51 441.66
  Q 406.72 441.28 407.08 441.02
  Q 407.58 440.65 407.53 439.95
  Q 407.49 439.43 407.88 439.09
  C 409.55 437.62 410.69 433.90 411.86 432.11
  Q 413.99 428.86 415.23 425.92
  Q 415.44 425.42 415.88 425.11
  L 416.16 424.92
  Q 416.53 424.65 416.46 424.20
  Q 416.33 423.41 416.89 423.04
  Q 417.17 422.86 417.33 422.57
  L 424.69 409.35
  Q 424.78 409.19 424.93 409.09
  L 425.15 408.93
  Q 425.63 408.58 425.50 408.00
  Q 425.37 407.37 425.87 407.05
  Q 426.16 406.87 426.33 406.57
  L 433.69 393.35
  Q 433.78 393.19 433.93 393.09
  L 434.16 392.92
  Q 434.54 392.65 434.46 392.19
  Q 434.33 391.36 434.96 391.00
  Q 435.42 390.74 435.65 390.27
  Q 436.95 387.65 438.22 385.01
  Q 438.51 384.43 438.86 384.12
  Q 439.23 383.79 439.46 383.36
  Q 441.34 379.79 443.25 376.23
  C 443.89 375.02 444.73 374.32 445.19 373.21
  Q 446.55 369.89 448.43 366.87
  Q 449.89 364.53 451.21 362.12
  Q 451.80 361.03 453.67 358.00
  C 454.89 356.02 455.64 353.81 456.96 351.90
  C 458.73 349.34 460.17 346.37 461.52 343.59
  C 461.80 343.02 462.40 342.79 462.61 342.35
  Q 464.03 339.44 465.47 336.54
  Q 465.57 336.33 465.76 336.19
  Q 465.89 336.09 466.02 336.00
  Q 466.16 335.90 466.24 335.76
  Q 467.68 333.07 469.09 330.38
  C 470.43 327.84 472.87 324.31 474.22 320.89
  Q 474.38 320.48 474.73 320.22
  L 475.28 319.79
  Q 475.63 319.53 475.51 319.10
  Q 475.29 318.35 476.05 317.95
  A 0.60 0.58 -87.5 0 0 476.30 317.71
  L 484.30 303.29
  Q 484.46 303.01 484.48 302.69
  Q 484.52 302.25 484.92 302.00
  Q 485.25 301.79 485.44 301.45
  L 492.65 288.48
  Q 492.81 288.20 493.07 288.01
  Q 493.62 287.60 493.52 286.78
  Q 493.47 286.39 493.78 286.14
  L 494.05 285.93
  Q 494.27 285.76 494.41 285.51
  L 501.77 272.25
  A 0.54 0.51 -87.1 0 1 501.99 272.04
  Q 502.61 271.71 502.52 271.02
  Q 502.44 270.50 502.82 270.13
  C 504.62 268.34 505.54 265.26 506.35 264.00
  Q 509.08 259.76 510.33 256.75
  Q 510.55 256.24 511.04 255.99
  Q 511.74 255.63 511.50 254.84
  A 0.69 0.68 -32.4 0 1 511.66 254.15
  Q 512.78 252.96 514.97 253.28
  Z"
      />
      <path
        fill="#000000"
        fillOpacity="0.788"
        d="
  M 514.97 253.28
  Q 643.97 253.18 773.00 253.32
  Q 773.30 253.32 773.48 253.44
  Q 773.81 253.65 773.67 253.96
  Q 771.86 256.10 770.93 258.93
  A 1.38 1.36 83.9 0 1 770.34 259.67
  Q 769.47 260.20 769.06 261.09
  Q 767.82 263.81 766.39 266.52
  Q 766.22 266.84 765.89 266.99
  Q 765.34 267.25 765.38 267.91
  A 0.38 0.36 59.4 0 1 765.33 268.13
  L 757.32 282.53
  Q 757.13 282.86 756.79 283.04
  Q 756.39 283.25 756.38 283.76
  Q 756.37 284.25 756.12 284.67
  Q 752.07 291.55 748.38 298.63
  Q 748.23 298.92 747.84 299.07
  Q 747.49 299.20 747.46 299.57
  C 747.35 300.82 744.91 304.38 743.69 307.31
  A 1.34 1.30 85.1 0 1 743.24 307.86
  C 741.86 308.77 740.23 312.92 739.45 314.51
  A 2.97 0.17 -55.6 0 1 738.56 315.98
  C 736.66 318.88 735.44 321.73 733.39 324.82
  Q 731.78 327.23 730.81 330.03
  Q 730.65 330.50 730.24 330.77
  C 729.44 331.30 729.39 332.20 729.03 332.79
  Q 727.78 334.79 726.72 336.88
  C 725.17 339.92 723.09 342.72 721.77 346.04
  Q 721.59 346.49 721.16 346.72
  Q 720.45 347.10 720.33 347.87
  Q 720.26 348.34 720.01 348.75
  C 718.36 351.48 717.26 354.11 715.41 356.92
  Q 713.90 359.21 712.87 361.82
  A 1.95 1.86 81.9 0 1 712.32 362.59
  C 711.41 363.36 711.18 364.46 710.73 365.25
  Q 708.99 368.31 707.25 371.35
  Q 706.89 372.00 706.56 372.60
  Q 705.05 375.36 703.57 378.15
  Q 703.36 378.55 703.00 378.82
  Q 702.43 379.23 702.32 379.92
  Q 702.25 380.31 702.06 380.66
  L 685.11 410.78
  A 0.59 0.59 0.0 0 1 684.54 411.07
  Q 683.99 411.00 684.12 411.52
  Q 684.24 411.98 684.05 412.40
  Q 682.30 416.23 680.03 419.76
  C 678.86 421.58 678.10 423.66 676.92 425.53
  Q 675.13 428.37 674.44 429.72
  C 672.71 433.10 670.71 436.06 668.96 439.61
  Q 667.98 441.59 666.66 443.36
  Q 666.43 443.67 666.37 444.05
  Q 666.32 444.38 666.05 444.80
  Q 663.25 449.32 658.31 458.57
  Q 658.20 458.77 658.03 458.92
  Q 657.76 459.13 657.60 459.44
  Q 655.46 463.51 653.24 467.53
  Q 653.09 467.82 652.81 467.98
  A 0.97 0.88 -4.5 0 0 652.50 468.31
  L 643.46 485.21
  L 640.77 489.98
  Q 640.54 490.41 640.20 490.75
  C 639.48 491.48 639.01 492.78 638.62 493.43
  Q 636.93 496.32 635.52 499.33
  Q 635.31 499.78 634.92 500.08
  C 633.41 501.27 632.36 505.25 630.92 507.05
  C 630.42 507.68 630.07 508.67 629.69 509.30
  Q 627.96 512.27 626.50 515.36
  Q 626.29 515.81 625.90 516.11
  Q 625.31 516.56 625.10 517.08
  Q 623.61 520.73 621.40 523.99
  Q 620.24 525.68 617.55 531.26
  C 617.18 532.03 616.34 532.41 616.08 533.07
  C 614.85 536.27 612.48 539.83 611.31 541.93
  Q 609.68 544.88 607.90 547.73
  Q 606.13 550.58 604.98 553.76
  A 2.00 1.96 -11.3 0 1 604.44 554.55
  C 603.51 555.37 603.11 556.60 602.62 557.43
  Q 600.92 560.34 599.49 563.39
  Q 599.28 563.84 598.87 564.14
  Q 598.47 564.44 598.38 564.63
  Q 596.93 567.56 595.51 570.50
  Q 595.43 570.66 595.07 570.92
  Q 594.82 571.10 594.69 571.35
  Q 592.73 575.07 590.73 578.76
  Q 590.15 579.82 589.74 580.25
  Q 589.39 580.60 589.18 581.04
  C 588.21 582.98 587.34 585.43 585.90 587.04
  Q 585.27 587.76 584.81 588.64
  Q 583.09 591.94 581.58 595.32
  A 1.89 1.81 -6.9 0 1 580.98 596.05
  Q 580.46 596.42 580.30 596.76
  Q 578.96 599.55 577.59 602.32
  Q 577.42 602.64 577.13 602.85
  Q 577.02 602.93 576.92 603.01
  Q 576.83 603.08 576.77 603.19
  Q 574.78 606.99 572.72 610.76
  Q 572.15 611.82 571.74 612.25
  Q 571.39 612.60 571.17 613.05
  Q 569.89 615.63 568.66 618.23
  Q 568.47 618.64 567.90 618.99
  Q 567.46 619.27 567.56 619.79
  Q 567.72 620.61 567.05 621.00
  Q 566.58 621.28 566.34 621.76
  L 563.45 627.43
  Q 563.24 627.85 562.86 628.13
  Q 562.49 628.40 562.38 628.63
  Q 560.99 631.45 559.62 634.27
  Q 559.39 634.74 558.91 634.95
  Q 558.36 635.19 558.55 635.88
  Q 558.69 636.37 558.32 636.73
  C 557.79 637.25 557.38 637.50 557.07 638.21
  C 555.26 642.36 552.64 645.64 550.81 650.02
  Q 550.55 650.63 549.85 651.06
  Q 549.42 651.32 549.56 651.81
  Q 549.78 652.60 549.01 652.98
  A 0.74 0.71 -88.0 0 0 548.70 653.28
  L 541.37 666.44
  Q 541.21 666.74 540.91 666.92
  Q 540.39 667.22 540.57 667.91
  Q 540.71 668.44 540.28 668.77
  C 538.65 670.03 537.35 673.86 536.11 675.75
  C 534.61 678.05 533.70 680.07 532.57 682.36
  A 1.16 1.07 87.8 0 1 532.21 682.78
  L 531.67 683.15
  Q 531.42 683.32 531.52 683.61
  Q 531.81 684.46 531.14 684.93
  Q 530.73 685.23 530.48 685.68
  L 523.28 698.62
  A 0.87 0.82 -2.7 0 1 523.01 698.90
  L 522.63 699.15
  Q 522.40 699.30 522.50 699.57
  Q 522.87 700.53 522.00 700.99
  A 0.77 0.72 -0.9 0 0 521.67 701.31
  L 513.93 715.28
  Q 513.72 715.66 513.64 716.09
  Q 513.50 716.82 512.85 717.23
  A 0.58 0.57 -31.1 0 1 512.03 717.03
  L 382.72 487.26
  Q 382.99 486.97 383.18 486.10
  Q 383.29 485.59 383.81 485.59
  L 642.28 485.59
  A 0.45 0.44 -14.6 0 0 642.67 484.93
  L 633.44 468.32
  Q 633.29 468.05 633.06 467.84
  Q 632.82 467.62 632.65 467.26
  Q 631.55 464.93 630.25 462.70
  Q 626.38 456.10 622.75 449.37
  C 619.84 443.99 616.65 438.64 613.69 433.22
  C 610.63 427.61 606.73 421.24 603.57 415.05
  Q 602.98 413.88 601.36 411.38
  C 600.05 409.37 599.42 407.10 597.99 405.15
  Q 597.16 404.01 596.48 402.76
  Q 590.24 391.31 583.93 379.90
  Q 581.18 374.92 578.15 370.11
  C 577.39 368.90 577.00 367.54 576.25 366.42
  C 574.24 363.47 572.98 360.05 571.01 357.19
  Q 570.25 356.09 569.58 354.90
  Q 566.47 349.33 563.46 343.70
  Q 563.07 342.97 561.03 339.70
  C 559.90 337.89 559.13 335.88 558.06 334.17
  Q 556.34 331.44 554.86 328.57
  C 553.53 325.98 551.70 323.36 550.39 320.56
  C 549.69 319.04 548.51 317.49 547.86 315.78
  Q 547.68 315.33 547.31 315.02
  C 546.08 313.97 545.29 311.19 544.38 309.74
  Q 542.42 306.60 542.06 305.93
  Q 539.37 300.91 536.70 295.90
  C 535.47 293.60 533.79 291.46 532.64 289.24
  C 529.33 282.83 525.53 276.65 522.17 270.30
  Q 518.71 263.78 515.39 258.62
  Q 514.31 256.94 513.85 255.53
  A 0.81 0.81 0.0 0 1 514.10 254.66
  Q 514.83 254.06 514.97 253.28
  Z"
      />
      <path
        fill="#000000"
        fillOpacity="0.600"
        d="
  M 773.67 253.96
  Q 774.22 254.09 774.38 254.37
  Q 838.97 369.29 903.84 484.65
  A 0.58 0.58 0.0 0 1 903.34 485.51
  Q 774.10 485.45 644.50 485.50
  Q 644.06 485.50 643.88 485.01
  Q 643.84 484.89 643.81 485.01
  Q 643.74 485.26 643.46 485.21
  L 652.50 468.31
  A 0.97 0.88 -4.5 0 1 652.81 467.98
  Q 653.09 467.82 653.24 467.53
  Q 655.46 463.51 657.60 459.44
  Q 657.76 459.13 658.03 458.92
  Q 658.20 458.77 658.31 458.57
  Q 663.25 449.32 666.05 444.80
  Q 666.32 444.38 666.37 444.05
  Q 666.43 443.67 666.66 443.36
  Q 667.98 441.59 668.96 439.61
  C 670.71 436.06 672.71 433.10 674.44 429.72
  Q 675.13 428.37 676.92 425.53
  C 678.10 423.66 678.86 421.58 680.03 419.76
  Q 682.30 416.23 684.05 412.40
  Q 684.24 411.98 684.12 411.52
  Q 683.99 411.00 684.54 411.07
  A 0.59 0.59 0.0 0 0 685.11 410.78
  L 702.06 380.66
  Q 702.25 380.31 702.32 379.92
  Q 702.43 379.23 703.00 378.82
  Q 703.36 378.55 703.57 378.15
  Q 705.05 375.36 706.56 372.60
  Q 706.89 372.00 707.25 371.35
  Q 708.99 368.31 710.73 365.25
  C 711.18 364.46 711.41 363.36 712.32 362.59
  A 1.95 1.86 81.9 0 0 712.87 361.82
  Q 713.90 359.21 715.41 356.92
  C 717.26 354.11 718.36 351.48 720.01 348.75
  Q 720.26 348.34 720.33 347.87
  Q 720.45 347.10 721.16 346.72
  Q 721.59 346.49 721.77 346.04
  C 723.09 342.72 725.17 339.92 726.72 336.88
  Q 727.78 334.79 729.03 332.79
  C 729.39 332.20 729.44 331.30 730.24 330.77
  Q 730.65 330.50 730.81 330.03
  Q 731.78 327.23 733.39 324.82
  C 735.44 321.73 736.66 318.88 738.56 315.98
  A 2.97 0.17 -55.6 0 0 739.45 314.51
  C 740.23 312.92 741.86 308.77 743.24 307.86
  A 1.34 1.30 85.1 0 0 743.69 307.31
  C 744.91 304.38 747.35 300.82 747.46 299.57
  Q 747.49 299.20 747.84 299.07
  Q 748.23 298.92 748.38 298.63
  Q 752.07 291.55 756.12 284.67
  Q 756.37 284.25 756.38 283.76
  Q 756.39 283.25 756.79 283.04
  Q 757.13 282.86 757.32 282.53
  L 765.33 268.13
  A 0.38 0.36 59.4 0 0 765.38 267.91
  Q 765.34 267.25 765.89 266.99
  Q 766.22 266.84 766.39 266.52
  Q 767.82 263.81 769.06 261.09
  Q 769.47 260.20 770.34 259.67
  A 1.38 1.36 83.9 0 0 770.93 258.93
  Q 771.86 256.10 773.67 253.96
  Z"
      />
      <path
        fill="#000000"
        fillOpacity="0.788"
        d="
  M 904.06 831.25
  Q 904.33 832.55 903.53 833.50
  Q 903.21 833.87 902.72 833.87
  L 645.34 833.87
  Q 644.96 833.87 644.84 834.23
  Q 644.71 834.60 644.90 835.07
  Q 645.03 835.40 645.52 835.90
  Q 645.86 836.25 646.03 836.71
  Q 646.53 838.10 647.34 839.32
  C 648.84 841.61 649.75 843.92 651.14 846.18
  Q 652.85 848.98 653.61 850.47
  C 655.63 854.41 657.73 857.50 659.26 860.65
  C 659.76 861.70 660.42 862.49 661.09 863.39
  C 662.56 865.34 663.18 867.93 664.59 870.03
  Q 666.69 873.16 667.25 874.23
  Q 668.49 876.60 669.70 878.98
  Q 670.00 879.56 670.76 880.63
  C 671.71 881.96 672.29 883.90 672.96 885.00
  Q 678.80 894.59 684.15 904.45
  Q 688.19 911.89 692.26 919.30
  Q 692.33 919.43 692.44 919.50
  Q 692.58 919.58 692.74 919.62
  Q 693.05 919.69 693.19 919.97
  L 695.95 925.75
  A 0.57 0.57 0.0 0 0 696.34 926.06
  Q 697.09 926.23 696.88 926.98
  A 0.88 0.87 26.8 0 0 697.20 927.94
  Q 698.32 928.77 698.90 930.74
  Q 699.48 932.74 702.05 935.98
  C 703.19 937.41 703.31 939.08 704.35 940.64
  C 705.37 942.16 705.89 943.82 706.91 945.33
  C 708.16 947.19 708.82 949.26 710.10 951.10
  Q 712.04 953.89 713.19 957.09
  A 2.15 2.11 -79.8 0 0 713.81 958.00
  Q 714.61 958.70 714.96 959.68
  Q 715.13 960.17 715.39 960.63
  L 724.06 975.96
  Q 724.31 976.40 724.69 976.74
  C 725.43 977.41 725.76 977.77 726.10 978.80
  C 727.05 981.77 728.99 984.17 730.24 986.68
  Q 731.13 988.48 732.12 990.22
  Q 732.16 990.29 732.23 990.31
  Q 732.38 990.34 732.54 990.38
  Q 732.69 990.41 732.72 990.56
  C 732.98 992.12 734.00 992.95 734.53 994.16
  C 735.46 996.31 736.37 998.61 738.36 999.96
  Q 738.72 1000.20 738.81 1000.63
  Q 739.33 1003.27 740.91 1005.47
  Q 741.60 1006.44 741.84 1007.57
  Q 741.94 1008.04 742.22 1008.43
  C 743.33 1009.96 745.65 1013.49 745.91 1015.30
  Q 745.93 1015.44 746.11 1015.34
  Q 746.19 1015.30 746.25 1015.40
  L 760.50 1040.27
  Q 760.56 1040.38 760.67 1040.45
  C 760.89 1040.60 761.17 1040.90 761.28 1041.18
  Q 763.43 1046.54 766.53 1050.35
  Q 767.30 1051.30 767.33 1052.24
  Q 767.33 1052.42 767.49 1052.49
  Q 767.66 1052.58 767.83 1052.67
  Q 768.09 1052.81 768.12 1053.10
  Q 768.20 1053.72 768.85 1053.91
  Q 769.13 1053.98 769.13 1054.28
  L 769.13 1055.26
  Q 769.13 1055.76 769.42 1056.17
  Q 771.16 1058.65 772.25 1061.46
  Q 772.73 1062.67 773.76 1063.00
  A 0.42 0.40 -84.7 0 1 774.04 1063.35
  Q 774.22 1064.95 774.95 1064.89
  Q 775.19 1064.87 775.32 1065.08
  Q 775.43 1065.25 775.43 1065.43
  Q 775.28 1066.25 774.50 1066.25
  Q 644.63 1066.25 514.75 1066.25
  Q 513.81 1066.25 513.36 1065.48
  Q 515.49 1063.49 516.96 1060.72
  Q 519.19 1056.50 519.78 1055.55
  C 521.52 1052.74 522.72 1049.99 524.63 1047.04
  C 525.88 1045.10 526.55 1042.18 528.09 1040.67
  Q 528.43 1040.33 528.54 1039.87
  Q 528.63 1039.44 528.89 1039.11
  C 530.63 1036.88 531.44 1034.15 533.22 1031.60
  Q 535.03 1028.99 536.05 1025.94
  Q 536.15 1025.63 536.38 1025.42
  Q 536.65 1025.18 536.94 1024.93
  Q 537.34 1024.58 537.35 1024.04
  Q 537.37 1023.25 538.07 1022.86
  A 0.77 0.77 0.0 0 0 538.30 1022.63
  L 545.67 1009.24
  Q 545.84 1008.94 546.11 1008.74
  Q 546.40 1008.54 546.51 1008.20
  Q 546.78 1007.42 546.81 1007.36
  C 549.20 1002.87 551.91 998.80 553.97 994.11
  C 554.23 993.52 554.50 993.39 554.96 992.97
  A 1.03 0.06 -67.7 0 0 555.35 992.16
  Q 555.61 991.55 556.27 990.55
  C 558.75 986.77 561.71 980.59 564.68 975.63
  C 566.46 972.65 567.79 969.87 569.79 966.67
  C 570.90 964.91 571.56 962.47 573.08 960.92
  Q 573.48 960.52 573.48 959.94
  Q 573.47 959.12 574.21 958.74
  A 1.03 1.03 0.0 0 0 574.71 958.21
  Q 576.61 953.91 577.43 952.78
  C 579.32 950.16 580.23 947.28 582.03 944.70
  C 583.87 942.06 584.96 939.03 586.73 936.34
  C 588.31 933.94 589.25 931.01 591.10 928.91
  Q 591.42 928.55 591.48 928.05
  A 0.53 0.49 64.7 0 1 591.55 927.83
  L 599.74 913.27
  Q 599.88 913.02 600.11 912.87
  Q 600.29 912.74 600.36 912.60
  Q 601.86 909.30 603.69 906.18
  Q 607.00 900.53 607.32 899.94
  Q 610.47 894.16 613.65 888.39
  Q 613.80 888.12 614.04 887.99
  Q 614.41 887.79 614.56 887.40
  C 615.92 883.83 618.35 880.38 619.94 877.46
  C 621.83 873.99 624.03 870.65 625.90 866.17
  A 1.52 1.49 84.4 0 1 626.43 865.51
  Q 626.91 865.19 627.18 864.71
  C 630.21 859.26 633.21 853.81 636.32 848.41
  Q 640.33 841.46 644.11 834.03
  Q 646.59 830.47 648.98 825.95
  C 650.07 823.88 652.04 820.93 653.12 818.13
  Q 653.36 817.52 653.85 817.12
  Q 654.23 816.80 654.46 816.37
  Q 656.36 812.80 658.20 809.21
  C 658.82 807.99 659.74 807.30 660.28 806.03
  Q 661.29 803.67 662.99 801.00
  C 664.95 797.91 666.56 794.42 668.86 790.71
  C 669.76 789.26 670.73 786.12 671.87 785.08
  Q 672.23 784.75 672.47 784.32
  Q 673.81 781.86 675.10 779.37
  C 676.46 776.76 678.86 773.35 680.22 770.00
  Q 680.44 769.47 680.85 769.11
  Q 681.22 768.79 681.46 768.35
  Q 683.53 764.49 685.55 760.61
  C 685.91 759.92 686.57 759.56 686.91 758.74
  Q 688.17 755.72 689.91 752.96
  Q 691.69 750.13 693.23 747.16
  C 694.62 744.50 696.85 741.39 698.12 738.15
  Q 698.36 737.54 698.84 737.13
  Q 699.21 736.82 699.44 736.38
  Q 701.52 732.51 703.55 728.61
  C 703.91 727.91 704.56 727.56 704.91 726.74
  Q 706.18 723.72 707.91 720.96
  Q 710.34 717.10 712.32 712.99
  C 712.81 711.96 713.72 711.31 714.24 710.10
  C 715.48 707.20 717.65 703.94 718.74 701.94
  Q 723.97 692.36 725.88 689.13
  C 728.04 685.48 729.55 682.42 731.86 678.70
  C 732.73 677.29 733.76 674.06 734.86 673.08
  Q 735.23 672.75 735.47 672.32
  C 737.24 669.06 738.60 666.40 740.86 662.71
  C 741.74 661.28 742.75 658.10 743.86 657.09
  Q 744.23 656.77 744.46 656.34
  Q 745.80 653.87 747.10 651.37
  C 748.46 648.78 750.85 645.35 752.21 641.99
  Q 752.39 641.55 752.73 641.24
  Q 753.00 640.99 753.17 640.67
  L 774.44 602.83
  Q 775.03 601.78 775.62 602.83
  L 904.06 831.25
  Z"
      />
      <path
        fill="#000000"
        fillOpacity="0.890"
        d="
  M 904.06 831.25
  L 905.44 833.62
  Q 905.69 834.04 905.45 834.47
  L 899.25 845.57
  Q 899.02 845.98 898.66 846.28
  Q 898.46 846.46 898.38 846.63
  Q 897.00 849.48 895.60 852.32
  Q 895.44 852.64 895.14 852.86
  Q 895.03 852.94 894.93 853.02
  Q 894.84 853.09 894.79 853.19
  Q 892.59 857.26 890.47 861.38
  Q 890.24 861.82 889.86 862.13
  Q 889.38 862.53 889.14 863.11
  Q 887.62 866.74 885.39 869.99
  Q 884.40 871.42 881.47 877.43
  C 881.19 878.00 880.57 878.23 880.38 878.63
  Q 879.00 881.48 877.60 884.32
  Q 877.44 884.65 877.14 884.86
  Q 877.03 884.94 876.93 885.02
  Q 876.84 885.09 876.79 885.19
  Q 874.59 889.26 872.48 893.37
  Q 872.25 893.81 871.87 894.13
  Q 871.39 894.52 871.14 895.11
  Q 869.62 898.74 867.39 901.99
  Q 866.39 903.44 863.47 909.43
  C 863.19 910.00 862.57 910.23 862.38 910.63
  Q 861.00 913.48 859.60 916.31
  Q 859.44 916.64 859.14 916.85
  Q 859.03 916.93 858.92 917.02
  Q 858.84 917.08 858.79 917.19
  Q 856.59 921.26 854.47 925.37
  Q 854.25 925.81 853.86 926.13
  Q 853.39 926.52 853.14 927.11
  Q 851.62 930.74 849.39 933.99
  Q 848.41 935.41 845.47 941.43
  C 845.19 942.01 844.59 942.21 844.38 942.63
  Q 843.05 945.34 841.73 948.06
  Q 841.51 948.51 841.12 948.83
  C 840.36 949.46 840.38 950.27 839.88 951.02
  C 838.90 952.46 838.11 955.17 836.81 956.09
  Q 836.42 956.37 836.55 956.83
  Q 836.77 957.60 836.01 957.99
  A 1.44 1.39 -89.5 0 0 835.39 958.62
  L 832.76 963.98
  Q 832.49 964.54 832.01 964.94
  C 831.35 965.49 831.45 966.10 831.05 966.71
  C 830.08 968.16 829.09 971.20 827.82 972.08
  Q 827.43 972.36 827.56 972.82
  Q 827.77 973.60 827.01 974.00
  A 0.64 0.59 -1.1 0 0 826.74 974.26
  L 819.41 987.49
  Q 819.23 987.82 818.89 987.98
  Q 818.44 988.19 818.51 988.74
  Q 818.56 989.20 818.29 989.57
  Q 816.15 992.52 814.86 995.95
  Q 814.69 996.40 814.31 996.70
  C 813.40 997.43 813.25 998.45 812.70 999.29
  C 811.79 1000.67 811.09 1003.19 809.82 1004.08
  Q 809.43 1004.36 809.56 1004.82
  Q 809.77 1005.60 809.01 1006.00
  A 0.66 0.61 -1.3 0 0 808.75 1006.25
  L 801.43 1019.44
  Q 801.24 1019.78 800.92 1019.98
  L 800.66 1020.14
  Q 800.46 1020.26 800.53 1020.49
  Q 800.82 1021.55 800.07 1021.96
  Q 799.61 1022.22 799.36 1022.70
  Q 797.97 1025.45 796.65 1028.22
  Q 796.47 1028.59 796.16 1028.86
  Q 795.79 1029.19 795.56 1029.62
  L 792.54 1035.31
  Q 792.31 1035.74 791.89 1036.00
  L 791.64 1036.15
  Q 791.40 1036.30 791.50 1036.57
  Q 791.87 1037.53 791.00 1038.01
  A 0.63 0.63 0.0 0 0 790.74 1038.27
  L 783.43 1051.44
  Q 783.24 1051.78 782.91 1051.99
  L 782.64 1052.15
  Q 782.39 1052.31 782.51 1052.59
  Q 782.89 1053.46 782.00 1054.00
  A 1.55 1.54 -1.9 0 0 781.48 1054.55
  L 775.43 1065.43
  Q 775.43 1065.25 775.32 1065.08
  Q 775.19 1064.87 774.95 1064.89
  Q 774.22 1064.95 774.04 1063.35
  A 0.42 0.40 -84.7 0 0 773.76 1063.00
  Q 772.73 1062.67 772.25 1061.46
  Q 771.16 1058.65 769.42 1056.17
  Q 769.13 1055.76 769.13 1055.26
  L 769.13 1054.28
  Q 769.13 1053.98 768.85 1053.91
  Q 768.20 1053.72 768.12 1053.10
  Q 768.09 1052.81 767.83 1052.67
  Q 767.66 1052.58 767.49 1052.49
  Q 767.33 1052.42 767.33 1052.24
  Q 767.30 1051.30 766.53 1050.35
  Q 763.43 1046.54 761.28 1041.18
  C 761.17 1040.90 760.89 1040.60 760.67 1040.45
  Q 760.56 1040.38 760.50 1040.27
  L 746.25 1015.40
  Q 746.19 1015.30 746.11 1015.34
  Q 745.93 1015.44 745.91 1015.30
  C 745.65 1013.49 743.33 1009.96 742.22 1008.43
  Q 741.94 1008.04 741.84 1007.57
  Q 741.60 1006.44 740.91 1005.47
  Q 739.33 1003.27 738.81 1000.63
  Q 738.72 1000.20 738.36 999.96
  C 736.37 998.61 735.46 996.31 734.53 994.16
  C 734.00 992.95 732.98 992.12 732.72 990.56
  Q 732.69 990.41 732.54 990.38
  Q 732.38 990.34 732.23 990.31
  Q 732.16 990.29 732.12 990.22
  Q 731.13 988.48 730.24 986.68
  C 728.99 984.17 727.05 981.77 726.10 978.80
  C 725.76 977.77 725.43 977.41 724.69 976.74
  Q 724.31 976.40 724.06 975.96
  L 715.39 960.63
  Q 715.13 960.17 714.96 959.68
  Q 714.61 958.70 713.81 958.00
  A 2.15 2.11 -79.8 0 1 713.19 957.09
  Q 712.04 953.89 710.10 951.10
  C 708.82 949.26 708.16 947.19 706.91 945.33
  C 705.89 943.82 705.37 942.16 704.35 940.64
  C 703.31 939.08 703.19 937.41 702.05 935.98
  Q 699.48 932.74 698.90 930.74
  Q 698.32 928.77 697.20 927.94
  A 0.88 0.87 26.8 0 1 696.88 926.98
  Q 697.09 926.23 696.34 926.06
  A 0.57 0.57 0.0 0 1 695.95 925.75
  L 693.19 919.97
  Q 693.05 919.69 692.74 919.62
  Q 692.58 919.58 692.44 919.50
  Q 692.33 919.43 692.26 919.30
  Q 688.19 911.89 684.15 904.45
  Q 678.80 894.59 672.96 885.00
  C 672.29 883.90 671.71 881.96 670.76 880.63
  Q 670.00 879.56 669.70 878.98
  Q 668.49 876.60 667.25 874.23
  Q 666.69 873.16 664.59 870.03
  C 663.18 867.93 662.56 865.34 661.09 863.39
  C 660.42 862.49 659.76 861.70 659.26 860.65
  C 657.73 857.50 655.63 854.41 653.61 850.47
  Q 652.85 848.98 651.14 846.18
  C 649.75 843.92 648.84 841.61 647.34 839.32
  Q 646.53 838.10 646.03 836.71
  Q 645.86 836.25 645.52 835.90
  Q 645.03 835.40 644.90 835.07
  Q 644.71 834.60 644.84 834.23
  Q 644.96 833.87 645.34 833.87
  L 902.72 833.87
  Q 903.21 833.87 903.53 833.50
  Q 904.33 832.55 904.06 831.25
  Z"
      />
      <path
        fill="#000000"
        fillOpacity="0.600"
        d="
  M 644.11 834.03
  Q 640.33 841.46 636.32 848.41
  C 633.21 853.81 630.21 859.26 627.18 864.71
  Q 626.91 865.19 626.43 865.51
  A 1.52 1.49 84.4 0 0 625.90 866.17
  C 624.03 870.65 621.83 873.99 619.94 877.46
  C 618.35 880.38 615.92 883.83 614.56 887.40
  Q 614.41 887.79 614.04 887.99
  Q 613.80 888.12 613.65 888.39
  Q 610.47 894.16 607.32 899.94
  Q 607.00 900.53 603.69 906.18
  Q 601.86 909.30 600.36 912.60
  Q 600.29 912.74 600.11 912.87
  Q 599.88 913.02 599.74 913.27
  L 591.55 927.83
  A 0.53 0.49 64.7 0 0 591.48 928.05
  Q 591.42 928.55 591.10 928.91
  C 589.25 931.01 588.31 933.94 586.73 936.34
  C 584.96 939.03 583.87 942.06 582.03 944.70
  C 580.23 947.28 579.32 950.16 577.43 952.78
  Q 576.61 953.91 574.71 958.21
  A 1.03 1.03 0.0 0 1 574.21 958.74
  Q 573.47 959.12 573.48 959.94
  Q 573.48 960.52 573.08 960.92
  C 571.56 962.47 570.90 964.91 569.79 966.67
  C 567.79 969.87 566.46 972.65 564.68 975.63
  C 561.71 980.59 558.75 986.77 556.27 990.55
  Q 555.61 991.55 555.35 992.16
  A 1.03 0.06 -67.7 0 1 554.96 992.97
  C 554.50 993.39 554.23 993.52 553.97 994.11
  C 551.91 998.80 549.20 1002.87 546.81 1007.36
  Q 546.78 1007.42 546.51 1008.20
  Q 546.40 1008.54 546.11 1008.74
  Q 545.84 1008.94 545.67 1009.24
  L 538.30 1022.63
  A 0.77 0.77 0.0 0 1 538.07 1022.86
  Q 537.37 1023.25 537.35 1024.04
  Q 537.34 1024.58 536.94 1024.93
  Q 536.65 1025.18 536.38 1025.42
  Q 536.15 1025.63 536.05 1025.94
  Q 535.03 1028.99 533.22 1031.60
  C 531.44 1034.15 530.63 1036.88 528.89 1039.11
  Q 528.63 1039.44 528.54 1039.87
  Q 528.43 1040.33 528.09 1040.67
  C 526.55 1042.18 525.88 1045.10 524.63 1047.04
  C 522.72 1049.99 521.52 1052.74 519.78 1055.55
  Q 519.19 1056.50 516.96 1060.72
  Q 515.49 1063.49 513.36 1065.48
  L 383.38 834.45
  Q 383.13 834.02 383.63 834.02
  L 644.11 834.03
  Z"
      />
    </svg>
  );
}

export default Solidity;
