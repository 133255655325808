function Javascript({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="40"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1052.00 1052.00"
    >
      <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
        <path
          stroke="#f8eda7"
          vectorEffect="non-scaling-stroke"
          d="
      M 0.00 1051.45
      L 1050.43 1051.77
      A 0.77 0.77 0.0 0 0 1051.20 1051.00
      L 1051.20 1.50
      A 0.50 0.50 0.0 0 0 1050.70 1.00
      L 0.00 1.00"
        />
        <path
          stroke="#918740"
          vectorEffect="non-scaling-stroke"
          d="
      M 760.43 580.27
      C 772.34 564.83 791.79 561.79 810.76 564.22
      C 835.08 567.33 851.12 584.66 862.20 605.22
      A 0.50 0.49 -30.2 0 0 862.90 605.40
      L 939.01 556.52
      A 0.39 0.39 0.0 0 0 939.14 555.99
      C 918.94 519.96 889.49 493.40 848.72 483.17
      Q 827.43 477.82 800.28 477.44
      C 737.04 476.56 678.22 507.68 659.74 570.54
      C 655.00 586.65 654.06 602.70 655.05 619.75
      Q 658.55 679.94 704.15 717.07
      C 741.60 747.57 782.36 758.30 829.38 781.65
      C 853.61 793.68 873.11 809.34 868.97 839.00
      Q 867.48 849.68 861.15 857.91
      C 843.30 881.14 807.55 884.04 781.26 879.38
      C 747.22 873.34 722.74 850.76 705.77 821.86
      A 0.69 0.69 0.0 0 0 704.83 821.61
      L 625.38 867.61
      Q 624.92 867.87 625.17 868.34
      C 653.53 922.67 702.96 955.81 763.51 965.40
      C 834.93 976.71 928.89 960.00 958.93 883.00
      C 969.67 855.47 970.57 824.15 964.63 795.27
      C 948.51 716.94 866.35 688.73 803.97 661.89
      Q 789.08 655.48 775.45 646.79
      C 763.87 639.41 754.93 629.47 752.72 615.51
      C 750.64 602.40 752.32 590.77 760.43 580.27"
        />
        <path
          stroke="#918740"
          vectorEffect="non-scaling-stroke"
          d="
      M 502.53 952.08
      C 554.42 925.97 571.71 872.74 571.62 818.00
      Q 571.33 650.57 571.38 483.17
      Q 571.38 483.00 571.21 483.00
      L 473.02 483.00
      A 0.29 0.28 -90.0 0 0 472.74 483.29
      Q 472.78 648.83 472.72 814.25
      C 472.71 841.25 469.63 872.56 437.24 878.90
      Q 423.57 881.58 411.00 879.95
      C 383.57 876.40 368.70 852.40 356.77 830.25
      A 0.53 0.53 0.0 0 0 356.02 830.05
      L 276.45 878.19
      Q 276.16 878.37 276.30 878.68
      C 297.72 924.99 337.75 956.72 388.26 965.47
      C 425.46 971.91 468.26 969.32 502.53 952.08"
        />
      </g>
      <path
        fill="#ffffff"
        d="
      M 0.00 0.00
      L 1052.00 0.00
      L 1052.00 1052.00
      L 0.00 1052.00
      L 0.00 1051.45
      L 1050.43 1051.77
      A 0.77 0.77 0.0 0 0 1051.20 1051.00
      L 1051.20 1.50
      A 0.50 0.50 0.0 0 0 1050.70 1.00
      L 0.00 1.00
      L 0.00 0.00
      Z"
      />
      <path
        fill="#f0db4f"
        d="
      M 0.00 1051.45
      L 0.00 1.00
      L 1050.70 1.00
      A 0.50 0.50 0.0 0 1 1051.20 1.50
      L 1051.20 1051.00
      A 0.77 0.77 0.0 0 1 1050.43 1051.77
      L 0.00 1051.45
      Z
      M 760.43 580.27
      C 772.34 564.83 791.79 561.79 810.76 564.22
      C 835.08 567.33 851.12 584.66 862.20 605.22
      A 0.50 0.49 -30.2 0 0 862.90 605.40
      L 939.01 556.52
      A 0.39 0.39 0.0 0 0 939.14 555.99
      C 918.94 519.96 889.49 493.40 848.72 483.17
      Q 827.43 477.82 800.28 477.44
      C 737.04 476.56 678.22 507.68 659.74 570.54
      C 655.00 586.65 654.06 602.70 655.05 619.75
      Q 658.55 679.94 704.15 717.07
      C 741.60 747.57 782.36 758.30 829.38 781.65
      C 853.61 793.68 873.11 809.34 868.97 839.00
      Q 867.48 849.68 861.15 857.91
      C 843.30 881.14 807.55 884.04 781.26 879.38
      C 747.22 873.34 722.74 850.76 705.77 821.86
      A 0.69 0.69 0.0 0 0 704.83 821.61
      L 625.38 867.61
      Q 624.92 867.87 625.17 868.34
      C 653.53 922.67 702.96 955.81 763.51 965.40
      C 834.93 976.71 928.89 960.00 958.93 883.00
      C 969.67 855.47 970.57 824.15 964.63 795.27
      C 948.51 716.94 866.35 688.73 803.97 661.89
      Q 789.08 655.48 775.45 646.79
      C 763.87 639.41 754.93 629.47 752.72 615.51
      C 750.64 602.40 752.32 590.77 760.43 580.27
      Z
      M 502.53 952.08
      C 554.42 925.97 571.71 872.74 571.62 818.00
      Q 571.33 650.57 571.38 483.17
      Q 571.38 483.00 571.21 483.00
      L 473.02 483.00
      A 0.29 0.28 -90.0 0 0 472.74 483.29
      Q 472.78 648.83 472.72 814.25
      C 472.71 841.25 469.63 872.56 437.24 878.90
      Q 423.57 881.58 411.00 879.95
      C 383.57 876.40 368.70 852.40 356.77 830.25
      A 0.53 0.53 0.0 0 0 356.02 830.05
      L 276.45 878.19
      Q 276.16 878.37 276.30 878.68
      C 297.72 924.99 337.75 956.72 388.26 965.47
      C 425.46 971.91 468.26 969.32 502.53 952.08
      Z"
      />
      <path
        fill="#323330"
        d="
      M 760.43 580.27
      C 752.32 590.77 750.64 602.40 752.72 615.51
      C 754.93 629.47 763.87 639.41 775.45 646.79
      Q 789.08 655.48 803.97 661.89
      C 866.35 688.73 948.51 716.94 964.63 795.27
      C 970.57 824.15 969.67 855.47 958.93 883.00
      C 928.89 960.00 834.93 976.71 763.51 965.40
      C 702.96 955.81 653.53 922.67 625.17 868.34
      Q 624.92 867.87 625.38 867.61
      L 704.83 821.61
      A 0.69 0.69 0.0 0 1 705.77 821.86
      C 722.74 850.76 747.22 873.34 781.26 879.38
      C 807.55 884.04 843.30 881.14 861.15 857.91
      Q 867.48 849.68 868.97 839.00
      C 873.11 809.34 853.61 793.68 829.38 781.65
      C 782.36 758.30 741.60 747.57 704.15 717.07
      Q 658.55 679.94 655.05 619.75
      C 654.06 602.70 655.00 586.65 659.74 570.54
      C 678.22 507.68 737.04 476.56 800.28 477.44
      Q 827.43 477.82 848.72 483.17
      C 889.49 493.40 918.94 519.96 939.14 555.99
      A 0.39 0.39 0.0 0 1 939.01 556.52
      L 862.90 605.40
      A 0.50 0.49 -30.2 0 1 862.20 605.22
      C 851.12 584.66 835.08 567.33 810.76 564.22
      C 791.79 561.79 772.34 564.83 760.43 580.27
      Z"
      />
      <path
        fill="#323330"
        d="
      M 571.62 818.00
      C 571.71 872.74 554.42 925.97 502.53 952.08
      C 468.26 969.32 425.46 971.91 388.26 965.47
      C 337.75 956.72 297.72 924.99 276.30 878.68
      Q 276.16 878.37 276.45 878.19
      L 356.02 830.05
      A 0.53 0.53 0.0 0 1 356.77 830.25
      C 368.70 852.40 383.57 876.40 411.00 879.95
      Q 423.57 881.58 437.24 878.90
      C 469.63 872.56 472.71 841.25 472.72 814.25
      Q 472.78 648.83 472.74 483.29
      A 0.29 0.28 90.0 0 1 473.02 483.00
      L 571.21 483.00
      Q 571.38 483.00 571.38 483.17
      Q 571.33 650.57 571.62 818.00
      Z"
      />
    </svg>
  );
}

export default Javascript;
