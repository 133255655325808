function Arcelormittal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      version="1.2"
      baseProfile="tiny"
      viewBox="0.00 0.00 1477.00 338.00"
    >
      <path
        fill="#ff3700"
        d="
  M 409.67 171.72
  C 375.64 151.74 339.43 133.85 301.76 120.42
  Q 274.60 110.73 253.76 107.74
  C 242.37 106.10 225.43 103.68 217.33 110.35
  C 209.53 116.78 215.48 129.29 219.87 135.71
  Q 220.36 136.44 219.48 136.53
  Q 204.18 138.07 189.23 141.73
  A 1.30 1.29 -20.6 0 1 187.76 141.07
  C 180.82 127.88 176.88 112.23 182.11 97.80
  C 196.76 57.37 268.63 78.97 295.99 88.82
  C 342.64 105.60 388.90 130.10 431.12 158.11
  Q 453.61 173.04 472.55 188.67
  C 480.03 194.85 487.02 204.08 481.88 214.44
  C 476.40 225.49 464.01 226.15 453.54 220.83
  C 396.45 191.80 335.60 173.60 271.25 172.01
  Q 256.66 171.65 242.39 172.83
  A 0.44 0.44 0.0 0 0 242.06 173.51
  Q 264.94 208.58 291.25 240.49
  C 301.88 253.39 283.43 271.15 269.98 262.49
  Q 266.89 260.50 263.33 256.16
  Q 232.85 219.11 207.19 178.41
  A 0.50 0.49 68.7 0 0 206.68 178.18
  Q 175.72 184.20 146.31 195.82
  C 107.19 211.28 68.96 232.35 36.06 258.32
  C 33.07 260.68 29.97 262.49 26.16 262.82
  C 18.71 263.47 12.53 259.62 9.58 252.76
  Q 5.31 242.84 14.35 234.84
  Q 26.93 223.70 46.09 210.87
  Q 99.40 175.21 160.68 156.15
  C 195.24 145.40 231.62 139.34 267.88 140.83
  C 316.29 142.82 365.59 154.05 409.10 172.84
  Q 418.52 176.91 409.67 171.72
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 1083.78 123.84
  Q 1083.66 126.53 1082.75 132.25
  Q 1075.73 176.57 1068.72 220.88
  Q 1067.94 225.83 1067.00 227.43
  C 1063.85 232.81 1055.44 232.37 1052.94 226.28
  Q 1052.23 224.56 1052.90 220.52
  Q 1062.08 164.69 1071.34 108.87
  C 1072.48 101.99 1078.16 96.82 1085.76 98.06
  C 1093.05 99.25 1095.23 104.58 1097.24 111.66
  Q 1108.15 150.10 1119.13 188.52
  Q 1121.14 195.56 1122.48 202.59
  Q 1123.03 205.50 1123.57 202.59
  Q 1124.74 196.27 1126.88 188.76
  Q 1142.33 134.39 1150.23 107.25
  C 1154.10 93.93 1171.95 95.22 1174.17 108.62
  Q 1183.43 164.31 1192.73 220.00
  C 1193.31 223.46 1193.16 227.37 1190.12 229.65
  C 1186.31 232.51 1181.19 231.13 1178.54 227.22
  Q 1177.56 225.77 1176.96 222.01
  Q 1170.11 178.83 1163.40 135.62
  Q 1162.32 128.71 1161.59 121.75
  Q 1161.52 121.10 1161.41 120.93
  Q 1160.88 120.14 1160.70 121.07
  Q 1159.71 126.27 1158.24 131.43
  Q 1145.24 177.11 1131.98 222.72
  Q 1130.58 227.54 1128.74 229.19
  C 1124.43 233.07 1116.52 231.60 1114.70 225.45
  Q 1101.13 179.64 1087.92 133.71
  Q 1086.22 127.79 1085.48 123.76
  Q 1084.10 116.30 1083.78 123.84
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 535.37 194.45
  Q 530.12 208.70 525.09 223.02
  Q 523.64 227.17 522.29 228.64
  C 518.32 232.97 509.70 231.73 508.59 225.40
  Q 508.11 222.61 509.54 218.74
  Q 530.27 162.91 551.12 107.13
  C 554.58 97.87 566.27 93.95 572.64 102.86
  Q 574.88 106.00 576.83 111.30
  Q 596.48 164.68 616.26 218.02
  Q 616.81 219.51 617.32 221.02
  C 620.17 229.44 609.53 234.39 604.12 229.07
  Q 602.66 227.62 601.19 223.51
  Q 596.02 209.04 590.82 194.59
  A 0.88 0.88 0.0 0 0 589.99 194.01
  L 536.00 194.01
  Q 535.53 194.01 535.37 194.45
  Z
  M 540.69 179.21
  A 0.32 0.32 0.0 0 0 540.99 179.64
  L 584.99 179.63
  A 0.32 0.32 0.0 0 0 585.29 179.20
  L 563.28 117.26
  A 0.32 0.32 0.0 0 0 562.68 117.26
  L 540.69 179.21
  Z"
      />
      <rect
        fill="#d5d5d5"
        x="862.51"
        y="98.00"
        width="16.30"
        height="133.22"
        rx="8.00"
      />
      <rect
        fill="#d5d5d5"
        x="1456.45"
        y="97.99"
        width="16.32"
        height="133.24"
        rx="7.98"
      />
      <circle fill="#d5d5d5" cx="1221.52" cy="112.39" r="9.99" />
      <path
        fill="#d5d5d5"
        d="
  M 1274.70 132.23
  C 1279.61 131.99 1287.59 131.09 1288.60 137.84
  C 1290.03 147.37 1280.48 146.35 1274.65 146.23
  Q 1274.01 146.21 1274.01 146.86
  Q 1274.03 184.09 1274.01 221.43
  Q 1274.01 225.18 1273.12 226.92
  C 1270.11 232.77 1261.51 232.67 1258.83 226.90
  Q 1257.77 224.63 1257.77 218.94
  Q 1257.79 183.66 1257.79 147.22
  Q 1257.79 146.33 1256.90 146.30
  C 1252.09 146.16 1245.59 147.36 1244.09 141.13
  C 1241.93 132.12 1251.07 131.77 1256.91 132.23
  A 0.90 0.89 1.8 0 0 1257.87 131.33
  Q 1257.78 122.97 1257.77 114.66
  Q 1257.76 108.69 1258.76 106.50
  C 1261.09 101.39 1270.31 100.89 1273.04 106.23
  Q 1274.01 108.12 1274.01 112.43
  Q 1273.99 122.01 1274.03 131.59
  A 0.64 0.64 0.0 0 0 1274.70 132.23
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 1309.02 131.65
  Q 1309.09 123.42 1309.01 115.00
  Q 1308.96 109.45 1309.60 107.55
  C 1311.86 100.81 1321.92 100.66 1324.63 107.20
  Q 1325.22 108.62 1325.24 111.38
  Q 1325.33 121.47 1325.20 131.61
  A 0.67 0.66 -2.3 0 0 1325.93 132.28
  C 1330.00 131.94 1336.61 131.39 1338.94 135.39
  C 1340.93 138.79 1340.06 143.66 1336.26 145.46
  C 1333.58 146.73 1329.14 146.29 1325.89 146.24
  A 0.47 0.47 0.0 0 0 1325.41 146.71
  Q 1325.45 183.43 1325.45 220.13
  Q 1325.45 225.10 1324.17 227.39
  C 1320.47 234.03 1309.23 231.51 1309.23 223.75
  Q 1309.25 185.24 1309.20 146.90
  A 0.64 0.63 90.0 0 0 1308.57 146.26
  C 1303.55 146.24 1296.52 147.44 1295.20 140.91
  C 1293.38 131.95 1302.50 131.72 1308.33 132.27
  A 0.63 0.63 0.0 0 0 1309.02 131.65
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 649.16 146.49
  Q 649.11 149.02 650.30 146.79
  Q 655.46 137.07 665.65 132.43
  C 669.68 130.60 675.35 130.27 678.46 133.84
  Q 680.60 136.29 680.00 139.77
  C 678.64 147.64 669.89 147.71 664.11 150.38
  Q 649.45 157.15 649.35 175.01
  Q 649.21 197.76 649.28 220.53
  Q 649.30 225.39 647.94 227.73
  C 645.07 232.66 635.97 232.41 633.68 226.70
  Q 632.98 224.94 632.98 220.72
  Q 632.98 180.78 632.97 140.85
  Q 632.97 136.87 634.12 134.87
  C 636.67 130.44 642.79 129.93 646.52 132.97
  C 650.33 136.08 649.25 142.15 649.16 146.49
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 699.10 190.10
  C 702.05 208.47 715.81 221.14 735.19 215.64
  C 738.57 214.69 746.53 209.36 750.50 213.25
  C 756.22 218.86 749.47 224.90 744.59 227.07
  Q 727.51 234.66 710.99 228.73
  C 685.16 219.47 678.46 190.62 683.58 166.52
  C 689.90 136.83 718.61 123.45 745.78 135.13
  C 750.52 137.17 755.24 143.89 750.26 148.28
  C 744.99 152.92 738.68 147.51 733.19 146.14
  Q 726.01 144.35 719.20 146.26
  C 700.56 151.49 696.50 173.90 699.10 190.10
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 900.17 154.93
  C 912.32 127.54 951.31 122.26 969.87 146.13
  C 978.54 157.28 981.42 170.85 980.69 185.00
  C 979.55 207.22 967.65 226.78 944.51 230.75
  Q 937.45 231.97 930.00 230.54
  C 895.50 223.92 888.02 182.33 900.17 154.93
  Z
  M 933.26 216.40
  C 965.95 221.85 971.21 173.70 957.14 155.13
  C 949.48 145.02 935.82 142.25 925.04 148.98
  C 904.84 161.60 905.66 211.80 933.26 216.40
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 1013.12 146.12
  Q 1012.84 149.75 1014.50 146.51
  C 1018.45 138.80 1028.96 129.42 1038.22 131.43
  C 1045.40 132.99 1045.87 142.07 1040.38 145.64
  C 1036.27 148.30 1030.94 148.72 1026.14 151.37
  C 1016.04 156.95 1013.30 165.72 1013.28 176.75
  Q 1013.24 198.68 1013.28 220.61
  Q 1013.28 225.40 1011.94 227.65
  C 1008.12 234.03 996.99 231.33 996.99 223.75
  Q 996.98 182.37 996.98 141.00
  Q 996.98 136.80 998.21 134.75
  C 1002.05 128.33 1013.10 130.68 1013.26 138.50
  Q 1013.36 143.10 1013.12 146.12
  Z"
      />
      <rect
        fill="#d5d5d5"
        x="-8.09"
        y="-50.05"
        transform="translate(1221.48,181.15) rotate(-0.1)"
        width="16.18"
        height="100.10"
        rx="7.75"
      />
      <path
        fill="#d5d5d5"
        d="
  M 1417.17 216.49
  Q 1417.20 215.28 1416.51 216.27
  C 1407.50 229.21 1393.78 233.85 1378.62 229.72
  C 1335.83 218.04 1336.84 139.23 1382.24 131.75
  Q 1404.46 128.09 1416.61 145.84
  A 0.41 0.41 0.0 0 0 1417.36 145.58
  C 1417.07 141.77 1416.42 136.38 1419.34 133.44
  C 1422.76 129.99 1429.20 130.19 1431.92 134.47
  Q 1433.26 136.58 1433.26 140.79
  Q 1433.26 180.92 1433.26 221.01
  Q 1433.26 225.21 1432.14 227.28
  C 1429.60 231.95 1423.29 232.49 1419.55 229.08
  C 1416.07 225.91 1417.06 220.78 1417.17 216.49
  Z
  M 1407.03 210.33
  C 1418.68 199.50 1419.47 176.99 1414.22 162.76
  C 1410.36 152.30 1400.38 144.26 1388.98 145.26
  C 1376.10 146.38 1367.97 156.38 1365.33 168.50
  C 1361.73 185.00 1364.62 211.13 1385.00 216.20
  C 1392.88 218.16 1401.08 215.86 1407.03 210.33
  Z"
      />
      <path
        fill="#d5d5d5"
        d="
  M 776.06 187.82
  C 776.51 211.54 798.66 222.33 819.24 214.21
  C 823.37 212.57 828.00 209.10 831.55 207.07
  C 838.52 203.09 845.15 210.95 840.46 217.22
  C 831.13 229.71 808.57 232.83 794.50 230.27
  C 755.01 223.07 749.24 164.98 775.96 141.32
  C 790.06 128.84 812.69 127.41 827.88 139.38
  C 839.82 148.80 846.53 166.13 845.52 180.75
  C 845.14 186.27 840.59 187.29 835.62 187.29
  Q 806.05 187.30 776.59 187.29
  A 0.53 0.52 -0.5 0 0 776.06 187.82
  Z
  M 776.56 175.14
  L 829.04 175.14
  A 0.24 0.24 0.0 0 0 829.28 174.90
  L 829.28 174.27
  A 28.99 25.56 90.0 0 0 803.72 145.28
  L 801.88 145.28
  A 28.99 25.56 -90.0 0 0 776.32 174.27
  L 776.32 174.90
  A 0.24 0.24 0.0 0 0 776.56 175.14
  Z"
      />
    </svg>
  );
}

export default Arcelormittal;
